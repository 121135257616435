import { useTrans } from "@/i18n"
import { classNames } from "@/lib/ui"
import { useFinanceGraphProductionData } from "./FinanceGraphProduction"

/**
 * FinanceGraphProductionTargetedPerformanceRatio
 * @returns
 */
export function FinanceGraphProductionTargetedPerformanceRatio() {
	const { data } = useFinanceGraphProductionData()
	const t = useTrans("finance")

	const performanceRatio =
		Number(
			data?.me?.finance_projects?.results?.[0]?.overall_statistics
				?.production_performance_ratio?.total_performance_ratio,
		) ?? 0

	const targetedPerformanceRatio =
		data?.me?.finance_projects?.results?.[0]?.targeted_performance_ratio ??
		0
	// @ts-ignore
	const isPositive = performanceRatio >= targetedPerformanceRatio

	return (
		<div
			className={classNames(
				"rounded-md px-3 py-2 text-white lg:px-4",
				isPositive ? "bg-green-500" : "bg-red-500",
			)}
		>
			{t("finance.production_graph.performance_ratio", {
				value: performanceRatio,
			})}
		</div>
	)
}
