import { Fragment, memo, useMemo, useState } from "react"

// UI
import { CardBody } from "@/components/Card"
import { SearchInput } from "@/components/form-controls/Input"
import { Heading, Subheading } from "@/components/Typography"

// Table
import {
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
	CellContext,
	createColumnHelper,
	SortingState,
} from "@/lib/table"
import { TableEmptyState } from "@/components/table-controls/TableEmptyState"
import {
	Table,
	TableBody,
	TableDataCell,
	TableFooter,
	TableHead,
	TableHeading,
	TableRowCell,
} from "@/components/table-controls/TableItems"
import { Disclaimer } from "@/components/Disclaimer"
import { TablePagination } from "@/components/table-controls/TablePagination"

import { useLang } from "@/context/lang"

// Queries
import { PaymentEntryType } from "@/api/graphql"

import { useTrans } from "@/i18n"
import { normaliseString } from "@/lib/js"

// Hooks
import { useCurrentPaymentDetail } from "../PaymentDetailProjectOwner"
import { emptyValue } from "@/utils/helpers"

function generateTableRowId(
	row: Pick<PaymentEntryType, "name" | "supplier_account">,
	suffix = "tablerow",
) {
	return `${suffix}-${row.supplier_account}-${normaliseString(
		row.name ?? "",
	)}`
}

/**
 * PaymentOverviewForProjectOwner
 * @returns
 */
export const PaymentOverviewForProjectOwner = memo(() => {
	// Translations
	const t = useTrans(["common", "payments"])
	const { formatCurrency, formatNumber } = useLang()

	// Queries
	const { data } = useCurrentPaymentDetail()

	// State
	const [sorting, setSorting] = useState<SortingState>([])

	// Tables
	const columnHelper = createColumnHelper<PaymentEntryType>()
	const columns = useMemo(
		() => [
			columnHelper.accessor("share_count", {
				header: () => (
					<TableHeading
						fieldName="share_count"
						sort={sorting}
						setSort={setSorting}
						sortType="client"
					>
						{t(
							"payments:payments.overview.header.number_of_shares",
						)}
					</TableHeading>
				),
				cell: (info: CellContext<PaymentEntryType, number>) => (
					<TableDataCell>{info.getValue()}</TableDataCell>
				),
			}),
			columnHelper.accessor("name", {
				header: () => (
					<TableHeading
						fieldName="name"
						sort={sorting}
						setSort={setSorting}
						sortType="client"
					>
						{t("payments:payments.overview.header.name")}
					</TableHeading>
				),
				cell: (info: CellContext<PaymentEntryType, string>) => (
					<TableDataCell>{info.getValue()}</TableDataCell>
				),
			}),
			columnHelper.accessor("production", {
				header: () => (
					<TableHeading
						fieldName="production"
						sort={sorting}
						setSort={setSorting}
						sortType="client"
					>
						{t(
							"payments:payments.overview.header.energy_generated",
						)}
					</TableHeading>
				),
				cell: (info: CellContext<PaymentEntryType, number>) => (
					<TableDataCell>
						{`${
							info.getValue() ? formatNumber(info.getValue()) : ""
						} kWh`}
					</TableDataCell>
				),
			}),
			columnHelper.accessor("cost", {
				header: () => (
					<TableHeading
						fieldName="cost"
						sort={sorting}
						setSort={setSorting}
						sortType="client"
					>
						{t("payments:payments.overview.header.interest")}
					</TableHeading>
				),
				cell: (info: CellContext<PaymentEntryType, number>) => (
					<TableDataCell>
						{formatCurrency(info.getValue() || 0)}
					</TableDataCell>
				),
			}),
			columnHelper.accessor("amortization", {
				header: () => (
					<TableHeading
						fieldName="amortization"
						sort={sorting}
						setSort={setSorting}
						sortType="client"
					>
						{t("payments:payments.overview.header.amortization")}
					</TableHeading>
				),
				cell: (info: CellContext<PaymentEntryType, number>) => (
					<TableDataCell>
						{formatCurrency(info.getValue() || 0)}
					</TableDataCell>
				),
			}),
			columnHelper.accessor("repayment", {
				header: () => (
					<TableHeading
						fieldName="repayment"
						sort={sorting}
						setSort={setSorting}
						sortType="client"
					>
						{t("payments:payments.overview.header.settlement")}
					</TableHeading>
				),
				cell: (info: CellContext<PaymentEntryType, number>) => (
					<TableDataCell>
						{formatCurrency(info.getValue() || 0)}
					</TableDataCell>
				),
			}),
			columnHelper.accessor(
				(data: PaymentEntryType) =>
					(data.cost ?? 0) +
					(data.amortization ?? 0) +
					(data.repayment ?? 0),
				{
					header: () => (
						<TableHeading
							fieldName="sum"
							sort={sorting}
							setSort={setSorting}
							sortType="client"
						>
							{t("payments:payments.overview.header.sum")}
						</TableHeading>
					),
					id: "sum",
					cell: (info: CellContext<PaymentEntryType, number>) => (
						<TableDataCell>
							{formatCurrency(info.getValue() || 0)}
						</TableDataCell>
					),
					footer: (info: any) => {
						const total = info.table.options.data.reduce(
							(sum: number, row: PaymentEntryType) =>
								sum +
								((row.cost ?? 0) +
									(row.amortization ?? 0) +
									(row.repayment ?? 0)),
							0,
						)

						return (
							<>
								<Subheading className="mb-2">
									{t(
										"payments:payments.overview_project_owner.footer.total_sum",
									)}
								</Subheading>
								<p className="text-sm font-medium text-gray-700">
									{total
										? formatCurrency(total)
										: emptyValue()}
								</p>
							</>
						)
					},
				},
			),
		],
		[t, formatCurrency, formatNumber, sorting],
	)

	const tableData =
		data?.interest_payments?.results?.[0]?.payment_entries ?? []

	const table = useReactTable({
		columns,
		data: tableData as PaymentEntryType[],
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getPaginationRowModel: getPaginationRowModel(),

		// Sorting
		getSortedRowModel: getSortedRowModel(),
		state: {
			sorting,
		},
		onSortingChange: setSorting,
		enableSorting: true,
	})

	return (
		<>
			<CardBody>
				<div className="flex flex-1 flex-col space-y-4 sm:flex-row sm:items-center sm:space-y-0">
					<Heading as="h2" styleAs="h5" className="sm:truncate">
						{t("payments:payments.overview_project_owner.title", {
							projectOwner:
								data?.interest_payments?.results?.[0]?.project
									?.owner?.name,
						})}
					</Heading>
					<div className="sm:order-3 sm:ml-auto">
						<SearchInput
							onChange={(evt) =>
								table.setGlobalFilter(evt.currentTarget.value)
							}
							label={t("payments:payments.search.placeholder")}
							className="md:width-auto min-w-full"
						/>
					</div>
				</div>
			</CardBody>
			<div className="md:hidden">
				<Disclaimer
					message={t("common:common.table.mobile_warning.copy")}
				/>
			</div>
			<Table className="min-w-[64rem] lg:min-w-0">
				<TableHead>
					{table.getHeaderGroups().map((headerGroup) => (
						<TableRowCell key={headerGroup.id}>
							{headerGroup.headers.map((header) => (
								<Fragment key={header.id}>
									{flexRender(
										header.column.columnDef.header,
										header.getContext(),
									)}
								</Fragment>
							))}
						</TableRowCell>
					))}
				</TableHead>
				{/* table body and table cells */}
				<TableBody data-testid="tablebody">
					{table.getRowModel().rows.map((row) => {
						return (
							<TableRowCell
								isOdd={row.index % 2 === 0}
								data-testid={generateTableRowId(row.original)}
							>
								{row.getAllCells().map((cell) => {
									return (
										<Fragment key={cell.id}>
											{flexRender(
												cell.column.columnDef.cell,
												cell.getContext(),
											)}
										</Fragment>
									)
								})}
							</TableRowCell>
						)
					})}
					{/* Pads the last entries in the table so the table doesn't collapse in the UI */}
					{table.getRowModel().rows.length <
						table.getState().pagination.pageSize &&
					table.getState().pagination.pageIndex !== 0 ? (
						<>
							{Array(
								Math.max(
									table.getState().pagination.pageSize -
										table.getRowModel().rows.length,
									1,
								),
							)
								.fill(true)
								.map((_, index) => (
									<TableRowCell
										key={index}
										withHover={false}
										isOdd={index % 2 === 0}
									>
										<TableDataCell colSpan={columns.length}>
											<span className="dummy-text" />
										</TableDataCell>
									</TableRowCell>
								))}
						</>
					) : null}
				</TableBody>
				<TableFooter>
					{table.getFooterGroups().map((footerGroup) => (
						<TableRowCell key={footerGroup.id}>
							{footerGroup.headers.map((header) => {
								return (
									<TableDataCell
										key={header.id}
										className="text-gray-600"
										data-testid={`footer-${header.id}`}
									>
										{flexRender(
											header.column.columnDef.footer,
											header.getContext(),
										)}
									</TableDataCell>
								)
							})}
						</TableRowCell>
					))}
				</TableFooter>
			</Table>
			{table.getRowModel().rows.length === 0 && (
				<CardBody>
					<TableEmptyState>
						{t(
							"payments:payments.overview_project_owner.empty_state",
						)}
					</TableEmptyState>
				</CardBody>
			)}
			{table.getRowModel().rows.length !== 0 && (
				<CardBody>
					<TablePagination
						amountOfRows={
							table.getPrePaginationRowModel().rows.length
						}
						pageIndex={table.getState().pagination.pageIndex}
						pageSize={table.getState().pagination.pageSize}
						previousPage={() => table.previousPage()}
						gotoPage={(page: number) => table.setPageIndex(page)}
						canPreviousPage={table.getCanPreviousPage()}
						canNextPage={table.getCanNextPage()}
						nextPage={() => table.nextPage()}
						setPageSize={(size: number) => table.setPageSize(size)}
						totalNumberOfItems={
							data?.interest_payments?.results?.[0]
								?.payment_entries?.length
						}
					/>
				</CardBody>
			)}
		</>
	)
})
