// React
import { ComponentProps } from "react"

/**
 * For UI we use components from Headless UI.
 *
 * DOCS: https://headlessui.com/
 */
import { ComboboxInput as BaseComboboxInput } from "@headlessui/react"

export const ComboboxInput = (
	props: ComponentProps<typeof BaseComboboxInput>,
) => {
	return (
		<BaseComboboxInput
			{...props}
			className={classNames(
				"focus:border-primary-500 focus:ring-primary-500 w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:outline-none focus:ring-1",
				props.className,
			)}
		/>
	)
}

// re-export only what we use
export {
	// Combobox
	Combobox,
	ComboboxButton,
	ComboboxOptions,
	ComboboxOption,

	// Switch
	Switch,

	// Menu
	Menu,
	MenuButton,
	MenuItems,
	MenuItem,

	// Dialog
	Dialog,
	DialogBackdrop,

	// Popover
	Popover,
	PopoverGroup,
	PopoverButton,
	PopoverPanel,

	// Transitions
	Transition,
	TransitionChild,
	Disclosure,
} from "@headlessui/react"

// Classnames utility helps with conditional classnames
export const classNames = (
	...classes: Array<string | boolean | undefined | null>
) => {
	return classes.filter(Boolean).join(" ")
}
