// React
import {
	createContext,
	Suspense,
	useContext,
	useMemo,
	useState,
	HTMLProps,
	useRef,
} from "react"

// PDF
import { generatePDF, getPdfFileName, PDFViewWrapper } from "@/lib/pdf"

// DateTime
import { DateTime, DEFAULT_TIMEZONE, ms } from "@/lib/dates"
import { apiDateFormat, dateFormat } from "@/constants/constants"
import { useCurrentDate } from "@/hooks/useCurrentDate"

// Translations
import { useTrans } from "@/i18n"
import { useLang } from "@/context/lang"

// GraphQL
import { useFiscalOverviewByYearQuery } from "@/api/graphql"

// Feature flags
import { useFeatureFlags } from "@/context/user"

// UI
import { classNames } from "@/lib/ui"
import {
	FiscalOverviewByYearTable,
	LoadingStateTable,
} from "./FiscalOverviewByYearTable"
import { Tooltip } from "@/components/Tooltip"
import { Button } from "@/components/Button"
import { CardBody, CardWrapper } from "@/components/Card"
import { DateRangePicker } from "@/components/form-controls/DateRangePicker"
import { Select } from "@/components/Select"
import { Disclaimer } from "@/components/Disclaimer"
import { Heading, P } from "@/components/Typography"
import { DownloadSelector } from "@/components/DownloadSelector"

// Icons
import { FiInfo, FiCopy } from "@/lib/icons"

// Context
import { useToasts } from "@/context/toasts"

// Copy
import { copy } from "@/lib/copyClipboard"

// Analytics
import { sendEvent } from "@/lib/analytics"

// Types
import { DownloadableFileType } from "@/components/DownloadSelector/types"
export type FiscalOverviewByYearContextType =
	| { type: "custom"; from: string; to: string }
	| { type: "year"; year: string }

export const FiscalOverviewByYearContext =
	createContext<FiscalOverviewByYearContextType>(null!)

// TODO: This is hardcoded and should be changed to start of first investment project?
const startYear = 2016

function useData() {
	return useFiscalOverviewByYearQueryWithYear()
}

function useYearOptions() {
	const now = useCurrentDate(ms("1h"))

	// Note: We want the previous year
	return Array(now.getFullYear() - startYear)
		.fill(true)
		.map((_, index) => String(startYear + index))
		.reverse()
}

/**
 * FiscalOverviewByYear
 * @returns
 */
export function FiscalOverviewByYear() {
	// State
	const [mode, setMode] = useState<FiscalOverviewByYearContextType>({
		type: "year",
		year: String(DateTime.local().minus({ years: 1 }).year),
	})

	// Translations
	const t = useTrans(["common", "investments"])

	// PDF
	const pdfViewRef = useRef(null)
	const PDF_TITLE =
		mode.type === "year"
			? t("investments:investments.fiscal.filter", { year: mode.year })
			: t("investments:investments.fiscal.custom_range.filter", {
					from: DateTime.fromISO(mode.from).toFormat(dateFormat),
					to: DateTime.fromISO(mode.to).toFormat(dateFormat),
			  })
	const PDF_VIEW_WIDTH = 1280 // // Width that the table renders optimally at
	async function handlePdfDownload() {
		if (pdfViewRef.current) {
			return generatePDF(pdfViewRef.current, {
				filename: getPdfFileName(PDF_TITLE),
				overrideWidth: PDF_VIEW_WIDTH,
				compression: "FAST",
			})
		}
	}

	/**
	 * handleDownload
	 * @param type
	 */
	function handleDownload(type: DownloadableFileType) {
		if (type === DownloadableFileType.Pdf) {
			sendEvent("investments", "fiscal_download")
			handlePdfDownload()
		}
	}

	// Dates
	const yearOptions = useYearOptions().map((year) => ({
		key: year,
		value: year,
	}))

	const { getFeatureFlagValue } = useFeatureFlags()

	return (
		<FiscalOverviewByYearContext.Provider value={mode}>
			{/** Invisible Print view */}
			<PDFViewWrapper
				ref={pdfViewRef}
				title={PDF_TITLE}
				width={PDF_VIEW_WIDTH}
			>
				<FiscalOverviewPdfHeading />
				<FiscalOverviewByYearTable layout="fixed" />
			</PDFViewWrapper>

			{/** Visible Web view */}
			<CardWrapper>
				<CardBody>
					{/* filters */}
					<div className="align-end mb-4 flex flex-col justify-between gap-2 md:mb-6 lg:flex-row lg:gap-0">
						<div className="space-between flex w-full flex-wrap gap-x-4 gap-y-2">
							<Heading
								as="h3"
								styleAs="h6"
								className="mr-2 lg:mr-0"
							>
								{mode.type === "year"
									? t(
											"investments:investments.fiscal.range.yearly.title",
											{
												year: mode.year,
											},
									  )
									: t(
											"investments:investments.fiscal.range.custom.title",
											{
												from: DateTime.fromISO(
													mode.from,
												).toFormat(dateFormat),
												to: DateTime.fromISO(
													mode.to,
												).toFormat(dateFormat),
											},
									  )}
							</Heading>
							{getFeatureFlagValue(
								"FISCAL_OVERVIEW_BY_YEAR_DISPLAY_BUTTONS",
							) === true && (
								<div className="mb-6 flex items-center">
									<Button
										size="small"
										onClick={() =>
											setMode({
												type: "year",
												year: yearOptions[0]?.key,
											})
										}
										className={classNames(
											"rounded-r-none",
											mode.type === "year" &&
												"relative z-10",
										)}
										variant={
											mode.type === "year"
												? "primary"
												: "transparent"
										}
									>
										{t(
											"investments:investments.fiscal.range.yearly",
										)}
									</Button>
									<Button
										size="small"
										onClick={() => {
											const from = DateTime.local()
												.startOf("year")
												.toISO()
											const to = DateTime.local().toISO()

											setMode({
												type: "custom",
												from,
												to,
											})
										}}
										className={classNames(
											"rounded-l-none border-l-0",
											mode.type === "custom" &&
												"relative z-10",
										)}
										variant={
											mode.type === "custom"
												? "primary"
												: "transparent"
										}
									>
										{t(
											"investments:investments.fiscal.range.custom",
										)}
									</Button>
								</div>
							)}
							{mode.type === "year" && (
								<Select
									value={mode.year}
									onChangeValue={(year) => {
										sendEvent(
											"investments",
											"fiscal_filter",
											{
												label: year,
											},
										)
										year =
											year ||
											String(DateTime.local().year)
										return setMode({
											type: "year",
											year,
										})
									}}
									label={
										<>
											{t(
												"investments:investments.fiscal.filter",
												{
													year: String(mode.year),
												},
											)}
										</>
									}
									options={yearOptions}
									variant="transparent"
								/>
							)}

							{mode.type === "custom" && (
								<DateRangePicker
									from={mode.from}
									to={mode.to}
									onSetFrom={(date: DateTime) =>
										setMode({
											type: "custom",
											from: String(date),
											to: mode.to,
										})
									}
									onSetTo={(date: DateTime) =>
										setMode({
											type: "custom",
											from: mode.from,
											to: String(date),
										})
									}
								/>
							)}

							{getFeatureFlagValue(
								"FEATURE_ENABLE_FISCAL_OVERVIEW_DATE_SELECTOR",
							) === true && (
								<div className="flex items-center">
									<Button
										size="small"
										onClick={() =>
											setMode({
												type: "year",
												year: yearOptions[0]?.key,
											})
										}
										className={classNames(
											"rounded-r-none",
											mode.type === "year" &&
												"relative z-10",
										)}
										variant={
											mode.type === "year"
												? "primary"
												: "transparent"
										}
									>
										{t(
											"investments:investments.fiscal.range.yearly",
										)}
									</Button>
									{getFeatureFlagValue(
										"FEATURE_ENABLE_FISCAL_OVERVIEW_DATE_SELECTOR",
									) === true && (
										<Button
											size="small"
											onClick={() => {
												const from = DateTime.local()
													.startOf("year")
													.toISO()
												const to =
													DateTime.local().toISO()

												setMode({
													type: "custom",
													from,
													to,
												})
											}}
											className={classNames(
												"rounded-l-none border-l-0",
												mode.type === "custom" &&
													"relative z-10",
											)}
											variant={
												mode.type === "custom"
													? "primary"
													: "transparent"
											}
										>
											{t(
												"investments:investments.fiscal.range.custom",
											)}
										</Button>
									)}
								</div>
							)}

							{mode?.type === "year" &&
								Number(mode.year) !== DateTime.local().year && (
									<div className="ml-auto">
										<DownloadSelector
											options={[
												{
													name: t(
														"common:button.download.pdf",
													),
													value: DownloadableFileType.Pdf,
												},
											]}
											onDownload={handleDownload}
										/>
									</div>
								)}
						</div>
					</div>

					{/** Show client reference */}
					<Suspense fallback={null}>
						<FiscalOverviewClientReferenceHeading />
					</Suspense>

					{/* value at end and value at beginning of year overview */}
					<Suspense fallback={<OverviewLoading />}>
						<Overview />
					</Suspense>
				</CardBody>

				{/** Legal disclaoimer */}
				<Disclaimer
					title={t(
						"investments:investments.fiscal.overview.legal-disclaimer.title",
					)}
					message={t(
						"investments:investments.fiscal.overview.legal-disclaimer.copy",
					)}
				/>

				<Suspense fallback={<LoadingStateTable />}>
					<FiscalOverviewByYearTable layout="responsive" />
				</Suspense>
			</CardWrapper>
		</FiscalOverviewByYearContext.Provider>
	)
}

/**
 * FiscalOverviewClientReferenceHeading
 * @returns
 */
function FiscalOverviewClientReferenceHeading() {
	// Translations
	const t = useTrans("investments")

	// Data
	const { data } = useData()

	// Context
	const toasts = useToasts()

	// When clicking the copy button
	const onCopy = async (string: string) => {
		try {
			await copy(string)
			toasts.addToast({
				text: t(
					"investments:investments.fiscal.overview.client_reference.copy.success",
				),
				id: "copy-uuid-success",
			})
		} catch {
			toasts.addToast({
				text: t(
					"investments:investments.fiscal.overview.client_reference.copy.failed",
				),
				id: "copy-uuid-failed",
			})
		}
	}
	return (
		<SmallCard className="mb-4 flex">
			<P>
				{t("investments:investments.fiscal.overview.client_reference", {
					uuid: data?.me?.uuid,
				})}
			</P>
			<Button
				className="ml-auto"
				variant="transparent"
				size="small"
				onClick={() => onCopy(data?.me?.uuid)}
			>
				<FiCopy />
			</Button>
		</SmallCard>
	)
}

/**
 * FiscalOverviewPdfHeading
 * Show address data etc only on pdf file
 * @returns
 */
function FiscalOverviewPdfHeading() {
	const t = useTrans("investments")
	const { data } = useData()

	return (
		<div className="flex flex-row gap-4">
			{/** Personal information */}
			<SmallCard className="mb-4 w-1/2">
				<Heading as="h4" className="mt-4">
					{data?.me?.full_name}
				</Heading>
				<P>
					{t(
						"investments:investments.fiscal.overview.date_generated",
						{
							date: DateTime.now().toFormat("dd-MM-yyyy HH:mm"),
						},
					)}
				</P>
				<P>
					{t(
						"investments:investments.fiscal.overview.client_reference",
						{
							uuid: data?.me?.uuid,
						},
					)}
				</P>
				<P>
					{data?.me?.profile?.address?.address_line_primary}{" "}
					{data?.me?.profile?.address?.address_line_secondary}
				</P>
				{[
					data?.me?.profile?.address?.city,
					data?.me?.profile?.address?.postal_code,
					data?.me?.profile?.address?.region,
					data?.me?.profile?.address?.country,
				]
					.filter(Boolean)
					.map((text, index) => {
						return <P key={`${text}_${index}`}>{text}</P>
					})}
			</SmallCard>

			{/** Organisation information */}
			{data?.me?.organisations?.[0] ? (
				<SmallCard className="mb-4 w-1/2">
					<Heading as="h4" className="mt-4">
						{data?.me?.organisations?.[0]?.business_legal_name}
					</Heading>
					<P>
						{t(
							"investments:investments.fiscal.overview.business_nr",
							{
								number: data?.me?.organisations?.[0]
									?.business_nr,
							},
						)}
					</P>
					<P>
						{
							data?.me?.organisations?.[0]?.location
								?.address_line_primary
						}{" "}
						{
							data?.me?.organisations?.[0]?.location
								?.address_line_secondary
						}
					</P>
					{[
						data?.me?.organisations?.[0]?.location?.city,
						data?.me?.organisations?.[0]?.location?.postal_code,
						data?.me?.organisations?.[0]?.location?.region,
						data?.me?.organisations?.[0]?.location?.country,
					]
						.filter(Boolean)
						.map((text, index) => {
							return <P key={`${text}_${index}`}>{text}</P>
						})}
				</SmallCard>
			) : null}
		</div>
	)
}

function OverviewLoading() {
	return (
		<div className="grid grid-cols-1 gap-4 sm:grid-cols-2 xl:grid-cols-4">
			<SmallCard>
				<p className="dummy-text mb-1 rounded-md bg-gray-100 text-sm font-medium text-gray-500 md:w-[80%]" />
				<Heading
					as="h2"
					className="dummy-text rounded-md bg-gray-100 text-sm text-gray-900 md:w-[50%]"
				/>
			</SmallCard>
			<SmallCard>
				<p className="dummy-text mb-1 rounded-md bg-gray-100 text-sm font-medium text-gray-500 md:w-[80%]" />
				<Heading
					as="h2"
					className="dummy-text rounded-md bg-gray-100 text-sm text-gray-900 md:w-[50%]"
				/>
			</SmallCard>
		</div>
	)
}

function useFiscalOverviewByYearQueryWithYear(
	options = { keepPreviousData: false },
) {
	const mode = useContext(FiscalOverviewByYearContext)

	const start: string = useMemo(() => {
		if (mode.type === "custom") {
			return DateTime.fromISO(mode.from).toFormat(apiDateFormat)
		}

		// we need to get the date in NL, so we can set the timezone to Amsterdam
		// then we get the start of the year, and then the backend expects the date in UTC
		// note: i had to set millisecond: 0 otherwise it was coming out in the iso timestamp
		return DateTime.local()
			.setZone(DEFAULT_TIMEZONE)
			.set({
				year: parseInt(mode.year),
			})
			.startOf("year")
			.toFormat(apiDateFormat)
	}, [mode])

	const end: string = useMemo(() => {
		if (mode.type === "custom") {
			return DateTime.fromISO(mode.to).toFormat(apiDateFormat)
		}

		// we can safely use the beginning of next year since the end date is exlusive
		return DateTime.local()
			.setZone(DEFAULT_TIMEZONE)
			.set({
				year: parseInt(mode.year) + 1,
			})
			.startOf("year")
			.toFormat(apiDateFormat)
	}, [mode])

	return useFiscalOverviewByYearQuery(
		{
			start,
			end,
		},
		options,
	)
}

function Overview() {
	const mode = useContext(FiscalOverviewByYearContext)
	const t = useTrans("investments")
	const { formatCurrency } = useLang()

	const { data } = useFiscalOverviewByYearQueryWithYear()

	const startValue = data?.me?.start?.total_investment_value
		? parseFloat(data.me.start.total_investment_value)
		: 0

	const endValue = data?.me?.end?.total_investment_value
		? parseFloat(data.me.end.total_investment_value)
		: 0

	if (mode.type === "custom") {
		const start = DateTime.fromISO(mode.from).toFormat(dateFormat)
		const end = DateTime.fromISO(mode.to).toFormat(dateFormat)

		return (
			<div className="grid-cols-2 gap-4 sm:grid-cols-2 xl:grid-cols-4">
				<Tooltip
					content={t(
						"investments:investments.fiscal.hero.returns_custom_range_start.explanation",
						{
							date: start,
						},
					)}
				>
					<div>
						<SmallCard>
							<dt className="relative mb-1 text-sm font-medium text-gray-500">
								{t(
									"investments:investments.fiscal.hero.returns_custom_range_start.title",
									{
										date: start,
									},
								)}
								<FiInfo
									className="absolute right-0 top-0"
									size={18}
								/>
							</dt>
							<dd className="text-sm text-gray-900">
								<Heading as="h2">
									{formatCurrency(startValue)}
								</Heading>
							</dd>
						</SmallCard>
					</div>
				</Tooltip>
				<Tooltip
					content={t(
						"investments:investments.fiscal.hero.returns_end_of_year.explanation",
						{
							date: end,
						},
					)}
				>
					<div>
						<SmallCard>
							<dt className="relative mb-1 text-sm font-medium text-gray-500">
								{t(
									"investments:investments.fiscal.hero.returns_end_of_year.title",
									{
										date: end,
									},
								)}

								<FiInfo
									className="absolute right-0 top-0"
									size={18}
								/>
							</dt>
							<dd className="text-sm text-gray-900">
								<Heading as="h2">
									{formatCurrency(endValue)}
								</Heading>
							</dd>
						</SmallCard>
					</div>
				</Tooltip>
			</div>
		)
	}

	if (mode.type === "year") {
		const { year } = mode

		return (
			<div className="flex gap-4">
				{/** Total Value start of year */}
				<Tooltip
					content={t(
						"investments:investments.fiscal.hero.returns_start_of_year.explanation",
						{
							year,
						},
					)}
				>
					<div className="w-full gap-4 sm:w-1/2 xl:w-1/4">
						<SmallCard>
							<dt className="relative mb-1 text-sm font-medium text-gray-500">
								{t(
									"investments:investments.fiscal.hero.returns_start_of_year.title",
									{
										year,
									},
								)}
								<FiInfo
									className="absolute right-0 top-0"
									size={18}
								/>
							</dt>
							<dd className="text-sm text-gray-900">
								<Heading as="h2">
									{formatCurrency(startValue)}
								</Heading>
							</dd>
						</SmallCard>
					</div>
				</Tooltip>

				{/** Total Value end of year if NOT current year */}
				{year !== String(new Date().getFullYear()) && (
					<Tooltip
						content={t(
							"investments:investments.fiscal.hero.returns_end_of_year.explanation",
							{
								year,
							},
						)}
					>
						<div className="w-full gap-4 sm:w-1/2 xl:w-1/4">
							<SmallCard>
								<dt className="relative mb-1 text-sm font-medium text-gray-500">
									{t(
										"investments:investments.fiscal.hero.returns_end_of_year.title",
										{
											year,
										},
									)}

									<FiInfo
										className="absolute right-0 top-0"
										size={18}
									/>
								</dt>
								<dd className="text-sm text-gray-900">
									<Heading as="h2">
										{formatCurrency(endValue)}
									</Heading>
								</dd>
							</SmallCard>
						</div>
					</Tooltip>
				)}

				{/** Total Value TODAY */}
				{year === String(new Date().getFullYear()) && (
					<Tooltip
						content={t(
							"investments:investments.fiscal.hero.returns_today.explanation",
						)}
					>
						<div className="ml-auto w-full gap-4 sm:w-1/2 xl:w-1/4">
							<SmallCard>
								<dt className="relative mb-1 text-sm font-medium text-gray-500">
									{t(
										"investments:investments.fiscal.hero.returns_today.title",
									)}

									<FiInfo
										className="absolute right-0 top-0"
										size={18}
									/>
								</dt>
								<dd className="text-sm text-gray-900">
									<Heading as="h2">
										{formatCurrency(endValue)}
									</Heading>
								</dd>
							</SmallCard>
						</div>
					</Tooltip>
				)}
			</div>
		)
	}

	return null
}

const SmallCard = ({ className, ...props }: HTMLProps<HTMLDivElement>) => (
	<div
		{...props}
		className={classNames(
			className,
			"grid-rows-[minmax(20px,1fr)_1fr] rounded-md bg-gray-50 px-4 py-3",
		)}
	/>
)
