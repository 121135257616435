import { useState, useEffect, useContext } from "react"

// Navigation
import { Routes } from "@/constants/routes"
import { useNavigate } from "@/lib/router"

// SEO
import { Helmet } from "@/lib/seo"

// Translations
import { useLang } from "@/context/lang"
import { useTrans } from "@/lib/i18n"

// UI
import { Heading } from "@/components/Typography"
import { CardBody, CardWrapper, Card } from "@/components/Card"
import { Label } from "@/components/form-controls/Label"
import { useToasts } from "@/context/toasts"
import { Disclaimer } from "@/components/Disclaimer"
import { ConfirmDialog } from "@/components/dialogs/ConfirmDialog"
import { Button } from "@/components/Button"

// Graphql
import {
	CoreInvestmentTestCategoryChoices,
	useInvestorTestMutationsInputMutation,
} from "@/api/graphql"

// DateTime
import { DateTime } from "@/lib/dates"
import { dateFormat } from "@/constants/constants"

// Forms
import {
	FormikSubmitButton,
	FormikInput,
} from "@/components/form-controls/formik"
import { FormikProvider, useFormik } from "formik"
import { Yup } from "@/lib/forms"

// Icons
import { CheckIcon } from "@heroicons/react/outline"

// Context
import { useInvestmentTests } from "@/context/investmentTests"
import { useHiddenGetParamsContext } from "@/context/hiddenGetParams"
import { useCurrentUserId } from "@/context/user"
import { RedirectContext } from "@/context/redirectContext"

// Tracking
import { SentryReplayPrivacyClassNames } from "@/lib/sentry"
import { enableGoogleTagManager } from "@/lib/analytics"
import { classNames } from "@/lib/ui"

// Environment variables
import { API_URL } from "@/lib/env"

/**
 * Calculate total net worth
 * @param totalNormalIncome
 * @param totalExtraIncome
 * @param totalLiquidAssets
 * @param totalYearlyExpenses
 * @returns
 */
function calculateTotalNetWorth(
	totalNormalIncome: number,
	totalExtraIncome: number,
	totalLiquidAssets: number,
	totalYearlyExpenses: number,
) {
	return (
		Number(totalNormalIncome) +
		Number(totalExtraIncome) +
		Number(totalLiquidAssets) -
		Number(totalYearlyExpenses)
	)
}

/**
 * Calculate total capacity for loss
 * @param totalNormalIncome
 * @param totalExtraIncome
 * @param totalLiquidAssets
 * @param totalYearlyExpenses
 */
function calculateTotalCapacityForLoss(totalNetWorth: number) {
	return (Number(totalNetWorth) / 100) * 10
}

/**
 * Converts string values to number with two decimals
 *
 * TODO: Make this work for large numbers like 10.000 as well!
 * @param amount
 * @returns
 */
function convertMoneyStringToNumberFormat(amount: string) {
	const stringWithDotsInsteadOfComma = amount.replace(",", ".")
	const parsedValue = parseFloat(stringWithDotsInsteadOfComma)
	const roundedValue = Number(parsedValue.toFixed(2))
	return isNaN(roundedValue) ? "" : roundedValue
}

/**
 * Risk Calculator
 *
 * TODO: Unit test these calculations and make sure they dont give any weird negative values!
 * @returns
 */
export const InvestorRisk = () => {
	// State
	const [showLanguageDisclaimer, setShowLanguageDisclaimer] =
		useState<boolean>(false)

	// Context
	const { investorTestRisk, refetch } = useInvestmentTests()
	const { next } = useHiddenGetParamsContext()
	const { id: userId } = useCurrentUserId()
	const { investorTestQuestions } = useInvestmentTests()
	const { redirect } = useContext(RedirectContext)

	// Translations
	const t = useTrans("investor")
	const { lang, setLang, formatCurrency } = useLang()

	// Hack to always reset to lang=NL on this page because we don't have the legal english texts yet
	useEffect(() => {
		if (lang !== "nl") {
			setShowLanguageDisclaimer(true)
			setLang("nl")
		}
	}, [lang, setLang])

	// When visiting this page, disable all trackers (GTM)
	useEffect(() => {
		enableGoogleTagManager(false)

		// When unmounting, enable tracking again
		return () => {
			enableGoogleTagManager(true)
		}
	}, [])

	// Internal state
	const [totalNetWorth, setTotalNetWorth] = useState<number>()
	const [totalCapacityForLoss, setTotalCapacityForLoss] = useState<number>()

	// Router
	const toasts = useToasts()

	// Mutation
	const createNewTestSubmission = useInvestorTestMutationsInputMutation({
		onSuccess: async (response) => {
			// do we have data? then success
			if (response?.investment_test_update?.investor_test?.id) {
				toasts.addToast({
					variant: "success",
					id: `notifications-success-${Date.now()}`,
					text: t("Bedankt voor het invullen van de test"),
				})

				// Refetch data
				refetch()

				// Scroll to top
				window.scrollTo(0, 0)

				// When 'next' param is set in context, redirect there in the API
				if (next && Boolean(investorTestQuestions)) {
					redirect(`${API_URL}${next}`)
				}
			}
		},
	})

	// Forms
	const formCalculation = useFormik({
		initialValues: {
			totalNormalIncome: 0,
			totalExtraIncome: 0,
			totalLiquidAssets: 0,
			totalYearlyExpenses: 0,
		},
		validationSchema: Yup.object().shape({
			totalNormalIncome: Yup.string()
				.required()
				.matches(/^[0-9,.-]+$/),
			totalExtraIncome: Yup.string()
				.required()
				.matches(/^[0-9,.-]+$/),
			totalLiquidAssets: Yup.string()
				.required()
				.matches(/^[0-9,.-]+$/),
			totalYearlyExpenses: Yup.string()
				.required()
				.matches(/^[0-9,.-]+$/),
		}),
		onSubmit: async (values) => {
			const totalNetWorth = calculateTotalNetWorth(
				values.totalNormalIncome,
				values.totalExtraIncome,
				values.totalLiquidAssets,
				values.totalYearlyExpenses,
			)
			setTotalNetWorth(totalNetWorth)
			setTotalCapacityForLoss(
				calculateTotalCapacityForLoss(totalNetWorth),
			)
		},
	})

	const handleFormFieldBlur = (
		event: React.ChangeEvent<HTMLInputElement>,
		setFieldValue: Function,
	) => {
		const { value, name } = event.target
		setFieldValue(name, convertMoneyStringToNumberFormat(value))
	}

	return (
		<>
			<Helmet>
				<title>{t("investor:investor.risk.title")}</title>
			</Helmet>

			<ConfirmDialog
				className={"sentry-block" as SentryReplayPrivacyClassNames}
				isOpen={Boolean(totalCapacityForLoss)}
				onClose={() => setTotalCapacityForLoss(undefined)}
				title={t("investor:investor.risk.result.advice-amount", {
					totalCapacityForLoss: formatCurrency(
						totalCapacityForLoss ?? 0,
					),
				})}
				description={t("investor:investor.risk.result.advice-text", {
					totalNetWorth: formatCurrency(totalNetWorth ?? 0),
					totalCapacityForLoss: formatCurrency(
						totalCapacityForLoss ?? 0,
					),
				})}
				buttons={{
					yes: (
						<Button
							type="submit"
							onClick={async () => {
								await createNewTestSubmission
									.mutateAsync({
										input: {
											investor: userId || "",
											category:
												CoreInvestmentTestCategoryChoices.LossCalculator,
											data: JSON.stringify({
												"total-capacity-for-loss":
													totalCapacityForLoss,
											}),
										},
									})
									.then(() => {
										setTotalCapacityForLoss(undefined)
									})
							}}
						>
							{t("investor:investor.risk.result.agree")}
						</Button>
					),
					no: <></>,
				}}
				buttonText={{
					no: "nee",
				}}
			/>

			<CardWrapper
				className={classNames(
					"sentry-block" as SentryReplayPrivacyClassNames,
					"relative",
				)}
			>
				{Boolean(investorTestRisk) === true && <StepCompletedOverlay />}

				<CardBody className="w-full lg:w-2/3">
					<Heading as="h2" styleAs="h5" className="mb-3 sm:truncate">
						{t("investor:investor.risk.heading")}
					</Heading>

					{showLanguageDisclaimer === true && (
						<Disclaimer
							className="mb-3"
							title={t(
								"investor:investor.generic.legal-disclaimer.dutch.title",
							)}
							message={t(
								"investor:investor.generic.legal-disclaimer-dutch.message",
							)}
						/>
					)}

					<p>{t("investor:investor.risk.privacy")}</p>

					<div>
						<FormikProvider value={formCalculation}>
							<form onSubmit={formCalculation.handleSubmit}>
								<div className="mt-4">
									<Label>
										{t(
											"investor:investor.risk.form.fields.regular-income.title",
										)}
									</Label>
									<p className="mb-1">
										{t(
											"investor:investor.risk.form.fields.regular-income.subtitle",
										)}
									</p>

									<FormikInput
										name="totalNormalIncome"
										showIcon="bs-currency-euro"
										required
										className="w-full"
										onBlur={(event) =>
											handleFormFieldBlur(
												event,
												formCalculation.setFieldValue,
											)
										}
									/>
								</div>
								<div className="mt-4">
									<Label>
										{t(
											"investor:investor.risk.form.fields.extra-income.title",
										)}
									</Label>
									<p className="mb-1">
										{t(
											"investor:investor.risk.form.fields.extra-income.subtitle",
										)}
									</p>

									<FormikInput
										name="totalExtraIncome"
										showIcon="bs-currency-euro"
										required
										className="w-full"
										onBlur={(event) =>
											handleFormFieldBlur(
												event,
												formCalculation.setFieldValue,
											)
										}
									/>
								</div>
								<div className="mt-4">
									<Label>
										{t(
											"investor:investor.risk.form.fields.liquid-assets.title",
										)}
									</Label>
									<p className="mb-1">
										{t(
											"investor:investor.risk.form.fields.liquid-assets.subtitle",
										)}
									</p>

									<FormikInput
										name="totalLiquidAssets"
										showIcon="bs-currency-euro"
										required
										className="w-full"
										onBlur={(event) =>
											handleFormFieldBlur(
												event,
												formCalculation.setFieldValue,
											)
										}
									/>
								</div>
								<div className="mt-4">
									<Label>
										{t(
											"investor:investor.risk.form.fields.expenses.title",
										)}
									</Label>
									<p className="mb-1">
										{t(
											"investor:investor.risk.form.fields.expenses.subtitle",
										)}
									</p>

									<FormikInput
										name="totalYearlyExpenses"
										showIcon="bs-currency-euro"
										required
										className="w-full"
										onBlur={(event) =>
											handleFormFieldBlur(
												event,
												formCalculation.setFieldValue,
											)
										}
									/>
								</div>

								<FormikSubmitButton className="mt-4">
									{t(
										"investor:investor.risk.form.buttons.calculate",
									)}
								</FormikSubmitButton>
							</form>
						</FormikProvider>
					</div>

					{/** Continue investment flow */}
					{next && (
						<Disclaimer
							className={"mt-4"}
							message="Je investeerdersprofiel is bijna compleet. Nadat de stappen zijn afgerond kun je doorgaan met je investering."
							title={""}
						/>
					)}
				</CardBody>
			</CardWrapper>
		</>
	)
}

/**
 * StepCompletedOverlay
 * @param param0
 * @returns
 */
const StepCompletedOverlay = () => {
	// i18n
	const t = useTrans("investor")
	const { formatCurrency } = useLang()

	// Navigate
	const navigate = useNavigate()

	// Context
	const { amountOfRequiredActionsLeft, requiredActions, investorTestRisk } =
		useInvestmentTests()

	// Parse JSON data
	const totalCapacityForLoss = JSON.parse(investorTestRisk?.data)[
		"total-capacity-for-loss"
	]

	// When clicking next button, determine next step
	function onClickButton() {
		const nextStep = Object.keys(requiredActions).find(
			(key) =>
				requiredActions[key as CoreInvestmentTestCategoryChoices] ===
				true,
		)
		switch (nextStep) {
			case "KYC":
				navigate(Routes.InvestorIdentity)
				break
			case "EXPERIENCE":
				navigate(Routes.InvestorProfile)
				break
			case "QUESTIONS":
				navigate(Routes.InvestorTest)
				break
			case "LOSS_CALCULATOR":
				navigate(Routes.InvestorRisk)
		}
	}

	// Template
	return (
		<div className="absolute inset-0 z-10 bg-gray-600 bg-opacity-50 p-5 text-center text-lg">
			<Card className="mx-auto max-w-md">
				<div className="space-y-4 text-center">
					<Heading
						as="h2"
						styleAs="h5"
						className="flex justify-center gap-2"
					>
						<div className="flex items-center">
							<CheckIcon
								aria-hidden="true"
								className="h-5 w-5 text-lg text-green-500"
							/>
						</div>
						{`Voltooid op ${DateTime.fromISO(
							investorTestRisk?.created_at,
						).toFormat(dateFormat)}`}
					</Heading>

					{/** If we have this value */}
					{totalCapacityForLoss && (
						<p className="text-gray-500">
							{`Uw adviesbedrag is: ${formatCurrency(
								totalCapacityForLoss,
							)}`}
						</p>
					)}

					{amountOfRequiredActionsLeft !== undefined &&
						amountOfRequiredActionsLeft !== 0 && (
							<Button onClick={() => onClickButton()}>
								{t("investor:investor.profile.generic.button")}
							</Button>
						)}
				</div>
			</Card>
		</div>
	)
}
