import { useFormik } from "formik"
import { useState } from "react"
import { useTrans } from "@/i18n"
import { Yup } from "@/lib/forms"
import { ButtonLink } from "@/components/Button"
import { CardBody, CardFooter, CardWrapper } from "@/components/Card"
import { FormikError } from "@/components/form-controls/Errors"
import {
	FormikErrors,
	FormikInput,
	FormikLabel,
	FormikSubmitButton,
	FormikWrapper,
} from "@/components/form-controls/formik"
import { LabelSubtitle } from "@/components/form-controls/Label"
import { Heading } from "@/components/Typography"

const validationSchema = Yup.object().shape({
	code: Yup.string()
		.required("profile.security.verify_code.code.error.required")
		.min(6, "profile.security.verify_code.code.error.6_characters")
		.max(6, "profile.security.verify_code.code.error.6_characters"),
})

interface ProfileSecurityVerifyCodeProps {
	step?: number
}

export const ProfileSecurityVerifyCode = ({
	step: stepFromProps = 1,
}: ProfileSecurityVerifyCodeProps) => {
	const [step, set] = useState(stepFromProps)
	const t = useTrans("profile")

	const form = useFormik({
		initialValues: {
			code: "",
		},
		onSubmit: async (_values, _actions) => {
			set(2)
		},
		validationSchema,
	})

	return (
		<FormikWrapper form={form}>
			<CardWrapper>
				<CardBody>
					<Heading as="h5" className="mb-3 sm:truncate">
						{t("profile.security.heading")}
					</Heading>

					<div className="space-y-6">
						{step === 1 ? (
							<div>
								<FormikLabel htmlFor="code">
									{t("profile.security.verify_code.label")}
									<LabelSubtitle>
										{t("profile.security.verify_code.copy")}
									</LabelSubtitle>
								</FormikLabel>
								<FormikInput
									name="code"
									required
									className="block w-full"
									classNameWrapper="lg:w-1/2"
									autoFocus
									placeholder={t(
										"profile.security.verify_code.code.placeholder",
									)}
								/>
								<FormikError field="code" namespace="profile" />
							</div>
						) : null}
						{step === 2 ? (
							<p className="text-sm text-gray-500 sm:text-base">
								{t("profile.security.verify_code.success")}
							</p>
						) : null}
					</div>

					<FormikErrors i18nNamespace="profile" />
				</CardBody>
				<CardFooter className="flex text-right">
					<ButtonLink
						variant="empty"
						to="/profile/security/trust-agent"
					>
						{t("profile.security.verify_code.form.back")}
					</ButtonLink>
					<FormikSubmitButton className="ml-auto">
						{t("profile.security.verify_code.form.next")}
					</FormikSubmitButton>
				</CardFooter>
			</CardWrapper>
		</FormikWrapper>
	)
}
