import { forwardRef, HTMLProps } from "react"
import { classNames } from "@/lib/ui"

// need to omit ref since we are using forward ref
// but i forgot exactly why
export type RadioProps = Omit<HTMLProps<HTMLInputElement>, "ref">

export const Radio = forwardRef<HTMLInputElement, RadioProps>(
	({ className, ...rest }, ref) => {
		return (
			<input
				type="radio"
				ref={ref}
				className={classNames(
					className,
					"text-primary-500 focus:ring-primary-500 h-4 w-4 border-gray-300",
				)}
				{...rest}
			/>
		)
	},
)
