import { AnimatePresence, motion } from "@/lib/animations"
import { forwardRef, HTMLProps } from "react"
import { classNames } from "@/lib/ui"

// Icons
import { BsCurrencyEuro } from "react-icons/bs"
import { FiSearch } from "react-icons/fi"

// need to omit ref since we are using forward ref
// but i forgot exactly why
export type InputProps = Omit<HTMLProps<HTMLInputElement>, "ref"> & {
	hasError?: boolean
	classNameWrapper?: string
	showIcon?: "bs-currency-euro"
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
	(
		{ className = "", classNameWrapper = "", hasError, showIcon, ...props },
		ref,
	) => {
		return (
			<div className={classNames("relative", classNameWrapper)}>
				{showIcon === "bs-currency-euro" && (
					<label
						className={classNames(
							"pointer-events-none absolute inset-y-0 left-0 flex h-full items-center pl-3",
						)}
					>
						<BsCurrencyEuro />
					</label>
				)}

				<input
					type="text"
					ref={ref}
					{...props}
					className={classNames(
						className,
						"appearance-none",
						showIcon === "bs-currency-euro" && "pl-8", // Padding left Because of the euro sign
						"border",
						hasError ? `border-red-300` : `border-gray-300`,
						hasError && `text-red-600`,
						"rounded-md shadow-sm",
						"placeholder-gray-400",
						"focus-within:text-dark focus:outline-none",
						hasError
							? `focus:ring-red-500`
							: `focus:ring-primary-500`,
						hasError
							? `focus:border-red-500`
							: `focus:border-primary-500`,
						"text-sm disabled:bg-gray-100",
					)}
				/>
				<AnimatePresence>
					{hasError && (
						<motion.div
							initial={{ opacity: 0 }}
							animate={{
								opacity: 1,
							}}
							exit={{ opacity: 0 }}
							key="error-icon"
							className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
						>
							<svg
								className="h-5 w-5 text-red-500"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 20 20"
								fill="currentColor"
								aria-hidden="true"
							>
								<path
									fillRule="evenodd"
									d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
									clipRule="evenodd"
								/>
							</svg>
						</motion.div>
					)}
				</AnimatePresence>
			</div>
		)
	},
)

export function InputMask({
	className,
	...rest
}: HTMLProps<HTMLParagraphElement>) {
	return (
		<p
			data-testid="inputmask"
			className={classNames(
				className,
				"appearance-none rounded-md border border-transparent px-3 py-2 hover:border-gray-300 hover:bg-white disabled:bg-gray-100 sm:text-sm",
			)}
			{...rest}
		/>
	)
}

interface SearchInputProps extends HTMLProps<HTMLInputElement> {
	label?: string
	containerClassName?: string
	labelClassName?: string
}

export function SearchInput({
	label,
	name = "search",
	containerClassName,
	labelClassName,
	className,
	...rest
}: SearchInputProps) {
	return (
		<div
			className={classNames(
				containerClassName,
				"focus-within:text-dark relative text-gray-500 hover:text-gray-900 hover:placeholder-gray-900",
			)}
		>
			<label
				htmlFor={name}
				className={classNames(
					labelClassName,
					"pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3",
				)}
			>
				{label && <span className="sr-only">{label}</span>}
				<FiSearch />
			</label>
			<input
				id={name}
				name={name}
				className={classNames(
					className,
					"border border-transparent bg-gray-300 py-2 pl-10 pr-3 leading-5",
					"bg-opacity-25 focus:bg-white",
					"focus:ring-primary-500 appearance-none rounded-md border px-3 py-2 shadow-sm focus:outline-none",
					"focus:border-primary-500 placeholder-gray-500 hover:placeholder-gray-900",
				)}
				type="search"
				placeholder={label}
				data-testid="searchinput"
				{...rest}
			/>
		</div>
	)
}
