// React
import { useContext } from "react"

// UI
import { classNames } from "@/lib/ui"
import { Heading } from "@/components/Typography"
import { Card, CardWrapper } from "@/components/Card"
import {
	Table,
	TableBody,
	TableDataCell,
	TableHead,
	TableHeading,
	TableRowCell,
} from "@/components/table-controls/TableItems"
import {
	getCoreRowModel,
	useReactTable,
	flexRender,
	createColumnHelper,
} from "@/lib/table"

// Context
import { FinancePerformanceContext } from "../pages/Performance"

// Translations
import { useTrans } from "@/i18n"

// Hooks
import { useCurrentFinanceProjectId } from "../hooks/useCurrentFinanceProjectId"

// Queries
import { useApiV1ComparisonProjectProductionFactorList } from "@/api/rest/generated/api/api"
import { ProductionFactor } from "@/api/rest/generated/@types"

// Dates
import { DateTime } from "@/lib/dates"

/**
 * ProductionFactorTable
 * @returns
 */
export function ProductionFactorTable({
	className = "",
}: {
	className?: string
}) {
	const t = useTrans("finance")
	const currentProjectId = useCurrentFinanceProjectId()
	const { data } =
		useApiV1ComparisonProjectProductionFactorList(currentProjectId)

	// Context
	const {
		selectedDateRangeStart,
		setSelectedDateRangeStart,
		setSelectedDateRangeEnd,
	} = useContext(FinancePerformanceContext)

	// Tables
	const columnHelper = createColumnHelper<ProductionFactor>()
	const columns = [
		columnHelper.accessor("start", {
			header: () =>
				t(
					"finance:finance.performance.production-factor.table.heading.solar-interest-period",
				),
			cell: ({ row }) => (
				<div
					onClick={() => {
						setSelectedDateRangeStart(
							DateTime.fromFormat(
								row.original.start,
								"dd-MM-yyyy",
							).toJSDate(),
						)
						setSelectedDateRangeEnd(
							DateTime.fromFormat(
								row.original.end,
								"dd-MM-yyyy",
							).toJSDate(),
						)
					}}
					className="cursor-pointer"
				>
					{row.original.start}
				</div>
			),
		}),
		columnHelper.accessor("production_factor", {
			header: () =>
				t(
					"finance.performance.production-factor.table.heading.production-factor",
				),
			cell: (info) => info.getValue(),
		}),
	]
	const table = useReactTable({
		data: data ?? [],
		columns,
		getCoreRowModel: getCoreRowModel(),
	})

	return (
		<CardWrapper className={className}>
			<Card>
				<Heading as="h3" styleAs="h6" className="mr-2 lg:mr-0">
					{t("finance:finance.performance.production-factor.title")}
				</Heading>
				<Table>
					<TableHead>
						{table.getHeaderGroups().map((headerGroup) => (
							<TableRowCell key={headerGroup.id}>
								{headerGroup.headers.map((header) => (
									<TableHeading
										key={header.id}
										variant="static"
									>
										{header.isPlaceholder
											? null
											: flexRender(
													header.column.columnDef
														.header,
													header.getContext(),
											  )}
									</TableHeading>
								))}
							</TableRowCell>
						))}
					</TableHead>
					<TableBody>
						{table.getRowModel().rows.map((row, index) => (
							<TableRowCell
								key={row.id}
								isOdd={index % 2 === 0}
								className={classNames(
									DateTime.fromFormat(
										row.original.start,
										"dd-MM-yyyy",
									).equals(
										DateTime.fromJSDate(
											selectedDateRangeStart,
										),
									) && "font-bold",
								)}
							>
								{row.getAllCells().map((cell) => (
									<TableDataCell key={cell.id}>
										{flexRender(
											cell.column.columnDef.cell,
											cell.getContext(),
										)}
									</TableDataCell>
								))}
							</TableRowCell>
						))}
					</TableBody>
				</Table>
			</Card>
		</CardWrapper>
	)
}
