// React
import { Fragment, useMemo } from "react"

// Router
import { useParams } from "@/lib/router"

// Queries
import {
	ProjectTypeEnum,
	StatsInformationByInterestPeriodType,
	useSingleProjectStatsInterestPeriodsQuery,
} from "@/api/graphql"

// UI
import { Card } from "@/components/Card"

// Tables
import {
	useReactTable,
	flexRender,
	CellContext,
	createColumnHelper,
	getCoreRowModel,
} from "@/lib/table"
import {
	Table,
	TableBody,
	TableDataCell,
	TableRowCell,
	TableHead,
	TableHeading,
} from "@/components/table-controls/TableItems"

// I18n
import { useTrans } from "@/i18n"

/**
 * ProjectSolarInterestPeriodsTable
 * @returns
 */
export function ProjectSolarInterestPeriodsTable() {
	// I18n
	const t = useTrans("dashboard")

	// Router
	const params = useParams()

	// Fetch
	const { data } = useSingleProjectStatsInterestPeriodsQuery({
		id: String(params.id),
	})
	const currentProject = data?.projects?.at(0)

	// Memo
	const stats = useMemo(() => {
		return (
			(data?.project_statistics
				?.stats_information_by_interest_period as StatsInformationByInterestPeriodType[]) ||
			[]
		)
	}, [data?.project_statistics?.stats_information_by_interest_period])

	// Table
	const columnHelper =
		createColumnHelper<StatsInformationByInterestPeriodType>()
	const columns = useMemo(
		() => [
			columnHelper.accessor((_row, rowIndex) => rowIndex + 1, {
				id: "interest_period",
				header: () => (
					<TableHeading variant="static" className="w-10">
						{t(
							"project:project.revenue.table.headers.interest_period",
						)}
					</TableHeading>
				),
				cell: (
					info: CellContext<
						StatsInformationByInterestPeriodType,
						number
					>,
				) => (
					<TableDataCell className="w-10 font-medium">
						{info.getValue()}
					</TableDataCell>
				),
			}),
			columnHelper.accessor("production_per_share", {
				header: () => (
					<TableHeading>
						{t(
							"dashboard:dashboard.stats.project_stats.table.interest_periods.production_per_share",
						)}
					</TableHeading>
				),
				cell: (
					info: CellContext<
						StatsInformationByInterestPeriodType,
						string
					>,
				) => (
					<TableDataCell>
						{Number(info.getValue()).toFixed(2)}
					</TableDataCell>
				),
			}),
			columnHelper.accessor("energy_price", {
				header: () => (
					<TableHeading>
						{t(
							"dashboard:dashboard.stats.project_stats.table.interest_periods.energy_price",
						)}
					</TableHeading>
				),
				cell: (
					info: CellContext<
						StatsInformationByInterestPeriodType,
						string
					>,
				) => (
					<TableDataCell>
						€ {Number(info.getValue()).toFixed(4)}
					</TableDataCell>
				),
			}),
			columnHelper.accessor("solar_interest_per_share", {
				header: () => (
					<TableHeading>
						{t(
							"dashboard:dashboard.stats.project_stats.table.interest_periods.solar_interest_per_share",
						)}
					</TableHeading>
				),
				cell: (
					info: CellContext<
						StatsInformationByInterestPeriodType,
						string
					>,
				) => (
					<TableDataCell>
						€ {Number(info.getValue()).toFixed(2)}
					</TableDataCell>
				),
			}),
			columnHelper.accessor("payment_per_share", {
				header: () => (
					<TableHeading>
						{t(
							"dashboard:dashboard.stats.project_stats.table.interest_periods.payment_per_share",
						)}
					</TableHeading>
				),
				cell: (
					info: CellContext<
						StatsInformationByInterestPeriodType,
						string
					>,
				) => (
					<TableDataCell>
						€ {Number(info.getValue()).toFixed(2)}
					</TableDataCell>
				),
			}),
			columnHelper.accessor("return_on_investment", {
				header: () => (
					<TableHeading>
						{t(
							"dashboard:dashboard.stats.project_stats.table.interest_periods.return_on_investment",
						)}
					</TableHeading>
				),
				cell: (
					info: CellContext<
						StatsInformationByInterestPeriodType,
						string
					>,
				) => (
					<TableDataCell>
						{Number(info.getValue()).toFixed(2)} %
					</TableDataCell>
				),
			}),
			columnHelper.accessor("expected_production_per_share", {
				header: () => (
					<TableHeading>
						{t(
							"dashboard:dashboard.stats.project_stats.table.interest_periods.expected_production_per_share",
						)}
					</TableHeading>
				),
				cell: (
					info: CellContext<
						StatsInformationByInterestPeriodType,
						string
					>,
				) => (
					<TableDataCell>
						{Number(info.getValue()).toFixed(2)}
					</TableDataCell>
				),
			}),
			columnHelper.accessor("expected_solar_interest_per_share", {
				header: () => (
					<TableHeading>
						{t(
							"dashboard:dashboard.stats.project_stats.table.interest_periods.expected_solar_interest_per_share",
						)}
					</TableHeading>
				),
				cell: (
					info: CellContext<
						StatsInformationByInterestPeriodType,
						string
					>,
				) => (
					<TableDataCell>
						€ {Number(info.getValue()).toFixed(2)}
					</TableDataCell>
				),
			}),
			columnHelper.accessor("expected_payment_per_share", {
				header: () => (
					<TableHeading>
						{t(
							"dashboard:dashboard.stats.project_stats.table.interest_periods.expected_payment_per_share",
						)}
					</TableHeading>
				),
				cell: (
					info: CellContext<
						StatsInformationByInterestPeriodType,
						string
					>,
				) => (
					<TableDataCell>
						€ {Number(info.getValue()).toFixed(2)}
					</TableDataCell>
				),
			}),
		],
		[],
	)
	const table = useReactTable({
		columns,
		data: stats,
		getCoreRowModel: getCoreRowModel(),
		enableHiding: true,
		state: {
			columnVisibility: {
				expected_production_per_share:
					currentProject?.type === ProjectTypeEnum.SolarShares,
				production_per_share:
					currentProject?.type === ProjectTypeEnum.SolarShares,
			},
		},
	})

	return (
		<Card className="row mt-5">
			<Table className="min-w-[64rem] lg:min-w-0" data-testid="tablebody">
				<TableHead>
					{table.getHeaderGroups().map((headerGroup) => (
						<TableRowCell key={headerGroup.id}>
							{headerGroup.headers.map((header) => {
								return (
									<Fragment key={header.id}>
										{flexRender(
											header.column.columnDef.header,
											header.getContext(),
										)}
									</Fragment>
								)
							})}
						</TableRowCell>
					))}
				</TableHead>

				<TableBody
					data-testid="tablebody-overview"
					data-pageindex={table.getState().pagination.pageIndex}
				>
					{table.getRowModel().rows.map((row) => {
						const isOdd = row.index % 2 === 0
						return (
							<Fragment key={row.id}>
								<TableRowCell isOdd={isOdd}>
									{row
										.getVisibleCells()
										.map((cell, index) => {
											return (
												<Fragment key={index}>
													{flexRender(
														cell.column.columnDef
															.cell,
														cell.getContext(),
													)}
												</Fragment>
											)
										})}
								</TableRowCell>
							</Fragment>
						)
					})}
				</TableBody>
			</Table>
		</Card>
	)
}

/**
 * ProjectSolarInterestPeriodsTableLoading
 * @returns
 */
export function ProjectSolarInterestPeriodsTableLoading() {
	const t = useTrans("dashboard")

	return (
		<Card className="row mt-5">
			<Table className="min-w-[64rem] lg:min-w-0">
				<TableHead>
					<TableRowCell>
						<TableHeading>
							{t(
								"dashboard.stats.project_stats.table.interest_periods.period",
							)}
						</TableHeading>
						<TableHeading>
							{t(
								"dashboard.stats.project_stats.table.interest_periods.production_per_share",
							)}
						</TableHeading>
						<TableHeading>
							{t(
								"dashboard.stats.project_stats.table.interest_periods.energy_price",
							)}
						</TableHeading>
						<TableHeading>
							{t(
								"dashboard.stats.project_stats.table.interest_periods.solar_interest_per_share",
							)}
						</TableHeading>
						<TableHeading>
							{t(
								"dashboard.stats.project_stats.table.interest_periods.payment_per_share",
							)}
						</TableHeading>
						<TableHeading>
							{t(
								"dashboard.stats.project_stats.table.interest_periods.return_on_investment",
							)}
						</TableHeading>
						<TableHeading>
							{t(
								"dashboard.stats.project_stats.table.interest_periods.expected_production_per_share",
							)}
						</TableHeading>
						<TableHeading>
							{t(
								"dashboard.stats.project_stats.table.interest_periods.expected_solar_interest_per_share",
							)}
						</TableHeading>
						<TableHeading>
							{t(
								"dashboard.stats.project_stats.table.interest_periods.expected_payment_per_share",
							)}
						</TableHeading>
						<TableHeading>
							{t(
								"dashboard.stats.project_stats.table.interest_periods.expected_interest",
							)}
						</TableHeading>
					</TableRowCell>
				</TableHead>
				<TableBody>
					<TableRowCell>
						<TableDataCell>-</TableDataCell>
					</TableRowCell>
				</TableBody>
			</Table>
		</Card>
	)
}
