import { useMemo, useState } from "react"
import { DateTime } from "@/lib/dates"
import { useTrans } from "@/i18n"
import { ChevronDownIcon } from "@heroicons/react/outline"

// Animations
import { Popover, Transition } from "@/lib/ui"
import { getTransitionPopoverProps } from "@/lib/animations"

// UI
import { Button } from "../../../components/Button"
import { DatePicker } from "../../../components/form-controls/DatePicker"

// Graphs
import { useProductionGraphContext } from "../../../components/graphs/types/bar/ProductionGraph"
import { GraphActions } from "../../../components/graphs/header/GraphActions"

// Feature flags
import { useFeatureFlags } from "@/context/user"

/**
 * ProjectGraphProductionActions
 * @returns
 */
export function ProjectGraphProductionActions() {
	const { graphInterval, setGraphInterval } = useProductionGraphContext()
	const AvailableDatePeriods = ["day", "month", "year"]
	const { getFeatureFlagValue } = useFeatureFlags()

	return (
		<>
			{/* date picker */}
			{getFeatureFlagValue("PROJECT_DISPLAY_DATE_SELECTOR") && (
				<DateRangePicker />
			)}

			<GraphActions
				graphInterval={graphInterval}
				setGraphInterval={setGraphInterval}
				availableIntervals={AvailableDatePeriods}
			/>
		</>
	)
}

function DateRangePicker() {
	const t = useTrans("project")
	// Date
	const minimumDate = DateTime.local().minus({ weeks: 1 }).endOf("day")
	const maximumDate = DateTime.local().endOf("day")
	const currentDateTime = DateTime.local()
	const [date, setDate] = useState<Date>(maximumDate.toJSDate())

	// months to show in date picker
	const months = useMemo(() => {
		if (maximumDate.month !== minimumDate.month) {
			return [minimumDate.month, maximumDate.month]
		}
		return [maximumDate.month]
	}, [maximumDate.month, minimumDate.month])

	return (
		<div className="mr-6 items-center lg:flex">
			<h2 className="font-static text-dark mr-6 truncate text-base font-extrabold uppercase">
				{t("project.data.energy_producted.button.custom")}
			</h2>

			{/** Date Start */}
			<Popover.Group className="flex items-center divide-x divide-gray-200">
				<Popover className="relative z-10 inline-block text-right">
					<Popover.Button
						as="div"
						className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900"
					>
						<Button
							variant="transparent"
							size="small"
							className="relative rounded-none border-l-0 focus:z-20"
						>
							<span className="mr-2">
								{DateTime.fromJSDate(date).toFormat(
									t(
										"project.data.production_today.date_picker.title.date_format",
									),
								)}
							</span>

							<ChevronDownIcon
								className="h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
								aria-hidden="true"
							/>
						</Button>
					</Popover.Button>
					<Transition {...getTransitionPopoverProps()}>
						<Popover.Panel className="absolute left-0 mt-2 origin-top-right lg:left-auto lg:right-0">
							<div
								className="min-w-[300px]"
								data-testid="daterangepicker-from"
							>
								<DatePicker
									initialMonth={currentDateTime.month}
									initialYear={currentDateTime.year}
									selectedDate={currentDateTime}
									showChangeOfYear={false}
									showNavigation={false}
									months={months}
									onSelectDate={(date) => {
										const newDate =
											DateTime.fromObject(date).endOf(
												"day",
											)
										setDate(newDate.toJSDate())
									}}
									isDateDisabled={(date) => {
										const dateTime = DateTime.fromObject({
											...date,
											day: date.day + 1,
										})

										// is this date within our range?
										if (
											dateTime < maximumDate &&
											dateTime >
												minimumDate.minus({
													days: 1,
												})
										) {
											return false
										}

										return true
									}}
								/>
							</div>
						</Popover.Panel>
					</Transition>
				</Popover>
			</Popover.Group>

			{/** Date End */}
			<Popover.Group className="flex items-center divide-x divide-gray-200">
				<Popover className="relative z-10 inline-block text-right">
					<Popover.Button
						as="div"
						className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900"
					>
						<Button
							variant="transparent"
							size="small"
							className="relative rounded-none border-l-0 focus:z-20"
						>
							<span className="mr-2">
								{DateTime.fromJSDate(date).toFormat(
									t(
										"project.data.production_today.date_picker.title.date_format",
									),
								)}
							</span>

							<ChevronDownIcon
								className="h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
								aria-hidden="true"
							/>
						</Button>
					</Popover.Button>
					<Transition {...getTransitionPopoverProps()}>
						<Popover.Panel className="absolute left-0 mt-2 origin-top-right lg:left-auto lg:right-0">
							<div
								className="min-w-[300px]"
								data-testid="daterangepicker-from"
							>
								<DatePicker
									initialMonth={currentDateTime.month}
									initialYear={currentDateTime.year}
									selectedDate={currentDateTime}
									showChangeOfYear={false}
									showNavigation={false}
									months={months}
									onSelectDate={(date) => {
										const newDate =
											DateTime.fromObject(date).endOf(
												"day",
											)
										setDate(newDate.toJSDate())
									}}
									isDateDisabled={(date) => {
										const dateTime = DateTime.fromObject({
											...date,
											day: date.day + 1,
										})

										// is this date within our range?
										if (
											dateTime < maximumDate &&
											dateTime >
												minimumDate.minus({
													days: 1,
												})
										) {
											return false
										}

										return true
									}}
								/>
							</div>
						</Popover.Panel>
					</Transition>
				</Popover>
			</Popover.Group>
		</div>
	)
}
