import {
	ComponentProps,
	ReactElement,
	ReactNode,
	useContext,
	forwardRef,
	Ref,
} from "react"

// Router
import { NavLink as RouterNavLink, useMatch } from "@/lib/router"

// UI
import { classNames } from "@/lib/ui"

// Context
import { AuthLayoutContext } from "@/layouts/AuthLayout"

// Icons
import { CurrencyEuroIcon, ChartBarIcon, SunIcon } from "@/lib/icons"

import { UserRoles } from "@/constants/constants"

// Translations
import { useTrans } from "@/i18n"

// Hooks
import { useCurrentUserRoles } from "@/context/user"

// Analytics
import { sendEvent } from "@/lib/analytics"

// Utils
import { normaliseString } from "@/lib/js"

// Sub menu structures
import { InvestmentsSubMenu } from "@/components/navigation/InvestmentsSubMenu"
import { FinanceSubMenu } from "@/components/navigation/FinanceSubMenu"
import { StatsSubMenu } from "@/components/navigation/StatsSubMenu"

/**
 * Nav
 * @returns
 */
export function MainNavigation() {
	const t = useTrans()
	const { hasOneOfRoles } = useCurrentUserRoles()

	return (
		<>
			<nav className="mt-8 space-y-1 px-1 pb-4">
				{/** Stats */}
				<NavItem
					name={t("common:common.navigation.statistics.title")}
					datda-testid="common.nav.dashboard"
					icon={ChartBarIcon}
				>
					<StatsSubMenu />
				</NavItem>

				{/** InvestmentsSubMenu */}
				<NavItem
					name={t("common.navigation.investments.title")}
					datda-testid="common.nav.dashboard"
					icon={CurrencyEuroIcon}
				>
					<InvestmentsSubMenu />
				</NavItem>

				{/** FinanceSubMenu */}
				{hasOneOfRoles(
					UserRoles.energyProvider,
					UserRoles.admin,
					UserRoles.installer,
					UserRoles.staff,
					UserRoles.projectOwner,
				) ? (
					<NavItem
						name={t("common.navigation.finance.title")}
						icon={SunIcon}
						datda-testid="common.nav.investments"
					>
						<FinanceSubMenu />
					</NavItem>
				) : null}
			</nav>
		</>
	)
}

/**
 * NavItem
 * @param param0
 * @returns
 */
export function NavItem({
	href,
	icon,
	name,
	children,
}: {
	href?: string
	icon: (props: ComponentProps<"svg">) => ReactElement
	name: string
	children?: ReactNode
}) {
	return (
		<div>
			<NavLink href={href} icon={icon} name={name} children={children} />
			<div>
				{children ? (
					<div className="w-full overflow-hidden pl-10">
						<div className="py-1">{children}</div>
					</div>
				) : null}
			</div>
		</div>
	)
}

const NavLink = forwardRef<
	HTMLDivElement | HTMLAnchorElement,
	{
		href?: string
		icon: (props: ComponentProps<"svg">) => ReactElement
		name: string
		children?: ReactNode
		open?: boolean
	}
>(({ name, icon: Icon, href = "" }, ref) => {
	const { setMobileMenuOpen } = useContext(AuthLayoutContext)

	const current = useMatch(href === "/" ? { path: href, end: true } : href)

	const NavContent = () => {
		return (
			<>
				<Icon
					className="mr-2 h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
					aria-hidden="true"
				/>
				{name}
			</>
		)
	}

	return href ? (
		<RouterNavLink
			ref={ref as Ref<HTMLAnchorElement>}
			to={href}
			className={classNames(
				current
					? "bg-gray-100 text-gray-900"
					: "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
				"group flex items-center rounded-md px-2 py-2 text-sm font-medium",
			)}
			onClick={() => {
				setMobileMenuOpen(false)
				sendEvent("layout", "navitem_onclick", {
					label: normaliseString(name),
				})
			}}
		>
			<NavContent />
		</RouterNavLink>
	) : (
		<div
			className={classNames(
				current
					? "pointer-events-none bg-gray-100 text-gray-900"
					: "pointer-events-none text-gray-600 hover:bg-gray-50 hover:text-gray-900",
				"group flex items-center rounded-md px-2 py-2 text-sm font-medium",
			)}
		>
			<NavContent />
		</div>
	)
})
