// React
import { useContext } from "react"

// UI
import { classNames } from "@/lib/ui"
import { Card } from "@/components/Card"
import { Heading } from "@/components/Typography"
import { Button } from "@/components/Button"

// Context
import { RedirectContext } from "@/context/redirectContext"

// Translations
import { useTrans } from "@/i18n"

// Environment variables
import { EXTERNAL_PLATFORM_ZONHUB_INVESTMENT } from "@/lib/env"

// Icons
import { XIcon } from "@/lib/icons"

// Redux
import { useDispatch, useSelector } from "@/state/StateProvider"
import {
	UserPreferencesState,
	setShowInvestmentBanner,
} from "@/state/features/userPreferencesSlice"

/**
 * InvestmentOpportunityBanner
 * @returns
 */
export function InvestmentOpportunityBanner({
	className = "",
}: {
	className?: any
}) {
	// Translations
	const t = useTrans("investments")

	// Context
	const { redirect } = useContext(RedirectContext)

	// Redux state
	const dispatch = useDispatch()
	const showInvestmentBanner = useSelector(
		({ userPreferences }: { userPreferences: UserPreferencesState }) =>
			userPreferences.showInvestmentBanner,
	)

	return (
		<>
			{showInvestmentBanner && (
				<Card
					className={classNames(
						"relative w-full bg-yellow-50",
						className,
					)}
				>
					{/** Close button */}
					<button
						onClick={() => dispatch(setShowInvestmentBanner(false))}
						className="absolute right-4 top-4 text-gray-400 hover:text-gray-600"
						aria-label="Close banner"
					>
						<XIcon className="h-5 w-5" />
					</button>

					<Heading as="h2" styleAs="h5" className="mb-3 sm:truncate">
						{t(
							"investments:investments.banner.opportunities.title",
						)}
					</Heading>
					<div className="mb-4">
						<p className="text-sm text-gray-500">
							{t(
								"investments:investments.banner.opportunities.copy",
							)}
						</p>
					</div>
					<Button
						className="inline-block"
						onClick={() => {
							redirect(EXTERNAL_PLATFORM_ZONHUB_INVESTMENT)
						}}
					>
						{t("investments:investments.banner.opportunities.cta")}
					</Button>
				</Card>
			)}
		</>
	)
}
