// React
import { useMemo, useContext, Fragment } from "react"

// DateTime
import { DateTime, DEFAULT_TIMEZONE } from "@/lib/dates"
import { apiDateFormat } from "@/constants/constants"

// Translations
import { useTrans } from "@/i18n"
import { useLang } from "@/context/lang"

// Utils
import { getShareNumberRange } from "@/utils/helpers"

// GraphQL
import {
	PayOutState,
	ProjectType,
	PaymentEntryType,
	useFiscalOverviewByYearQuery,
} from "@/api/graphql"

// UI
import { CardBody } from "@/components/Card"
import { Heading, Subheading } from "@/components/Typography"
import { Tooltip } from "@/components/Tooltip"

// Tables
import {
	useReactTable,
	flexRender,
	getCoreRowModel,
	CellContext,
	createColumnHelper,
} from "@/lib/table"
import {
	Table,
	TableBody,
	TableDataCell,
	TableFooter,
	TableHead,
	TableHeading,
	TableRowCell,
} from "@/components/table-controls/TableItems"

// Utils
import { isWholeNumber } from "@/lib/math"

// Context
import { FiscalOverviewByYearContext } from "./FiscalOverviewByYear"

// Icons
import { FiInfo } from "@/lib/icons"

function useData() {
	return useFiscalOverviewByYearQueryWithYear()
}

function useFiscalOverviewByYearQueryWithYear(
	options = { keepPreviousData: false },
) {
	const mode = useContext(FiscalOverviewByYearContext)

	const start: string = useMemo(() => {
		if (mode.type === "custom") {
			return DateTime.fromISO(mode.from).toFormat(apiDateFormat)
		}

		// we need to get the date in NL, so we can set the timezone to Amsterdam
		// then we get the start of the year, and then the backend expects the date in UTC
		// note: i had to set millisecond: 0 otherwise it was coming out in the iso timestamp
		return DateTime.local()
			.setZone(DEFAULT_TIMEZONE)
			.set({
				year: parseInt(mode.year),
			})
			.startOf("year")
			.toFormat(apiDateFormat)
	}, [mode])

	const end: string = useMemo(() => {
		if (mode.type === "custom") {
			return DateTime.fromISO(mode.to).toFormat(apiDateFormat)
		}

		// we can safely use the beginning of next year since the end date is exlusive
		return DateTime.local()
			.setZone(DEFAULT_TIMEZONE)
			.set({
				year: parseInt(mode.year) + 1,
			})
			.startOf("year")
			.toFormat(apiDateFormat)
	}, [mode])

	return useFiscalOverviewByYearQuery(
		{
			start,
			end,
		},
		options,
	)
}

/**
 * FiscalOverviewByYearTable
 * @returns
 */
export function FiscalOverviewByYearTable({
	layout = "fixed",
}: {
	layout?: "responsive" | "fixed"
}) {
	const { formatCurrency } = useLang()
	const t = useTrans("investments")
	const { data, isPreviousData } = useData()

	const rows = useMemo(() => {
		return data?.me?.investment_projects?.results || []
	}, [data?.me?.investment_projects])

	// Tables
	const columnHelper = createColumnHelper<ProjectType>()
	const columns = [
		columnHelper.accessor((data: ProjectType) => data?.name, {
			id: "project.name",
			header: () => (
				<TableHeading variant="static">
					{t(
						"investments:investments.fiscal.table_heading.project_name",
					)}
				</TableHeading>
			),
			cell: (info: CellContext<ProjectType, string>) => (
				<TableDataCell
					showHeaderOnlyOnMobile={layout === "responsive"}
					fieldName={
						layout === "responsive"
							? t(
									"investments:investments.fiscal.table_heading.project_name",
							  )
							: undefined
					}
				>
					{info.getValue()}
				</TableDataCell>
			),
		}),
		columnHelper.accessor(
			(data: ProjectType) =>
				data?.start?.total_shares
					? isWholeNumber(data?.start?.total_shares)
						? data?.start?.total_shares
						: Number(data?.start?.total_shares ?? 0).toFixed(2)
					: "-",
			{
				id: "shares_at_start",
				header: () => (
					<TableHeading variant="static">
						{t(
							"investments:investments.fiscal.table_heading.number_of_shares",
						)}
					</TableHeading>
				),
				cell: (info: CellContext<ProjectType, string>) => (
					<TableDataCell
						multiline
						fieldName={
							layout === "responsive"
								? t(
										"investments:investments.fiscal.table_heading.number_of_shares",
								  )
								: undefined
						}
					>
						{info.getValue()}
					</TableDataCell>
				),
			},
		),
		columnHelper.accessor(
			(project: ProjectType) =>
				project?.end?.total_shares
					? isWholeNumber(project?.end?.total_shares)
						? project?.end?.total_shares
						: Number(project?.end?.total_shares ?? 0).toFixed(2)
					: "-",
			{
				id: "shares_at_end",
				header: () => (
					<TableHeading variant="static">
						{t(
							"investments:investments.fiscal.table_heading.number_of_shares_at_end_of_year",
						)}
					</TableHeading>
				),
				cell: (info: CellContext<ProjectType, string>) => (
					<TableDataCell
						fieldName={
							layout === "responsive"
								? t(
										"investments:investments.fiscal.table_heading.number_of_shares_at_end_of_year",
								  )
								: undefined
						}
					>
						{info.getValue()}
					</TableDataCell>
				),
			},
		),
		columnHelper.accessor(
			(project: ProjectType) =>
				project?.start?.total_investment_value
					? parseFloat(project?.start?.total_investment_value)
					: undefined
					? formatCurrency(
							project?.start?.total_investment_value
								? parseFloat(
										project?.start?.total_investment_value,
								  )
								: undefined ?? 0,
					  )
					: "-",
			{
				id: "value_at_start",
				header: () => (
					<TableHeading variant="static">
						{t(
							"investments:investments.fiscal.table_heading.value_at_start_of_year",
						)}
					</TableHeading>
				),
				cell: (info: CellContext<ProjectType, number>) => (
					<TableDataCell
						fieldName={
							layout === "responsive"
								? t(
										"investments:investments.fiscal.table_heading.value_at_start_of_year",
								  )
								: undefined
						}
					>
						{formatCurrency(info.getValue() || 0)}
					</TableDataCell>
				),
				footer: (info) => {
					const total = info.table.options.data.reduce(
						(sum, row: ProjectType) => {
							const value = row?.start?.total_investment_value
								? parseFloat(row.start.total_investment_value)
								: 0
							return sum + value
						},
						0,
					)

					return (
						<div className="break-word whitespace-pre-wrap">
							<Subheading className="mb-2">
								{t(
									"investments:investments.fiscal.table_footer.value_at_start_of_year",
								)}
							</Subheading>
							<p className="text-sm font-medium text-gray-700">
								{formatCurrency(total)}
							</p>
						</div>
					)
				},
			},
		),
		columnHelper.accessor(
			(project: ProjectType) =>
				project?.end?.total_investment_value
					? parseFloat(project?.end?.total_investment_value)
					: undefined
					? formatCurrency(
							project?.end?.total_investment_value
								? parseFloat(
										project?.end?.total_investment_value,
								  )
								: undefined ?? 0,
					  )
					: "-",
			{
				id: "value_at_end",
				header: () => (
					<TableHeading variant="static">
						{t(
							"investments:investments.fiscal.table_heading.value_at_end_of_year",
						)}
					</TableHeading>
				),
				cell: (info: CellContext<ProjectType, number>) => (
					<TableDataCell
						fieldName={
							layout === "responsive"
								? t(
										"investments:investments.fiscal.table_heading.value_at_end_of_year",
								  )
								: undefined
						}
					>
						{formatCurrency(info.getValue() || 0)}
					</TableDataCell>
				),
				footer: (info) => {
					const total = info.table.options.data.reduce(
						(sum, row: ProjectType) => {
							const value = row?.end?.total_investment_value
								? parseFloat(row.end.total_investment_value)
								: 0
							return sum + value
						},
						0,
					)

					return (
						<div className="break-word whitespace-pre-wrap">
							<Subheading className="mb-2">
								{t(
									"investments:investments.fiscal.table_footer.value_at_end_of_year_total",
								)}
							</Subheading>
							<p className="text-sm font-medium text-gray-700">
								{formatCurrency(total)}
							</p>
						</div>
					)
				},
			},
		),
		columnHelper.accessor(
			(project: ProjectType) =>
				data?.me?.payment_entries?.results?.find(
					(payment) =>
						payment?.project?.id === project.id &&
						payment?.state !== null && // Exclude older buckaroo payments
						payment?.state !== PayOutState.PayoutCompleted &&
						payment?.state !== PayOutState.TransferCompleted,
				),
			{
				id: "cost",
				header: () => (
					<TableHeading variant="static">
						{t(
							"investments:investments.fiscal.table_heading.unpaid_interest",
						)}
					</TableHeading>
				),
				cell: (info: CellContext<ProjectType, PaymentEntryType>) => (
					<TableDataCell
						fieldName={
							layout === "responsive"
								? t(
										"investments:investments.fiscal.table_heading.unpaid_interest",
								  )
								: undefined
						}
					>
						{/** Show tooltip only if the state is not null */}
						{info.getValue()?.cost ? (
							<Tooltip
								content={t(
									"investments:investments.fiscal.table_heading.unpaid_interest.tooltip",
								)}
							>
								<div className="flex cursor-pointer items-center">
									<FiInfo className="mr-1" />
									{formatCurrency(info.getValue()?.cost || 0)}
								</div>
							</Tooltip>
						) : (
							// Show "-" if the state is null
							"-"
						)}
					</TableDataCell>
				),
			},
		),
		columnHelper.accessor(
			(project: ProjectType) =>
				data?.me?.payment_entries?.results?.find(
					(payment) =>
						payment?.project?.id === project.id &&
						payment?.state !== null && // Exclude older buckaroo payments
						payment?.state !== PayOutState.PayoutCompleted &&
						payment?.state !== PayOutState.TransferCompleted,
				),
			{
				id: "amortization",
				header: () => (
					<TableHeading variant="static">
						{t(
							"investments:investments.fiscal.table_heading.unpaid_amortization",
						)}
					</TableHeading>
				),
				cell: (info: CellContext<ProjectType, PaymentEntryType>) => (
					<TableDataCell
						fieldName={
							layout === "responsive"
								? t(
										"investments:investments.fiscal.table_heading.unpaid_amortization",
								  )
								: undefined
						}
					>
						{/** Show tooltip only if the state is not null */}
						{info.getValue()?.amortization ? (
							<Tooltip
								content={t(
									"investments:investments.fiscal.table_heading.unpaid_amortization.tooltip",
								)}
							>
								<div className="flex cursor-pointer items-center">
									<FiInfo className="mr-1" />
									{formatCurrency(
										info.getValue()?.amortization || 0,
									)}
								</div>
							</Tooltip>
						) : (
							// Show "-" if the state is null
							"-"
						)}
					</TableDataCell>
				),
			},
		),
		columnHelper.accessor(
			(project: ProjectType) =>
				getShareNumberRange(
					project?.end?.shares?.map((share: any) =>
						Number(share?.share_number),
					),
					t,
				) ?? undefined,
			{
				id: "share_numbers",
				header: () => (
					<TableHeading variant="static">
						{t(
							"investments:investments.fiscal.table_heading.share_numbers_at_end_of_year",
						)}
					</TableHeading>
				),
				cell: (info: CellContext<ProjectType, string>) => (
					<TableDataCell
						multiline
						fieldName={
							layout === "responsive"
								? t(
										"investments:investments.fiscal.table_heading.share_numbers_at_end_of_year",
								  )
								: undefined
						}
					>
						{info.getValue()}
					</TableDataCell>
				),
			},
		),
	]
	const table = useReactTable({
		columns,
		data: rows as ProjectType[],
		getCoreRowModel: getCoreRowModel(),
	})

	return (
		<>
			<Table layout={layout}>
				{/* table header */}
				<TableHead>
					<TableRowCell>
						{table.getHeaderGroups().map((headerGroup) =>
							headerGroup.headers.map((header) => {
								return (
									<Fragment key={header.id}>
										{flexRender(
											header.column.columnDef.header,
											header.getContext(),
										)}
									</Fragment>
								)
							}),
						)}
					</TableRowCell>
				</TableHead>
				{/* table body and table cells */}
				<TableBody
					className={isPreviousData ? "opacity-25" : ""}
					data-testid="tablebody"
				>
					{table.getRowModel().rows.map((row, index) => {
						return (
							<TableRowCell
								key={row.original.id}
								isOdd={index % 2 === 0}
								data-testid={`tablerow-${row.original.id}`}
							>
								{row.getVisibleCells().map((cell) => (
									<Fragment key={cell.id}>
										{flexRender(
											cell.column.columnDef.cell,
											cell.getContext(),
										)}
									</Fragment>
								))}
							</TableRowCell>
						)
					})}
				</TableBody>
				{rows && rows.length > 0 ? (
					<TableFooter>
						<TableRowCell>
							{table.getFooterGroups().map((footerGroup) =>
								footerGroup.headers.map((header) => (
									<TableDataCell
										key={header.id}
										data-testid={`footer-${header.id}`}
										className="text-gray-600"
									>
										{header.isPlaceholder ? null : (
											<>
												{flexRender(
													header.column.columnDef
														.footer,
													header.getContext(),
												)}
											</>
										)}
									</TableDataCell>
								)),
							)}
						</TableRowCell>
					</TableFooter>
				) : null}
			</Table>
			{rows?.length === 0 && (
				<CardBody>
					<TableEmptyState />
				</CardBody>
			)}
		</>
	)
}
const TableEmptyState = () => {
	const t = useTrans("investments")

	return (
		<div className="space-y-4 p-10 text-center">
			<Heading as="h2" styleAs="h5">
				{t("investments:investments.fiscal.no_results.title")}
			</Heading>
			<p className="text-gray-500">
				{t("investments:investments.fiscal.no_results.copy")}
			</p>
		</div>
	)
}

/**
 * LoadingStateTable
 * @returns
 */
export function LoadingStateTable() {
	return (
		<Table className="min-w-[48rem] lg:min-w-0">
			<TableHead>
				<TableRowCell>
					<TableHeading className="animate-pulse" variant="static">
						<p className="dummy-text leading-[2.7]"></p>
					</TableHeading>
				</TableRowCell>
			</TableHead>
			<TableBody>
				{Array(10)
					.fill(true)
					.map((_, index) => {
						return (
							<TableRowCell
								isOdd={index % 2 === 0}
								key={index}
								withHover={false}
								className="animate-pulse"
							>
								<TableDataCell>
									<p className="dummy-text"></p>
								</TableDataCell>
							</TableRowCell>
						)
					})}
			</TableBody>
		</Table>
	)
}
