import { isMobile } from "@/lib/device"
import { motion } from "@/lib/animations"
import { classNames } from "@/lib/ui"
import { Tooltip } from "./Tooltip"
import { tooltipStyles } from "./tooltipStyles"
import {
	MultiGraphTooltipContent,
	MultiGraphTooltipProps,
} from "../../../graphs/types/bar-line/MultiGraphContent"

interface TooltipAdapterProps {
	tooltipData: MultiGraphTooltipProps
	left?: number
	top?: number
}

export const TooltipAdapter = ({
	left,
	top,
	tooltipData,
}: TooltipAdapterProps) => {
	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
		>
			{isMobile ? (
				<div
					style={tooltipStyles}
					className="z-100 left-1/2 right-1/2 top-0 w-1/2 min-w-[200px] max-w-none -translate-x-1/2 transform opacity-90"
				>
					<MultiGraphTooltipContent {...tooltipData} />
				</div>
			) : (
				<Tooltip
					left={left}
					top={top}
					className={classNames(
						"max-width-xs pointer-events-none opacity-90",
					)}
				>
					<MultiGraphTooltipContent {...tooltipData} />
				</Tooltip>
			)}
		</motion.div>
	)
}
