import { ReactNode } from "react"
import { useFormikContext } from "formik"
import { AnimatePresence, motion, Target } from "@/lib/animations"
import { useTrans } from "@/i18n"
import { classNames } from "@/lib/ui"

export function FormikError<Data extends Object | string>({
	field,
	reliesOnField,
	namespace = "common",
}: {
	field: Data extends string ? Data : keyof Data
	reliesOnField?: Data extends string ? Data : keyof Data
	namespace?: string
}) {
	const t = useTrans(namespace)
	const { touched, errors } = useFormikContext<any>()

	let error =
		touched[field] && typeof errors[field] === "string"
			? t(errors[field] as string)
			: null

	if (reliesOnField) {
		if (!touched[reliesOnField]) {
			error = null
		}
	}

	return <InlineError error={error} id={String(field)} />
}

interface ErrorProps {
	error?: ReactNode | null
	initial?: Target
	animate?: Target
	exit?: Target
	id: string
	className?: string
}

export const FormError = ({
	error,
	initial = {},
	animate = {},
	exit = {},
	id,
	className,
}: ErrorProps) => {
	return (
		<AnimatePresence>
			{error && (
				<motion.p
					className={classNames(
						"overflow-hidden text-sm text-red-600",
						className,
					)}
					initial={{
						...initial,
						opacity: 0,
						height: 0,
					}}
					animate={{
						...animate,
						opacity: 1,
						height: "auto",
					}}
					exit={{
						...exit,
						opacity: 0,
						height: 0,
					}}
					transition={{ bounce: 0 }}
					layoutId={
						typeof error === "string"
							? `${id}-${error}`
							: `${id}-error`
					}
				>
					{error}
				</motion.p>
			)}
		</AnimatePresence>
	)
}

export const InlineError = ({
	error,
	id,
}: {
	error?: string | null
	id: string
}) => {
	return (
		<FormError
			id={id}
			error={error}
			initial={{ marginTop: `0rem` }}
			animate={{
				marginTop: `0.75rem`,
			}}
			exit={{
				marginTop: `0rem`,
			}}
		/>
	)
}
