import { classNames, Switch } from "@/lib/ui"

export interface ToggleProps {
	checked: boolean
	onToggle: (enabled: boolean) => void
	label?: string
	className?: string
	disabled?: boolean
	isSubmitting?: boolean
}

export const Toggle = ({
	checked,
	onToggle,
	className,
	label,
	disabled,
	isSubmitting,
	...rest
}: ToggleProps) => {
	return (
		<Switch
			checked={checked}
			onChange={disabled ? () => {} : onToggle}
			className={classNames(
				className,
				checked ? "bg-primary-500" : "bg-gray-200",
				checked ? "hover:bg-primary-500" : "hover:bg-primary-500",
				"relative inline-flex h-6 w-11 flex-shrink-0 rounded-full border-2 border-transparent",
				"cursor-pointer transition-colors duration-200 ease-in-out",
				"focus:ring-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2",
			)}
			{...rest}
		>
			{isSubmitting && <ProgressIndicator />}
			<span className="sr-only">{label}</span>
			<span
				className={classNames(
					checked ? "translate-x-5" : "translate-x-0",
					"pointer-events-none relative inline-block h-5 w-5 rounded-full",
					"transform bg-white shadow ring-0 transition duration-200 ease-in-out",
				)}
			>
				<span
					className={classNames(
						checked
							? "opacity-0 duration-100 ease-out"
							: "opacity-100 duration-200 ease-in",
						"absolute inset-0 flex h-full w-full items-center justify-center transition-opacity",
					)}
					aria-hidden="true"
				>
					<svg
						className="h-3 w-3 text-black"
						fill="none"
						viewBox="0 0 12 12"
					>
						<path
							d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
							stroke="currentColor"
							strokeWidth={2}
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</span>
				<span
					className={classNames(
						checked
							? "opacity-100 duration-200 ease-in"
							: "opacity-0 duration-100 ease-out",
						"absolute inset-0 flex h-full w-full items-center justify-center transition-opacity",
					)}
					aria-hidden="true"
				>
					<svg
						className="h-3 w-3 text-black"
						fill="currentColor"
						viewBox="0 0 12 12"
					>
						<path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
					</svg>
				</span>
			</span>
		</Switch>
	)
}

const ProgressIndicator = () => (
	<div
		className="animate-conveyor-slow pointer-events-none absolute left-0 top-0 h-full"
		style={{
			width: `calc(100% + 40px)`,
			background: `repeating-linear-gradient(
				45deg,
				rgba(255,255,255,0.5),
				rgba(255,255,255,0.5) 10px,
				rgba(255,255,255,0) 10px,
				rgba(255,255,255,0) 20px
			)`,
		}}
	/>
)
