// React
import { createContext, Suspense, useState } from "react"

// Analytics
import { generateSendEventDebounced, sendEvent } from "@/lib/analytics"

// UI
import { classNames } from "@/lib/ui"
import {
	InvestmentsGrid,
	InvestmentsGridLoadingState,
} from "./_components/InvestmentsGrid"
import {
	InvestmentsTable,
	InvestmentsTableLoadingState,
} from "./_components/InvestmentsTable"

import { Button } from "@/components/Button"
import { SearchInput } from "@/components/form-controls/Input"
import { Select } from "@/components/form-controls"
import { Checkbox } from "@/components/form-controls/Checkbox"
import { ErrorBoundaryWithErrorState } from "@/components/errors/ErrorBoundary"

// Icons
import { FiArrowDown, FiChevronDown, FiFilter } from "@/lib/icons"

// State
import { useSelector, useDispatch } from "@/state/StateProvider"
import {
	setFilterState,
	setFilterType,
	setPerPage,
	setViewType,
	setSortingMethod,
	setSortingOrder,
	setShowHiddenProjects,
} from "@/state/features/investmentsOverview/slice"

// Translations
import { useTrans } from "@/i18n"

// Types
import {
	InvestmentsOverviewState,
	projectPageSizes,
	projectPageViewTypes,
	ProjectPageViewTypes,
	ProjectSortOrder,
	ALL_PROJECT_STATES,
	ALL_PROJECT_TYPES,
	ProjectStateEnumFiltered,
	ProjectTypeEnumFiltered,
	ProjectPageSize,
	ProjectSortMethod,
} from "@/state/features/investmentsOverview/types"

const onSearchSendEvent = generateSendEventDebounced(1000)

export const InvestmentsContext = createContext<{
	search: string
	setSearch: (search: string) => void
}>(null!)

/**
 * Investments
 * @param param0
 * @returns
 */
export const Investments = () => {
	// I18n
	const t = useTrans(["common", "investments"])

	// State
	const [search, setSearch] = useState("")
	const [showFilters, setShowFilters] = useState<boolean>(false)

	// Redux state
	const { viewType } = useSelector(
		({
			investmentsOverview,
		}: {
			investmentsOverview: InvestmentsOverviewState
		}) => investmentsOverview,
	)

	return (
		<InvestmentsContext.Provider
			value={{
				search,
				setSearch,
			}}
		>
			<div data-testid="myinvestments">
				{/** Search and filters */}
				<div className="flex flex-col gap-4 lg:flex-row">
					<div className="flex gap-4 lg:order-2 lg:ml-auto">
						{/** Search bar */}
						<div
							className={classNames(
								"flex flex-1 items-center lg:order-3 lg:ml-auto",
								!showFilters && "hidden lg:flex",
								showFilters && "flex",
							)}
						>
							<div className="w-full">
								<SearchInput
									onChange={(evt) => {
										onSearchSendEvent(
											"investments",
											"on_search",
											{
												label: evt.currentTarget.value,
											},
										)
										setSearch(evt.currentTarget.value)
									}}
									label={t(
										"investments:investments.search.placeholder",
									)}
									className="w-full"
								/>
							</div>
						</div>

						{/** Show / hide Filter button */}
						<div className="ml-auto flex justify-end">
							<Button
								variant="transparent"
								size="small"
								className="ml-auto lg:hidden"
								onClick={() => setShowFilters(!showFilters)}
							>
								<FiFilter className="h-5 w-5" />
							</Button>
						</div>
					</div>

					{/** Filter buttons */}
					<div
						className={classNames(
							"flex-col space-y-4 lg:order-1 lg:flex lg:flex-row lg:space-x-4 lg:space-y-0",
							!showFilters && "hidden lg:flex",
							showFilters && "flex",
						)}
					>
						<div className="flex gap-4">
							{/** Sort order */}
							<ButtonSorting className="w-full flex-1 flex-grow" />

							{/** Filter on project type */}
							<ButtonFilterProjectType />

							{/** Filter on project status */}
							<ButtonFilterProjectState />
						</div>

						{/** Set page size */}
						<ButtonPageSize />

						{/** Swith table views */}
						<ButtonViewType />

						{/** Show hidden projects */}
						<CheckboxShowHidden />
					</div>
				</div>
			</div>
			<ErrorBoundaryWithErrorState errorBoundaryClassName="mt-5">
				{viewType === "grid" ? (
					<Suspense fallback={<InvestmentsGridLoadingState />}>
						<InvestmentsGrid />
					</Suspense>
				) : null}

				{viewType === "table" ? (
					<Suspense fallback={<InvestmentsTableLoadingState />}>
						<InvestmentsTable />
					</Suspense>
				) : null}
			</ErrorBoundaryWithErrorState>
		</InvestmentsContext.Provider>
	)
}

/**
 * ButtonSorting
 */
function ButtonSorting({ className }: { className?: string }) {
	const t = useTrans(["common", "investments"])

	// Redux state
	const dispatch = useDispatch()
	const { sortingMethod, sortingOrder } = useSelector(
		({
			investmentsOverview,
		}: {
			investmentsOverview: InvestmentsOverviewState
		}) => investmentsOverview,
	)

	return (
		<div className={className}>
			<Button
				size="small"
				variant="transparent"
				className="flex items-stretch"
				componentRight={
					<div
						className="flex items-center justify-center self-stretch bg-gray-100 px-4"
						onClick={() => {
							const next =
								sortingOrder === ProjectSortOrder.Asc
									? ProjectSortOrder.Desc
									: ProjectSortOrder.Asc
							sendEvent("investments", "on_sort_order", {
								label: next,
							})
							dispatch(setSortingOrder(next))
						}}
					>
						<FiArrowDown
							className={classNames(
								"h-5 w-5",
								sortingOrder === ProjectSortOrder.Asc &&
									"rotate-180 transform",
							)}
						/>
					</div>
				}
			>
				<div className="relative">
					<label htmlFor="sortingMethod">
						{t(
							`investments:investments.sort_method.${sortingMethod}`,
						)}
						<Select
							name="sortingMethod"
							className="h-100 absolute left-0 top-0 w-full cursor-pointer opacity-0"
							onChange={(evt) => {
								sendEvent("investments", "on_sort_method", {
									label: evt.currentTarget.value,
								})
								dispatch(
									setSortingMethod(
										evt.currentTarget
											.value as ProjectSortMethod,
									),
								)
							}}
							value={sortingMethod ?? undefined}
						>
							{Object.entries(ProjectSortMethod).map(
								([_key, value]) => (
									<option value={value} key={value}>
										{t(
											`investments:investments.sort_method.${value}`,
										)}
									</option>
								),
							)}
						</Select>
					</label>
				</div>
			</Button>
		</div>
	)
}

function ButtonFilterProjectType() {
	const t = useTrans(["common", "investments"])

	// Redux state
	const dispatch = useDispatch()
	const { filterType } = useSelector(
		({
			investmentsOverview,
		}: {
			investmentsOverview: InvestmentsOverviewState
		}) => investmentsOverview,
	)

	return (
		<div className="relative">
			<label htmlFor="projectType">
				<Button size="small" variant="transparent">
					{filterType.toUpperCase() === ALL_PROJECT_TYPES
						? t("investments:investments.filter.types")
						: t(`common:common.project.type.${filterType}`)}
					<FiChevronDown className={"ml-2"} />
					<Select
						name="projectType"
						className="h-100 absolute left-0 top-0 w-full cursor-pointer opacity-0"
						onChange={(evt) => {
							sendEvent("investments", "on_type_filter", {
								label: evt.currentTarget.value,
							})
							dispatch(
								setFilterType(
									evt.currentTarget.value.toUpperCase() as ProjectTypeEnumFiltered,
								),
							)
						}}
						value={filterType ?? undefined}
					>
						<option value={ALL_PROJECT_TYPES}>
							{t("investments:investments.filter.all")}
						</option>
						{Object.entries(ProjectTypeEnumFiltered).map(
							([_key, value]) => (
								<option value={value} key={value}>
									{t(`common:common.project.type.${value}`)}
								</option>
							),
						)}
					</Select>
				</Button>
			</label>
		</div>
	)
}

function ButtonFilterProjectState() {
	const t = useTrans(["common", "investments"])

	// Redux state
	const dispatch = useDispatch()
	const { filterState } = useSelector(
		({
			investmentsOverview,
		}: {
			investmentsOverview: InvestmentsOverviewState
		}) => investmentsOverview,
	)

	return (
		<div className="relative">
			<label htmlFor="projectStatus">
				<Button size="small" variant="transparent">
					{filterState.toUpperCase() === ALL_PROJECT_STATES
						? t("investments:investments.filter.status")
						: t(`common:common.project.status.${filterState}`)}
					<FiChevronDown className={"ml-2"} />
					<Select
						name="projectStatus"
						className="h-100 absolute left-0 top-0 w-full cursor-pointer opacity-0"
						onChange={(evt) => {
							sendEvent("investments", "on_status_filter", {
								label: evt.currentTarget.value,
							})
							dispatch(
								setFilterState(
									evt.currentTarget.value.toUpperCase() as ProjectStateEnumFiltered,
								),
							)
						}}
						value={filterState ?? undefined}
					>
						<option value={ALL_PROJECT_STATES}>
							{t("investments:investments.filter.all")}
						</option>
						{Object.entries(ProjectStateEnumFiltered).map(
							([_key, value]) => (
								<option value={value} key={value}>
									{t(`common:common.project.status.${value}`)}
								</option>
							),
						)}
					</Select>
				</Button>
			</label>
		</div>
	)
}

function ButtonPageSize() {
	const t = useTrans(["common", "investments"])

	// Redux state
	const dispatch = useDispatch()
	const { perPage: limit } = useSelector(
		({
			investmentsOverview,
		}: {
			investmentsOverview: InvestmentsOverviewState
		}) => investmentsOverview,
	)

	return (
		<div className="relative">
			<label htmlFor="pageSize w-full">
				<Button size="small" variant="transparent" className="w-full">
					{t("investments:investments.pagination.set_page_size", {
						count: limit,
					})}
					<FiChevronDown className={"ml-2"} />
					<Select
						name="pageSize"
						className="h-100 absolute left-0 top-0 w-full cursor-pointer opacity-0"
						onChange={(evt) =>
							dispatch(
								setPerPage(
									Number(
										evt.currentTarget.value,
									) as ProjectPageSize,
								),
							)
						}
						value={limit ?? undefined}
					>
						{projectPageSizes.map((item) => (
							<option value={item} key={item}>
								{item}
							</option>
						))}
					</Select>
				</Button>
			</label>
		</div>
	)
}

function ButtonViewType() {
	const t = useTrans("common")

	// Redux state
	const dispatch = useDispatch()
	const { viewType } = useSelector(
		({
			investmentsOverview,
		}: {
			investmentsOverview: InvestmentsOverviewState
		}) => investmentsOverview,
	)

	return (
		<div className="relative">
			<label htmlFor="pageSize w-full">
				<Button
					size="small"
					variant="transparent"
					className="w-full capitalize"
				>
					{t(`common:common.project.view.${viewType.toUpperCase()}`)}
					<FiChevronDown className={"ml-2"} />
					<Select
						name="pageSize"
						className="h-100 absolute left-0 top-0 w-full cursor-pointer opacity-0"
						onChange={(event) =>
							dispatch(
								setViewType(
									event.currentTarget
										.value as ProjectPageViewTypes,
								),
							)
						}
						value={viewType ?? undefined}
					>
						{projectPageViewTypes.map((item) => (
							<option value={item} key={item}>
								{t(
									`common:common.project.view.${item.toUpperCase()}`,
								)}
							</option>
						))}
					</Select>
				</Button>
			</label>
		</div>
	)
}

/**
 * CheckboxShowHidden
 * @returns
 */
function CheckboxShowHidden() {
	const t = useTrans("investments")

	// Redux state
	const dispatch = useDispatch()
	const { showHiddenProjects } = useSelector(
		({
			investmentsOverview,
		}: {
			investmentsOverview: InvestmentsOverviewState
		}) => investmentsOverview,
	)

	return (
		<div className="relative">
			<label
				htmlFor="showHiddenProjects"
				className="focus:ring-primary-500 flex h-full cursor-pointer items-center font-medium capitalize text-gray-500 transition hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2"
			>
				<Checkbox
					id="showHiddenProjects"
					name="showHiddenProjects"
					checked={showHiddenProjects === true}
					onChange={() =>
						dispatch(setShowHiddenProjects(!showHiddenProjects))
					}
					className="mr-2"
				/>
				{t("investments:investments.overview.show-hidden")}
			</label>
		</div>
	)
}
