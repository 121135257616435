import { Popover, Transition } from "@/lib/ui"
import {
	ChevronDownIcon,
	ChevronLeftIcon,
	ChevronRightIcon,
} from "@heroicons/react/outline"
import { DateTime } from "@/lib/dates"
import { useMemo } from "react"
import { useTrans } from "@/i18n"
import { getTransitionPopoverProps } from "@/lib/animations"
import { Button } from "../../../Button"
import { DatePicker } from "../../../form-controls/DatePicker"
import { useAreaGraphContext } from "./AreaGraph"
import { Tooltip } from "@/components/Tooltip"

export function AreaGraphActions() {
	const { maximumDate, minimumDate, date, setDate } = useAreaGraphContext()
	const t = { project: useTrans("project"), common: useTrans("common") }

	const currentDateTime = DateTime.fromJSDate(date)

	// is currentDateTime (current selected date) before now?
	const canGoNext = currentDateTime < maximumDate

	// is minimumDate before current date time
	const canGoPrev = currentDateTime > minimumDate

	return (
		<>
			{/* prev day button */}
			<Button
				onClick={() => {
					if (canGoPrev) {
						const nextDate = currentDateTime.minus({
							days: 1,
						})
						setDate(nextDate.toJSDate())
					}
				}}
				disabled={!canGoPrev}
				className="group relative rounded-r-none focus:z-20"
				variant="transparent"
			>
				<ChevronLeftIcon className="h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500" />
			</Button>

			{/* date picker */}
			<Tooltip
				content={
					"we do not store daily information for days more than a week old"
				}
			>
				<Popover.Group className="flex items-center divide-x divide-gray-200">
					<Popover className="relative z-10 inline-block text-right">
						<Popover.Button
							as="div"
							className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900"
						>
							<Button
								variant="transparent"
								size="small"
								className="relative rounded-none border-l-0 focus:z-20"
							>
								<span className="mr-2">
									{DateTime.fromJSDate(date).toFormat(
										t.project(
											"project.data.production_today.date_picker.title.date_format",
										),
									)}
								</span>

								<ChevronDownIcon
									className="h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
									aria-hidden="true"
								/>
							</Button>
						</Popover.Button>
						<Transition {...getTransitionPopoverProps()}>
							<Popover.Panel className="absolute left-0 mt-2 origin-top-right lg:left-auto lg:right-0">
								<div
									className="min-w-[300px]"
									data-testid="daterangepicker-from"
								>
									<DatePicker
										initialMonth={currentDateTime.month}
										initialYear={currentDateTime.year}
										selectedDate={currentDateTime}
										showChangeOfYear={false}
										showNavigation={false}
										months={useMemo(() => {
											if (
												maximumDate.month !==
												minimumDate.month
											) {
												return [
													minimumDate.month,
													maximumDate.month,
												]
											}
											return [maximumDate.month]
										}, [
											maximumDate.month,
											minimumDate.month,
										])}
										onSelectDate={(date) => {
											const newDate =
												DateTime.fromObject(date).endOf(
													"day",
												)
											setDate(newDate.toJSDate())
										}}
										isDateDisabled={(date) => {
											const dateTime =
												DateTime.fromObject({
													...date,
													day: date.day + 1,
												})

											// is this date within our range?
											if (
												dateTime < maximumDate &&
												dateTime >
													minimumDate.minus({
														days: 1,
													})
											) {
												return false
											}

											return true
										}}
									/>
								</div>
							</Popover.Panel>
						</Transition>
					</Popover>
				</Popover.Group>
			</Tooltip>
			{/* next day button */}
			<Button
				onClick={() => {
					if (canGoNext) {
						const nextDate = currentDateTime.plus({
							days: 1,
						})
						setDate(nextDate.toJSDate())
					}
				}}
				className="group relative rounded-l-none focus:z-20"
				variant="transparent"
				disabled={!canGoNext}
			>
				<ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500" />
			</Button>
		</>
	)
}
