import { useContext } from "react"
import { useTrans } from "@/i18n"
import { AuthLayoutContext } from "@/layouts/AuthLayout"
import { Routes } from "@/constants/routes"

// UI
import { classNames } from "@/lib/ui"
import { Link } from "@/components/Anchor"

/**
 * FinanceSubMenu
 */
export function FinanceSubMenu() {
	const t = useTrans()
	const { setMobileMenuOpen } = useContext(AuthLayoutContext)

	function closeMenu() {
		setMobileMenuOpen(false)
	}

	return (
		<nav className="space-y-1 pr-3">
			<Link
				className={({ isActive }) =>
					classNames(
						isActive && "active-navigation-item",
						"block truncate text-sm hover:text-gray-500",
					)
				}
				href={Routes.FinanceProjects}
				onClick={closeMenu}
			>
				{t("common.navigation.finance.dashboard.link")}
			</Link>
			<Link
				className={({ isActive }) =>
					classNames(
						isActive && "active-navigation-item",
						"block truncate text-sm hover:text-gray-500",
					)
				}
				href={Routes.FinanceInterestPayments}
				onClick={closeMenu}
			>
				{t("common.navigation.finance.interest_payments.link")}
			</Link>
		</nav>
	)
}
