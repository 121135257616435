import { FiAlertTriangle } from "react-icons/fi"
import { useTrans } from "@/i18n"
import { classNames } from "@/lib/ui"
import { ReactNode } from "react"

interface DisclaimerProps {
	message: string | ReactNode
	title?: string
	className?: any
}

/**
 * Disclaimer
 * @param param0
 * @returns
 */
export const Disclaimer = ({
	message = "",
	title,
	className = "",
}: DisclaimerProps) => {
	const t = useTrans("common")

	return (
		<div className={classNames(className, "rounded-md bg-yellow-50 p-4")}>
			<div className="flex">
				<div>
					<div className="flex rounded-lg bg-yellow-100 p-2">
						<FiAlertTriangle
							className="h-6 w-6 text-yellow-400"
							aria-hidden="true"
						/>
					</div>
				</div>
				<div className="ml-3">
					<h3 className="text-sm font-medium text-yellow-800">
						{title ?? t("common.table.mobile_warning.title")}
					</h3>
					<p className="mt-2 text-sm text-yellow-700">{message}</p>
				</div>
			</div>
		</div>
	)
}
