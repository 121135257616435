// React
import { useCallback, useContext, useMemo } from "react"

// Router
import { useNavigate, useParams, useLocation } from "@/lib/router"
import { Routes } from "@/constants/routes"

// Animations
import { motion } from "@/lib/animations"

// UI
import { classNames } from "@/lib/ui"
import { ProjectCard, ProjectCardPreloader } from "@/components/ProjectCard"
import { Heading } from "@/components/Typography"
import {
	PaginationAsButtons,
	PaginationAsButtonsLoadingState,
} from "@/components/PaginationAsButtons"
import { Button } from "@/components/Button"
import { Card } from "@/components/Card"

// Context
import { InvestmentsContext } from "../Investments"

// GraphQL
import { useInvestmentsGridViewQuery } from "@/api/graphql"

// State
import { useSelector, useDispatch } from "@/state/StateProvider"
import {
	setFilterState,
	setFilterType,
	setSortingMethod,
	setSortingOrder,
} from "@/state/features/investmentsOverview/slice"

// Translations
import { useTrans } from "@/i18n"

// Types
import {
	InvestmentsOverviewState,
	ProjectSortOrder,
	ALL_PROJECT_STATES,
	ALL_PROJECT_TYPES,
	ProjectSortMethod,
} from "@/state/features/investmentsOverview/types"

/**
 * InvestmentsGrid
 * @returns
 */
export function InvestmentsGrid() {
	// Context
	const { search, setSearch } = useContext(InvestmentsContext)

	// Redux state
	const dispatch = useDispatch()
	const {
		filterState,
		filterType,
		sortingMethod,
		sortingOrder,
		perPage: limit,
		hiddenIds,
		showHiddenProjects,
	} = useSelector(
		({
			investmentsOverview,
		}: {
			investmentsOverview: InvestmentsOverviewState
		}) => investmentsOverview,
	)

	// Router
	const navigate = useNavigate()
	const location = useLocation()
	const params = useParams()
	const currentPage = Number(params.page) || 1
	function setCurrentPage(page: number) {
		if (page >= 1) {
			navigate(
				Routes.InvestmentsProjectsDashboardPaginated.replace(
					":page",
					page.toString(),
				),
			)
		}
	}

	// Query
	const { data, isPreviousData } = useInvestmentsGridViewQuery({
		limit,
		offset: (currentPage - 1) * limit, // API Uses 0-based pagination
		name: search,
		ordering:
			sortingOrder === ProjectSortOrder.Asc
				? sortingMethod
				: `-${sortingMethod}`,
		state: filterState,
		type: filterType,
	})

	// Projects
	const projects = useMemo(
		() =>
			data?.me?.investment_projects?.results?.filter((project) => {
				// Only filter if we are not showing hidden projects
				if (showHiddenProjects === false) {
					const projectId = Number(project?.id)
					return hiddenIds.includes(projectId) === false // If project is not hidden
				}
				return true
			}) ?? [],
		[
			// Update when these values change
			data?.me?.investment_projects?.results,
			showHiddenProjects,
			hiddenIds,
			location,
		],
	)

	const onReset = useCallback(() => {
		setSearch("")
		dispatch(setFilterState(ALL_PROJECT_STATES))
		dispatch(setFilterType(ALL_PROJECT_TYPES))
		dispatch(setSortingOrder(ProjectSortOrder.Asc))
		dispatch(setSortingMethod(ProjectSortMethod.Name))
		setCurrentPage(1)
	}, [setSearch, setFilterState, setSortingMethod, setSortingOrder])

	return (
		<>
			<dl
				className={classNames(
					"mt-5 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3",
					isPreviousData && "opacity-50",
				)}
				data-testid="grid"
			>
				{projects.length === 0 && (
					<div className="sm:col-span-2 lg:col-span-1 lg:col-start-2">
						<NoResults onReset={onReset} />
					</div>
				)}
				{projects.map((project) => (
					<div key={`${project?.id}`}>
						<ProjectCard
							id={project?.id}
							analyticsContext="investments"
							className="h-full"
							key={project?.id}
							totalRepayment={
								project?.investor_shares_value_stats
									?.total_repaid_for_project
									? parseFloat(
											project.investor_shares_value_stats
												.total_repaid_for_project,
									  )
									: 0
							}
							hasEnergySupplierMessage={false}
							projectName={project?.name ?? ""}
							amountInvested={
								project?.investor_shares_value_stats
									?.total_investment_for_project
									? parseFloat(
											project.investor_shares_value_stats
												.total_investment_for_project,
									  )
									: 0
							}
							energySupplier={project?.installer?.name ?? ""}
							comingInterestPeriod={
								project?.current_interest_period?.end
									? new Date(
											project.current_interest_period.end,
									  )
									: null
							}
							slug={project?.id ?? ""}
							energySavings={
								project?.investor_production_stats
									?.investor_generated_power_in_kwh
									? parseFloat(
											project.investor_production_stats
												.investor_generated_power_in_kwh,
									  )
									: 0
							}
							status={project?.state}
							type={project?.type}
							image={project?.image_url ?? ""}
						/>
					</div>
				))}
			</dl>
			<div className="mt-6 flex justify-center md:mt-8">
				<PaginationAsButtons
					countPerPage={limit}
					totalCount={data?.me?.investment_projects?.totalCount ?? 0}
					itemType={"common.pagination.item_types.project"}
					currentPage={currentPage}
					currentItemsAmount={projects?.length ?? 0}
					onNextPage={() => setCurrentPage(currentPage + 1)}
					onPrevPage={() => setCurrentPage(currentPage - 1)}
					analyticsId="investments"
				/>
			</div>
		</>
	)
}

export function InvestmentsGridLoadingState() {
	return (
		<>
			<dl className="mt-5 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
				{Array(6)
					.fill(true)
					.map((_, index) => (
						<div key={index} data-testid="spinner">
							<ProjectCardPreloader />
						</div>
					))}
			</dl>
			<div className="mt-6 flex justify-center md:mt-8 md:gap-x-4">
				<PaginationAsButtonsLoadingState />
			</div>
		</>
	)
}

const NoResults = ({ onReset }: { onReset: () => void }) => {
	const t = useTrans()

	return (
		<motion.div
			data-testid="investment-item-noresults"
			layoutId="no-results"
			exit={{
				scale: 0.95,
				opacity: 0,
			}}
			animate={{
				scale: 1,
				opacity: 1,
			}}
			initial={{
				scale: 0.95,
				opacity: 0,
			}}
		>
			<Card>
				<div className="space-y-4 text-center">
					<Heading as="h2" styleAs="h5">
						{t("investments:investments.no_results.title")}
					</Heading>
					<p className="text-gray-500">
						{t("investments:investments.no_results.copy")}
					</p>
					<Button onClick={onReset}>
						{t("investments:investments.no_results.button")}
					</Button>
				</div>
			</Card>
		</motion.div>
	)
}
