import { Fragment, MouseEventHandler, useMemo, useState } from "react"
import { Helmet } from "@/lib/seo"

// Queries
import {
	InvestmentStateEnum,
	InvestmentType,
	useInvestmentsQuery,
} from "@/api/graphql"

// Translations
import { useTrans } from "@/i18n"
import { useLang } from "@/context/lang"

// Animations
import { AnimatePresence, motion } from "@/lib/animations"

// DateTime
import { DateTime } from "@/lib/dates"
import { dateFormat } from "@/constants/constants"

// Icons
import { FiChevronDown } from "@/lib/icons"

// Tables
import {
	flexRender,
	getCoreRowModel,
	getExpandedRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
	CellContext,
	createColumnHelper,
} from "@/lib/table"
import {
	Table,
	TableBody,
	TableDataCell,
	TableHead,
	TableRowCell,
	TableHeading,
} from "@/components/table-controls/TableItems"

// UI
import { PaginationAsButtons } from "@/components/PaginationAsButtons"
import { classNames } from "@/lib/ui"
import { CardBody, CardWrapper, Card } from "@/components/Card"
import { Heading } from "@/components/Typography"
import { InvestmentOpportunityBanner } from "@/components/banners/InvestmentOpportunityBanner"

/**
 * ProvisionalInvestments
 * @returns
 */
export const ProvisionalInvestments = () => {
	// State
	const [currentPage, setCurrentPage] = useState(0)
	const limit = 5

	const t = useTrans(["project", "common"])
	const { data } = useInvestmentsQuery({
		limit,
		offset: currentPage * limit,
	})
	const { formatCurrency } = useLang()

	// Memo
	const investments = useMemo(() => {
		return data?.me?.provisional_investments?.results
	}, [data?.me?.provisional_investments?.results])

	// Tables
	const columnHelper = createColumnHelper<InvestmentType>()
	const columns = useMemo(
		() => [
			columnHelper.accessor((data) => data, {
				id: "expander",
				header: () => <TableHeading className="w-10" />,
				cell: (info: CellContext<InvestmentType, InvestmentType>) => {
					// Only show when we have more info
					if (
						info.getValue()?.refund_reason ||
						info.getValue()?.crowdfunding_campaign?.project?.project
							?.issuance_date_in_information_memorandum ||
						info.getValue()?.agreement_file
					) {
						// Toggle expanded when clicked
						const handleClick: MouseEventHandler<
							HTMLTableCellElement
						> = (e) => {
							e.preventDefault()
							info.row.toggleExpanded()
						}

						return (
							<TableDataCell
								className={classNames(
									info.row.getIsExpanded()
										? "hover:bg-yellow-300"
										: "hover:bg-gray-200",
									"w-10 cursor-pointer text-gray-500 hover:text-gray-700",
								)}
								onClick={handleClick}
							>
								<FiChevronDown
									className={classNames(
										"mx-auto block transform transition",
										info.row.getIsExpanded() &&
											"rotate-180",
									)}
								/>
							</TableDataCell>
						)
					}

					// Default empty
					return <TableDataCell />
				},
			}),
			columnHelper.accessor(
				(investments: InvestmentType) =>
					investments.crowdfunding_campaign?.project?.title,
				{
					id: "project",
					header: () => <TableHeading>Project</TableHeading>,
					cell: (info: CellContext<InvestmentType, string>) => (
						<TableDataCell>{info.getValue()}</TableDataCell>
					),
				},
			),
			columnHelper.accessor("amount_total", {
				header: () => (
					<TableHeading>
						{t(
							"project:project.provisional_investments.table.heading.total",
						)}
					</TableHeading>
				),
				cell: (info: CellContext<InvestmentType, number>) => (
					<TableDataCell>
						{formatCurrency(info.getValue() || 0)}
					</TableDataCell>
				),
			}),
			columnHelper.accessor("created_at", {
				header: () => (
					<TableHeading>
						{t(
							"project:project.provisional_investments.table.heading.date",
						)}
					</TableHeading>
				),
				cell: (info: CellContext<InvestmentType, string>) => (
					<TableDataCell>
						{DateTime.fromISO(info.getValue()).toFormat(dateFormat)}
					</TableDataCell>
				),
			}),
			columnHelper.accessor("state", {
				header: () => (
					<TableHeading>
						{t(
							"project:project.provisional_investments.table.heading.state",
						)}
					</TableHeading>
				),
				cell: (
					info: CellContext<InvestmentType, InvestmentStateEnum>,
				) => (
					<TableDataCell>
						{t(
							`common:common.investment.status.${info.getValue()}`,
						)}
					</TableDataCell>
				),
			}),
		],
		[],
	)

	// Table
	const table = useReactTable({
		columns,
		data: investments as InvestmentType[],
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getExpandedRowModel: getExpandedRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		state: {
			pagination: {
				pageIndex: 0,
				pageSize: limit,
			},
		},
	})

	return (
		<>
			<Helmet>
				<title>
					{t("project:project.provisional_investments.title")}
				</title>
			</Helmet>
			<InvestmentOpportunityBanner />
			<Card className="mb-8 mt-5">
				<Heading as="h2" styleAs="h5" className="mb-3 sm:truncate">
					{t("project:project.provisional_investments.title")}
				</Heading>
				<p className="mb-2 text-sm text-gray-500 sm:w-3/4 lg:w-1/2">
					{t("project:project.provisional_investments.copy")}
				</p>

				{/** Bullet points */}
				<ul className="ml-0 list-disc whitespace-pre-wrap text-sm text-gray-500">
					<li>
						{t(
							"project:project.provisional_investments.copy.point.payment",
						)}
					</li>
					<li>
						{t(
							"project:project.provisional_investments.copy.point.duration",
						)}
					</li>
					<li>
						{t(
							"project:project.provisional_investments.copy.point.issuance",
						)}
					</li>
				</ul>
			</Card>

			{/** When we have provisional investments */}
			{data?.me?.provisional_investments?.totalCount !== 0 && (
				<CardWrapper>
					<CardBody>
						<Table
							className="min-w-[64rem] lg:min-w-0"
							data-testid="tablebody"
						>
							<TableHead>
								{table.getHeaderGroups().map((headerGroup) => (
									<TableRowCell key={headerGroup.id}>
										{headerGroup.headers.map((header) => {
											return (
												<Fragment key={header.id}>
													{flexRender(
														header.column.columnDef
															.header,
														header.getContext(),
													)}
												</Fragment>
											)
										})}
									</TableRowCell>
								))}
							</TableHead>
							<TableBody
								data-testid="tablebody-overview"
								data-pageindex={
									table.getState().pagination.pageIndex
								}
							>
								{table.getRowModel().rows.map((row) => {
									const isOdd = row.index % 2 === 0
									let className = ""
									if (row.getIsExpanded()) {
										className =
											"bg-yellow-100 hover:bg-yellow-200 text-gray-900"
									}

									return (
										<Fragment key={row.id}>
											<TableRowCell
												onClick={row.getToggleExpandedHandler()}
												isOdd={isOdd}
												className={classNames(
													className,
												)}
											>
												{row
													.getAllCells()
													.map((cell) => {
														return (
															<Fragment
																key={cell.id}
															>
																{flexRender(
																	cell.column
																		.columnDef
																		.cell,
																	cell.getContext(),
																)}
															</Fragment>
														)
													})}
											</TableRowCell>
											<TableRowCell
												isOdd
												withHover={false}
												className="!p-0"
											>
												<TableDataCell
													colSpan={
														row._getAllVisibleCells()
															.length
													}
													className="!p-0"
												>
													<AnimatePresence
														// we use initial false, as if a row is expanded
														// and you navigate from one page to the next, and this next
														// page has a row that is expanded, it will animate in and we do not want that
														initial={false}
													>
														{row.getIsExpanded() ? (
															<motion.div
																initial={{
																	height: 0,
																}}
																animate={{
																	height: "auto",
																}}
																exit={{
																	height: 0,
																}}
																transition={{
																	ease: "easeOut",
																}}
																className="flex overflow-hidden"
															>
																<InvestmentsTableRowExpanded
																	investment={
																		row.original
																	}
																/>
															</motion.div>
														) : null}
													</AnimatePresence>
												</TableDataCell>
											</TableRowCell>
										</Fragment>
									)
								})}
								{/* Pads the last entries in the table so the table doesn't collapse in the UI */}
								{table.getRowModel().rows.length <
									table.getState().pagination.pageSize &&
								table.getState().pagination.pageIndex !== 0 ? (
									<>
										{Array(
											Math.max(
												table.getState().pagination
													.pageSize -
													table.getRowModel().rows
														.length,
												1,
											),
										)
											.fill(true)
											.map((_, index) => (
												<TableRowCell
													key={index}
													withHover={false}
													isOdd={index % 2 === 0}
												>
													<TableDataCell
														colSpan={columns.length}
													>
														<span className="dummy-text" />
													</TableDataCell>
												</TableRowCell>
											))}
									</>
								) : null}
							</TableBody>
						</Table>

						{investments?.length !== 0 && (
							<div className="mt-6 flex w-full justify-center px-4">
								<PaginationAsButtons
									countPerPage={limit}
									totalCount={
										data?.me?.provisional_investments
											?.totalCount ?? 0
									}
									itemType={
										"common.pagination.item_types.investment"
									}
									currentPage={currentPage + 1}
									currentItemsAmount={
										data?.me?.provisional_investments
											?.results?.length ?? 0
									}
									onNextPage={() =>
										setCurrentPage(
											(currentPage) => currentPage + 1,
										)
									}
									onPrevPage={() =>
										setCurrentPage((currentPage) =>
											Math.max(currentPage - 1, 0),
										)
									}
									analyticsId="investments"
								/>
							</div>
						)}
					</CardBody>
				</CardWrapper>
			)}
		</>
	)
}

/**
 * InvestmentsTableRowExpanded
 * @param param0
 * @returns
 */
function InvestmentsTableRowExpanded({ investment }: any) {
	const t = useTrans("project")

	return (
		<div className="p-5">
			{/** Comment */}
			{investment?.comment && (
				<div>
					<b>
						{t(
							"project:project.provisional_investments.table.info.comment",
						)}
						:{" "}
					</b>
					{investment.comment}
				</div>
			)}

			{/** Refund */}
			{investment?.refund_reason !== null && (
				<div>
					<b>Refund reason: </b>
					{investment?.refund_reason}
				</div>
			)}

			{/** Agreement file */}
			{investment.agreement_file && (
				<div>
					<b>
						{t(
							"project:project.provisional_investments.table.info.agreement_file",
						)}
						:{" "}
					</b>
					<a
						target="_blank"
						rel="noreferrer"
						href={investment.agreement_file}
						className={classNames(
							"text-gray-500 hover:text-gray-700",
							"text-sm font-medium",
						)}
					>
						{t(
							"project:project.provisional_investments.table.info.agreement_file.action.download",
						)}
					</a>
				</div>
			)}

			{/** Share Issuance date */}
			{investment.crowdfunding_campaign?.project?.project
				?.issuance_date_in_information_memorandum && (
				<div>
					<b>The project will formaly issue the bonds: </b>
					{DateTime.fromISO(
						investment.crowdfunding_campaign?.project?.project
							?.issuance_date_in_information_memorandum,
					).toFormat(dateFormat)}
				</div>
			)}
		</div>
	)
}
