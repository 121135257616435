import { useContext } from "react"

// Navigation
import { Routes } from "@/constants/routes"
import { useLocation } from "@/lib/router"

// Context
import { AuthLayoutContext } from "@/layouts/AuthLayout"

// UI
import { classNames } from "@/lib/ui"
import { Link } from "@/components/Anchor"

// Translation
import { useTrans } from "@/i18n"

/**
 * StatsSubMenu
 * @returns
 */
export function StatsSubMenu() {
	// Router
	const location = useLocation()

	const { setMobileMenuOpen } = useContext(AuthLayoutContext)
	const t = useTrans(["common", "project"])

	function closeMenu() {
		setMobileMenuOpen(false)
	}

	return (
		<nav className="space-y-1 pr-3">
			<Link
				className={classNames(
					location.pathname === Routes.StatisticsPlatform &&
						"active-navigation-item",
					"block truncate text-sm hover:text-gray-500",
				)}
				href={Routes.StatisticsPlatform}
				onClick={closeMenu}
			>
				{t("common:common.navigation.statistics.platform.link")}
			</Link>
			<Link
				className={classNames(
					location.pathname === Routes.StatisticsPlatformEndex &&
						"active-navigation-item",
					"block truncate text-sm hover:text-gray-500",
				)}
				href={Routes.StatisticsPlatformEndex}
				onClick={closeMenu}
			>
				{t("common:common.navigation.statistics.platform.endex.link")}
			</Link>
		</nav>
	)
}
