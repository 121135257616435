// React
import { useMemo, useState, Fragment, ReactNode } from "react"

// Translations
import { useTrans } from "@/i18n"

// Dates
import { DateTime } from "@/lib/dates"

// GraphQL
import {
	useProjectMonthlyProductionTableQuery,
	ProjectType,
} from "@/api/graphql"

// CSV
import { exportToCsv, getCsvFileName } from "@/lib/csv"

// Tables
import {
	Table,
	TableBody,
	TableDataCell,
	TableHead,
	TableHeading,
	TableRowCell,
} from "@/components/table-controls/TableItems"
import {
	useReactTable,
	flexRender,
	createColumnHelper,
	getCoreRowModel,
	getPaginationRowModel,
	CellContext,
} from "@/lib/table"

// UI
import { classNames } from "@/lib/ui"
import { PillInverted } from "@/components/Pill"
import { Heading } from "@/components/Typography"
import { DownloadSelector } from "@/components/DownloadSelector"
import { DownloadableFileType } from "@/components/DownloadSelector/types"

/**
 * ProjectMonthlyProductionTable
 * @returns
 */
interface ProjectMonthlyProductionTableProps {
	startTime: DateTime
	endTime: DateTime
}
export function ProjectMonthlyProductionTable({
	startTime,
	endTime,
}: ProjectMonthlyProductionTableProps) {
	const t = useTrans(["investments", "common"])

	// GraphQL
	const { data } = useProjectMonthlyProductionTableQuery({
		startTime: startTime?.toJSDate(),
		endTime: endTime?.toJSDate(),
	})

	// Table
	const [rows, setRows] = useState<ProjectType[]>([])

	useMemo(() => {
		const results = data?.me?.investment_projects?.results as
			| ProjectType[]
			| undefined
		setRows(results ?? [])
	}, [data?.me?.investment_projects])

	// Tables
	const columnHelper = createColumnHelper<ProjectType>()
	const columns = [
		columnHelper.accessor("name", {
			id: "project.name",
			header: () => (
				<TableHeading>
					{t(
						"investments:investments.monthly_overview.block.project-production-table.headers.project_name",
					)}
				</TableHeading>
			),
			cell: (info: CellContext<ProjectType, string>) => {
				return (
					<TableDataCell className="font-medium">
						{info.getValue()}
					</TableDataCell>
				)
			},
		}),
		columnHelper.accessor(
			(data: ProjectType) =>
				data.investor_shares_value_stats?.total_shares,
			{
				id: "solar_shares",
				header: () => (
					<TableHeading>
						{t(
							"investments:investments.monthly_overview.block.project-production-table.headers.solar_shares",
						)}
					</TableHeading>
				),
				cell: (info: CellContext<ProjectType, number>) => {
					return <TableDataCell>{info.getValue()}</TableDataCell>
				},
			},
		),
		columnHelper.accessor(
			(data: ProjectType) =>
				data.investor_production_stats
					?.investor_generated_power_in_kwh !== "0" ? ( // Decimals are returned from API as strings
					<>
						{`${parseFloat(
							data.investor_production_stats
								?.investor_generated_power_in_kwh || "0",
						).toFixed(1)} kWh`}
					</>
				) : (
					<>
						{data?.state ? (
							<PillInverted variant={data?.state}>
								{t(
									`common:common.project.status.${data?.state}`,
								)}
							</PillInverted>
						) : null}
					</>
				),
			{
				id: "production_kwh",
				header: () => (
					<TableHeading>
						{t(
							"investments:investments.monthly_overview.block.project-production-table.headers.production_kwh",
						)}
					</TableHeading>
				),
				cell: (info: CellContext<ProjectType, ReactNode>) => {
					return <TableDataCell>{info.getValue()}</TableDataCell>
				},
			},
		),
		columnHelper.accessor(
			(data: ProjectType) =>
				data.investor_production_stats?.total_production_percentage !==
				"0.00" // Decimals are returned from API as strings
					? `${data.investor_production_stats?.total_production_percentage}%`
					: null,
			{
				id: "production_percentage",
				header: () => (
					<TableHeading>
						{t(
							"investments:investments.monthly_overview.block.project-production-table.headers.production_percentage",
						)}
					</TableHeading>
				),
				cell: (info: CellContext<ProjectType, string>) => {
					return <TableDataCell>{info.getValue()}</TableDataCell>
				},
			},
		),
		columnHelper.accessor(
			(data: ProjectType) =>
				data.investor_production_stats?.investor_co2_saving !== "0.0000" // Decimals are returned from API as strings
					? `${(
							parseFloat(
								data.investor_production_stats
									?.investor_co2_saving || "0",
							) / 1000
					  ).toFixed(1)} kg`
					: null,
			{
				id: "production_co2",
				header: () => (
					<TableHeading>
						{t(
							"investments:investments.monthly_overview.block.project-production-table.headers.production_co2",
						)}
					</TableHeading>
				),
				cell: (info: CellContext<ProjectType, string>) => {
					return <TableDataCell>{info.getValue()}</TableDataCell>
				},
			},
		),
	]
	const table = useReactTable({
		columns,
		data: rows as ProjectType[],
		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
	})

	/**
	 * handleDownload
	 * @param fileType
	 */
	const handleDownload = (fileType: DownloadableFileType) => {
		if (fileType === DownloadableFileType.Csv) {
			exportToCsv({
				filename: getCsvFileName(
					t(
						"investments:investments.monthly_overview.block.project-production-table.title",
						{
							date: startTime.toLocaleString({
								month: "long",
								year: "numeric",
							}),
						},
					),
				),
				data: rows?.map((project) => ({
					[t(
						"investments:investments.monthly_overview.block.project-production-table.headers.project_name",
					)]: project.name,
					[t(
						"investments:investments.monthly_overview.block.project-production-table.headers.solar_shares",
					)]: project.investor_shares_value_stats?.total_shares || "",
					[t(
						"investments:investments.monthly_overview.block.project-production-table.headers.production_kwh",
					)]: project.investor_production_stats
						?.investor_generated_power_in_kwh
						? parseFloat(
								project.investor_production_stats
									.investor_generated_power_in_kwh,
						  ).toFixed(1)
						: "",
					[t(
						"investments:investments.monthly_overview.block.project-production-table.headers.production_percentage",
					)]:
						project.investor_production_stats
							?.total_production_percentage !== "0.00"
							? project.investor_production_stats
									?.total_production_percentage
							: "",
					[t(
						"investments:investments.monthly_overview.block.project-production-table.headers.production_co2",
					)]:
						project.investor_production_stats
							?.investor_co2_saving !== "0.0000"
							? (
									parseFloat(
										project.investor_production_stats
											?.investor_co2_saving || "0",
									) / 1000
							  ).toFixed(1)
							: "",
				})),
				headers: [
					// Create CSV headers using the same translations as the table
					t(
						"investments:investments.monthly_overview.block.project-production-table.headers.project_name",
					),
					t(
						"investments:investments.monthly_overview.block.project-production-table.headers.solar_shares",
					),
					t(
						"investments:investments.monthly_overview.block.project-production-table.headers.production_kwh",
					),
					t(
						"investments:investments.monthly_overview.block.project-production-table.headers.production_percentage",
					),
					t(
						"investments:investments.monthly_overview.block.project-production-table.headers.production_co2",
					),
				],
			})
		}
	}

	return (
		<>
			<div className="flex items-center justify-between">
				<Heading as="h2" styleAs="h5" className="mb-4">
					{t(
						"investments:investments.monthly_overview.block.project-production-table.title",
						{
							date: startTime.toLocaleString({
								month: "long",
								year: "numeric",
							}),
						},
					)}
				</Heading>
				<div className="ml-auto">
					<DownloadSelector
						options={[
							{
								name: t("common:button.download.csv"),
								value: DownloadableFileType.Csv,
							},
						]}
						onDownload={handleDownload}
					/>
				</div>
			</div>

			<Table
				className="mb-5 min-w-[64rem] lg:min-w-0"
				data-testid="tablebody"
			>
				<TableHead>
					{table.getHeaderGroups().map((headerGroup) => (
						<TableRowCell key={headerGroup.id}>
							{headerGroup.headers.map((header) => {
								return (
									<Fragment key={header.id}>
										{flexRender(
											header.column.columnDef.header,
											header.getContext(),
										)}
									</Fragment>
								)
							})}
						</TableRowCell>
					))}
				</TableHead>
				<TableBody
					data-testid="tablebody-overview"
					data-pageindex={table.getState().pagination.pageIndex}
				>
					{table.getRowModel().rows.map((row) => {
						const isOdd = row.index % 2 === 0
						let className = ""

						return (
							<Fragment key={row.id}>
								<TableRowCell
									isOdd={isOdd}
									className={classNames(className)}
								>
									{row.getAllCells().map((cell) => {
										return (
											<Fragment key={cell.id}>
												{flexRender(
													cell.column.columnDef.cell,
													cell.getContext(),
												)}
											</Fragment>
										)
									})}
								</TableRowCell>
							</Fragment>
						)
					})}
					{/* Pads the last entries in the table so the table doesn't collapse in the UI */}
					{table.getRowModel().rows.length <
						table.getState().pagination.pageSize &&
					table.getState().pagination.pageIndex !== 0 ? (
						<>
							{Array(
								Math.max(
									table.getState().pagination.pageSize -
										table.getRowModel().rows.length,
									1,
								),
							)
								.fill(true)
								.map((_, index) => (
									<TableRowCell
										key={index}
										withHover={false}
										isOdd={index % 2 === 0}
									>
										<TableDataCell colSpan={columns.length}>
											<span className="dummy-text" />
										</TableDataCell>
									</TableRowCell>
								))}
						</>
					) : null}
				</TableBody>
			</Table>
		</>
	)
}
