// React
import {
	ReactNode,
	useState,
	useMemo,
	createContext,
	useCallback,
	useEffect,
} from "react"

// Create redirect context
export const RedirectContext = createContext<{
	redirect: (url: string) => void
}>({
	redirect: () => {},
})

/**
 * RedirectProvider
 * @param param0
 * @returns
 */
export function RedirectProvider({ children }: { children: ReactNode }) {
	const [redirectUrl, setRedirectUrl] = useState<string | null>(null)

	// When redirectUrl is set, redirect to it
	useEffect(() => {
		if (redirectUrl && typeof window !== "undefined") {
			window.location.href = redirectUrl
		}
	}, [redirectUrl])

	// Call this function to redirect
	const redirect = useCallback((url: string) => {
		setRedirectUrl(url)
	}, [])

	const value = useMemo(
		() => ({
			redirect,
		}),
		[redirect],
	)

	return (
		<RedirectContext.Provider value={value}>
			{children}
		</RedirectContext.Provider>
	)
}
