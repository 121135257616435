import { classNames } from "@/lib/ui"
import { ReactNode, useEffect } from "react"

interface ConfirmDialogProps {
	children: ReactNode
	className?: string
}

export const DialogWrapper = ({ children, className }: ConfirmDialogProps) => {
	useEffect(() => {
		document.body.classList.add("overflow-hidden")

		return () => document.body.classList.remove("overflow-hidden")
	}, [])

	return (
		<>
			<div
				className={classNames(
					className,
					"h-modal fixed left-0 right-0 top-4 z-[150] flex h-full w-full items-center justify-center overflow-y-auto overflow-x-hidden backdrop-blur md:inset-0",
				)}
			>
				<div className="relative h-full w-full max-w-2xl px-4 md:h-auto">
					<div className="relative rounded-lg bg-white shadow dark:bg-gray-700">
						{children}
					</div>
				</div>
			</div>
		</>
	)
}
