import { Trans } from "@/lib/i18n"
import { Pill } from "./Pill"

interface StatusMessageProps {
	content: string
}

export const StatusMessage = ({ content }: StatusMessageProps) => {
	return (
		<Trans
			defaults={content}
			components={{
				bold: <span className="font-medium text-gray-900" />,
				heading: <span className="font-medium text-gray-900" />,
				sub: <p className="mb-2" />,
				p: <p className="text-gray-700" />,
				pill: <Pill className="mx-0.5 md:mt-2 lg:mt-0" />,
			}}
		/>
	)
}
