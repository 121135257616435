import { ReactNode } from "react"

// Router
import { Link } from "@/lib/router"
import { Routes } from "@/constants/routes"

// Utils
import { emptyValue, truncateFloatNumber } from "@/utils/helpers"

// Hooks
import { useCurrentProject } from "../hooks"
import { useCurrentProjectId } from "../hooks/useCurrentProjectId"

// Icons
import { FiArrowRight, FiTrendingUp } from "@/lib/icons"

// UI
import { classNames } from "@/lib/ui"
import { Card } from "@/components/Card"
import { PillInverted } from "@/components/Pill"
import { Heading } from "@/components/Typography"
import { Tooltip } from "@/components/tooltips/Tooltip"

// GraphQL
import {
	ProjectTypeEnum,
	useInterestPaymentsQuery,
	useProjectRevenueQuery,
	PayOutState,
} from "@/api/graphql"

// Translations
import { useTrans } from "@/i18n"
import { useLang } from "@/context/lang"

// Dates
import { DateTime } from "@/lib/dates"
import { dateFormat } from "@/constants/constants"

/**
 * ProjectDashboard
 * @returns
 */
export const ProjectDashboard = () => {
	const t = useTrans(["common", "project"])
	const id = useCurrentProjectId()
	const { data } = useCurrentProject()
	const { formatCurrency } = useLang()

	// TODO: Bug?
	const { data: projectRevenueData } = useProjectRevenueQuery({
		id: String(id),
	})

	const { data: { me = {} } = { me: {} } } = useInterestPaymentsQuery({
		id: String(id),
		project_id: String(id),
		ordering: "-interestperiod__end",
	})

	// Select the last payment based on date (first in array) where payment entries are done
	const lastPayment = me?.interest_payments
		?.filter((payment) =>
			payment.payment_entries?.some(
				(entry) =>
					entry?.state === null || // Payouts before the Mangopay migration (2024) have state 'null'. We always include them.
					entry?.state === PayOutState.PayoutCompleted ||
					entry?.state === PayOutState.TransferCompleted,
			),
		)
		.at(0)
	const interestPeriodEndDate = lastPayment?.interestperiod?.end

	const lastPaymentEndDate = interestPeriodEndDate
		? DateTime.fromISO(interestPeriodEndDate)
		: null

	const { cost, amortization, repayment } =
		lastPayment?.payment_entries?.at(0) || {}

	const lastPaymentSum =
		Number(cost) + Number(amortization) + Number(repayment)

	// Sum total of all payments that have status DONE
	const paymentSum = me?.interest_payments
		?.filter((payment) =>
			payment.payment_entries?.some(
				(entry) =>
					entry?.state === null || // Payouts before the Mangopay migration (2024) have state 'null'. We always include them.
					entry.state === PayOutState.TransferCompleted ||
					entry.state === PayOutState.PayoutCompleted,
			),
		)
		?.reduce((acc, curr) => {
			const payment = curr.payment_entries?.at(0)

			return (
				acc +
				(Number(payment?.cost) +
					Number(payment?.amortization) +
					Number(payment?.repayment))
			)
		}, 0)

	const interestRate =
		projectRevenueData?.project?.investor_shares_value_stats
			?.internal_rate_of_returns?.internal_rate_of_return
	const interestRateFixed = truncateFloatNumber(Number(interestRate))

	const expectedInterestRate =
		projectRevenueData?.project?.investor_shares_value_stats
			?.internal_rate_of_returns?.expected_internal_rate_of_return
	const expectedInterestRateFixed = truncateFloatNumber(
		Number(expectedInterestRate),
	)

	// Calculate initial investment
	const initalInvestment = Number(
		(data?.project?.investor_shares_value_stats?.shares?.[0]
			?.nominal_value || 0) *
			(data?.project?.investor_shares_value_stats?.total_shares || 0),
	)

	return (
		<div className="grid grid-cols-1 gap-8 md:grid-cols-4">
			<Card className="md:col-span-4 xl:col-span-3">
				<div className="mb-4 md:mb-2 md:flex md:items-center md:justify-start">
					<Heading as="h2" styleAs="h5" className="mb-2 md:mb-0">
						{data?.project?.name}
					</Heading>

					{/** Project Type */}
					{data?.project?.type && (
						<div className="bg-primary-500 inline-block rounded-md px-4 py-1 font-bold text-black opacity-90 shadow-2xl md:ml-4 md:mt-2 lg:mt-0">
							{t(
								`common:common.project.type.${data?.project?.type}`,
							)}
						</div>
					)}

					{/** Project State */}
					{data?.project?.state && (
						<PillInverted
							variant={data.project.state}
							className="ml-4 inline-block md:mt-2 lg:mt-0"
						>
							{t(
								`common:common.project.status.${data.project.state}`,
							)}
						</PillInverted>
					)}
				</div>
				{!!me?.investment_campaigns?.at(0)?.description?.length && (
					<p className="hidden overflow-ellipsis text-sm text-gray-700 md:block xl:w-1/2">
						{me.investment_campaigns.at(0)?.description}
					</p>
				)}
				<div className="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2 md:mt-6 xl:grid-cols-4">
					{/** Share value */}
					<div
						className="col-span-1 rounded-md bg-gray-50 px-4 py-3"
						data-testid="project-sharevalue"
					>
						<dt className="text-sm font-medium text-gray-500">
							{projectRevenueData?.project?.type ===
							ProjectTypeEnum.Portfolio
								? t(
										"project:project.dashboard.hero.bond_value.title",
								  )
								: t(
										"project:project.dashboard.hero.share_value.title",
								  )}
						</dt>
						<dd className="mt-1 text-sm text-gray-900">
							{formatCurrency(
								Number(
									data?.project?.investor_shares_value_stats
										?.total_investment_for_project,
								),
							)}
						</dd>
					</div>

					{/** Total shares */}
					<div
						className="col-span-1 rounded-md bg-gray-50 px-4 py-3"
						data-testid="project-solarshares"
					>
						<dt className="text-sm font-medium text-gray-500">
							{projectRevenueData?.project?.type ===
							ProjectTypeEnum.Portfolio
								? t(
										"project:project.dashboard.hero.solar_bonds.title",
								  )
								: t(
										"project:project.dashboard.hero.solar_shares.title",
								  )}
						</dt>
						<dd className="mt-1 text-sm text-gray-900">
							{
								data?.project?.investor_shares_value_stats
									?.total_shares
							}
						</dd>
					</div>

					{/** Interest Rate */}
					<div
						className="col-span-1 rounded-md bg-gray-50 px-4 py-3"
						data-testid="project-interestrate"
					>
						<dt className="text-sm font-medium text-gray-500">
							{t(
								"project:project.dashboard.hero.interest_rate.title",
							)}
							<Tooltip
								text={t(
									`project:project.dashboard.hero.interest_rate.description${
										data?.project?.type ===
										ProjectTypeEnum.Portfolio
											? "_pro_project"
											: ""
									}`,
									{
										interestRate: interestRateFixed,
									},
								)}
							/>
						</dt>
						<dd className="mt-1 flex text-sm text-gray-900">
							{interestRateFixed}% ({expectedInterestRateFixed}%){" "}
							<FiTrendingUp className="ml-2 h-5 w-5 flex-shrink-0 transform self-center text-green-500" />
						</dd>
					</div>

					{/** Initial investment */}
					<div
						className="col-span-1 rounded-md bg-gray-50 px-4 py-3"
						data-testid="project-sharevalue"
					>
						<dt className="text-sm font-medium text-gray-500">
							{t(
								"project:project.dashboard.hero.initial_investment.title",
							)}
						</dt>
						<dd className="mt-1 text-sm text-gray-900">
							{formatCurrency(Number(initalInvestment))}
						</dd>
					</div>
				</div>
			</Card>

			{/** Project photo */}
			<Card
				className={`${classNames(
					!data?.project?.image_url && "invisible",
					"items-center",
					"justify-center",
					"xl:flex",
					"md:col-span-2",
					"xl:col-auto",
				)}`}
			>
				{data?.project?.image_url ? (
					<img
						src={data?.project?.image_url}
						alt={data?.project?.name || ""}
					/>
				) : (
					data?.project?.supplier?.name
				)}
			</Card>

			{/** Energy supplier */}
			<Card
				className={`${classNames(
					!data?.project?.supplier && "invisible",
					"items-center",
					"justify-center",
					"xl:flex",
					"md:col-span-2",
					"xl:col-auto",
				)}`}
			>
				{data?.project?.supplier?.logo ? (
					<img
						src={data?.project?.supplier?.logo}
						alt={data?.project?.supplier?.name}
					/>
				) : (
					data?.project?.supplier?.name
				)}
			</Card>

			<div className="grid grid-cols-1 gap-8 md:col-span-4 lg:grid-cols-2 xl:col-span-3">
				<ItemWithLink
					to={`${Routes.InvestmentsProjects}/${id}/revenue`}
					className="md:col-span-1 xl:col-span-1"
					title={t("project:project.dashboard.payments.title")}
					content={
						<div className="grid grid-cols-2 gap-2">
							<dt className="text-sm font-medium text-gray-500">
								{t(
									"project:project.dashboard.payments.last_payment.title",
								)}
							</dt>
							<dd className="text-right text-sm text-gray-900">
								{lastPaymentSum
									? formatCurrency(lastPaymentSum)
									: emptyValue()}
							</dd>
							<dt className="text-sm font-medium text-gray-500">
								{t(
									"project:project.dashboard.payments.last_payout.title",
								)}
							</dt>
							<dd className="text-right text-sm text-gray-900">
								{lastPaymentEndDate
									? lastPaymentEndDate.toFormat(dateFormat)
									: emptyValue()}
							</dd>
							<dt className="text-sm font-medium text-gray-500">
								{t(
									"project:project.dashboard.payments.total_payments.title",
								)}
							</dt>
							<dd className="text-right text-sm text-gray-900">
								{formatCurrency(paymentSum || 0)}
							</dd>
						</div>
					}
				/>
				<Item
					title={t("project:project.dashboard.details.title")}
					className="md:col-span-1 xl:col-span-1"
					content={
						<div className="grid grid-cols-2 gap-2">
							<dt className="text-sm font-medium text-gray-500">
								{t("common:common.date.start")}
							</dt>
							<dd className="text-right text-sm text-gray-900">
								{DateTime.fromISO(
									data?.project?.start,
								).toFormat(dateFormat)}
							</dd>
							<dt className="text-sm font-medium text-gray-500">
								{t("common:common.date.end")}
							</dt>
							<dd className="text-right text-sm text-gray-900">
								{DateTime.fromISO(data?.project?.end).toFormat(
									dateFormat,
								)}
							</dd>
							<dt className="text-sm font-medium text-gray-500">
								{t(
									"project:project.dashboard.details.owner.email",
								)}
							</dt>
							<dd className="text-right text-sm font-medium text-gray-900">
								{data?.project?.owner?.email}
							</dd>
						</div>
					}
				/>
			</div>
		</div>
	)
}

/**
 * ProjectDashboardLoadingState
 * @returns
 */
export const ProjectDashboardLoadingState = () => {
	const t = useTrans(["common", "project"])
	return (
		<div className="grid grid-cols-1 gap-8 md:grid-cols-4">
			<Card className="md:col-span-4 xl:col-span-3">
				<div className="mb-4 md:mb-2 md:flex md:items-center md:justify-start">
					<Heading
						as="h2"
						styleAs="h5"
						className="mb-2 md:mb-0"
					></Heading>
				</div>
				<p className="hidden overflow-ellipsis text-sm text-gray-700 md:block xl:w-1/2"></p>
				<div className="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2 md:mt-6 xl:grid-cols-4">
					{/** Share value */}
					<div
						className="col-span-1 animate-pulse rounded-md bg-gray-50 px-4 py-3"
						data-testid="project-sharevalue"
					>
						<dt className="text-sm font-medium text-gray-500"></dt>
						<dd className="mt-1 text-sm text-gray-900"></dd>
					</div>

					{/** Total shares */}
					<div
						className="col-span-1 animate-pulse rounded-md bg-gray-50 px-4 py-3"
						data-testid="project-solarshares"
					>
						<dt className="text-sm font-medium text-gray-500"></dt>
						<dd className="mt-1 text-sm text-gray-900"></dd>
					</div>

					{/** Interest Rate */}
					<div
						className="col-span-1 animate-pulse rounded-md bg-gray-50 px-4 py-3"
						data-testid="project-interestrate"
					>
						<dt className="text-sm font-medium text-gray-500"></dt>
						<dd className="mt-1 flex text-sm text-gray-900">
							<FiTrendingUp className="ml-2 h-5 w-5 flex-shrink-0 transform self-center text-green-500" />
						</dd>
					</div>

					{/** Initial investment */}
					<div
						className="col-span-1 animate-pulse rounded-md bg-gray-50 px-4 py-3"
						data-testid="project-sharevalue"
					>
						<dt className="text-sm font-medium text-gray-500"></dt>
						<dd className="mt-1 text-sm text-gray-900"></dd>
					</div>
				</div>
			</Card>
			{/** Project photo */}
			<Card
				className={`${classNames(
					"items-center",
					"justify-center",
					"xl:flex",
					"md:col-span-2",
					"xl:col-auto",
				)}`}
			></Card>

			{/** Energy supplier logo */}
			<Card
				className={`${classNames(
					"items-center",
					"justify-center",
					"xl:flex",
					"md:col-span-2",
					"xl:col-auto",
				)}`}
			></Card>
			<div className="grid grid-cols-1 gap-8 md:col-span-4 lg:grid-cols-2 xl:col-span-3">
				<ItemWithLink
					to={""}
					className="md:col-span-1 xl:col-span-1"
					title={t("project:project.dashboard.payments.title")}
					content={
						<div className="grid grid-cols-2 gap-2">
							<dt className="text-sm font-medium text-gray-500"></dt>
							<dd className="text-right text-sm text-gray-900"></dd>
							<dt className="text-sm font-medium text-gray-500"></dt>
							<dd className="text-right text-sm text-gray-900"></dd>
							<dt className="text-sm font-medium text-gray-500"></dt>
							<dd className="text-right text-sm text-gray-900"></dd>
						</div>
					}
				/>
				<Item
					title={t("project:project.dashboard.details.title")}
					className="md:col-span-1 xl:col-span-1"
					content={
						<div className="grid grid-cols-2 gap-2">
							<dt className="text-sm font-medium text-gray-500">
								{t("common:common.date.start")}
							</dt>
							<dd className="text-right text-sm text-gray-900"></dd>
							<dt className="text-sm font-medium text-gray-500">
								{t("common:common.date.end")}
							</dt>
							<dd className="text-right text-sm text-gray-900"></dd>
							<dt className="text-sm font-medium text-gray-500">
								{t(
									"project:project.dashboard.details.owner.email",
								)}
							</dt>
							<dd className="text-right text-sm text-gray-900"></dd>
						</div>
					}
				/>
			</div>
		</div>
	)
}

interface ItemProps {
	title: string
	content: ReactNode
	className?: string
	withLink?: boolean
}

const Item = ({ title, content, className = "", withLink }: ItemProps) => {
	return (
		<Card className={`${className} h-full group-hover:bg-gray-50`}>
			{withLink && (
				<FiArrowRight className="absolute right-5 top-5 transform text-gray-400 transition group-hover:translate-x-1/3" />
			)}
			<div className="space-y-3">
				<Heading as="h5" className="text-gray-600">
					{title}
				</Heading>
				{content}
			</div>
		</Card>
	)
}

interface ItemWithLinkProps {
	to: string
	title: string
	content: ReactNode
	className?: string
}

const ItemWithLink = ({
	to,
	className = "",
	title,
	content,
}: ItemWithLinkProps) => {
	return (
		<Link to={to} className={`${className} group relative`}>
			<Item title={title} content={content} withLink />
		</Link>
	)
}
