import { useState } from "react"

// UI
import { classNames } from "@/lib/ui"
import { Select } from "../form-controls"
import { Button } from "../Button"

// Icons
import { FiDownload } from "@/lib/icons"

// Translations
import { useTrans } from "@/i18n"

// Types
import { DownloadableFileType } from "./types"

/**
 * DownloadSelector
 * @param param0
 * @returns
 */
type DownloadSelectorProps = {
	options: { name: string; value: string }[]
	onDownload: (type: DownloadableFileType) => void
	disabled?: boolean
}
export const DownloadSelector = ({
	options,
	onDownload,
	disabled = false,
}: DownloadSelectorProps) => {
	const t = useTrans()

	// State
	const [selectKey, setSelectKey] = useState(0)

	return (
		<label className={classNames("relative flex items-center")}>
			<Button aria-label="Download" size="small" disabled={disabled}>
				<FiDownload className="h-4 w-4" />
			</Button>
			<Select
				key={selectKey}
				onChange={(event) => {
					setSelectKey((prev) => prev + 1) // Force re-render on change
					onDownload(
						(event.target as HTMLSelectElement)
							.value as DownloadableFileType,
					)
				}}
				className="absolute left-0 top-0 h-full w-full cursor-pointer p-0 opacity-0"
				defaultValue=""
			>
				{/** Default */}
				<option value="" disabled>
					{t("common:button.download.title")}
				</option>

				{/** Download options */}
				{options.map((option, index) => (
					<option value={option.value} key={index}>
						{option.name}
					</option>
				))}
			</Select>
		</label>
	)
}
