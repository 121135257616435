/**
 * Import from all i18n libraries that we use
 */
import {
	useTranslation,
	Trans,
	I18nextProvider,
	initReactI18next,
} from "react-i18next"
import i18next, { TFunction } from "i18next"
import i18nISOCountries from "i18n-iso-countries"
import HttpApi from "i18next-http-backend"
import LanguageDetector from "i18next-browser-languagedetector"

// Math
import { formatToDecimalPlaces } from "./math"

/**
 * getKeyAndValueFromNumber
 */
export const getKeyAndValueFromNumber = (
	number: number,
	formatter = formatToDecimalPlaces,
) => {
	let i18n = "common.numbers"
	let value: number

	if (number >= 1_000 && number < 1_000_000) {
		i18n = "common.numbers.thousand"
		value = formatter(number / 1_000)
	} else if (number >= 1_000_000 && number < 1_000_000_000) {
		i18n = "common.numbers.million"
		value = formatter(number / 1_000_000)
	} else if (number >= 1_000_000_000 && number < 1_000_000_000_000) {
		i18n = "common.numbers.billion"
		value = formatter(number / 1_000_000_000)
	} else if (number >= 1_000_000_000_000) {
		i18n = "common.numbers.trillion"
		value = formatter(number / 1_000_000_000_000)
	} else {
		value = formatter(number)
	}

	return { i18n, value }
}

/**
 * UseTranslation
 */
const useTrans = (namespace: string | string[] = "common") => {
	return useTranslation(namespace).t
}

// Export
export {
	i18next,
	useTrans,
	useTranslation,
	Trans,
	I18nextProvider,
	i18nISOCountries,
	HttpApi,
	initReactI18next,
	LanguageDetector,
}

// Types
export type { TFunction }
