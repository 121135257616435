// React
import { useMemo, useEffect, useCallback } from "react"

// Dates
import { DateTime } from "@/lib/dates"

// Router
import { useParams } from "@/lib/router"

// Graphs
import { CumulativeProductionGraph } from "@/components/visx/CumulativeProductionGraph"

// API
import { useFinanceGraphCumulativeProductionQuery } from "@/api/graphql"

// Hooks
import { useCurrentFinanceProject } from "../hooks/useCurrentFinanceProject"
import { useCurrentFinanceProjectId } from "../hooks/useCurrentFinanceProjectId"

// Feature flags
import { useFeatureFlags } from "@/context/user"

// Graphs
import {
	useProductionCumulativeGraphContext,
	AvailableDatePeriods,
} from "@/components/graphs/types/stacked-area/ProductionCumulative"

// Icons
import { FiChevronLeft, FiChevronRight } from "@/lib/icons"

function useProductionCumulativeGraphData() {
	const id = useCurrentFinanceProjectId()

	const { graphInterval, startTime, endTime, setMinimumTime } =
		useProductionCumulativeGraphContext()
	const params = useParams()

	// Query data
	const { data: projectData } = useCurrentFinanceProject()

	// Set the start date of the project as minimum time
	useEffect(() => {
		if (projectData?.project?.start) {
			setMinimumTime(
				DateTime.fromISO(projectData?.project?.start).toJSDate(),
			)
		}
	}, [projectData?.project?.start, setMinimumTime])

	// Query options
	const queryOptions = useMemo(() => {
		return {
			projectId: params.id,
			startTime,
			endTime,
			// monthly timerange in graph = daily intervals
			// yearly timerange in graph = monthly interals
			// all = monthly interals
			interval:
				graphInterval === AvailableDatePeriods.MONTH ? "day" : "month",
			id: String(id),
		}
	}, [startTime, endTime, params.id, id, graphInterval])

	const { data } = useFinanceGraphCumulativeProductionQuery(queryOptions)

	return data?.me?.finance_projects?.results?.[0]?.overall_statistics ?? {}
}

/**
 * FinanceGraphCumulativeProduction
 * @returns
 */
export function FinanceGraphCumulativeProduction() {
	// Context
	const { cumulative_production_data, expected_cumulative_production_data } =
		useProductionCumulativeGraphData()
	const { startTime, setStartTime, endTime, setEndTime } =
		useProductionCumulativeGraphContext()

	// Feature flags
	const { getFeatureFlagValue } = useFeatureFlags()

	const cumulativeProductionData = useMemo(() => {
		return (
			cumulative_production_data?.map((data) => {
				return {
					x: data?.date
						? DateTime.fromISO(data.date).toUTC().toMillis()
						: 0,
					y: data?.production ? parseFloat(data.production) : 0,
				}
			}) ?? []
		)
	}, [cumulative_production_data])

	const expectedCumulativeProductionData = useMemo(() => {
		return (
			expected_cumulative_production_data?.map((data) => {
				return {
					x: data?.date
						? DateTime.fromISO(data.date).toUTC().toMillis()
						: 0,
					y: data?.production ? parseFloat(data.production) : 0,
				}
			}) ?? []
		)
	}, [expected_cumulative_production_data])

	// Handle previous and next arrows
	const handlePreviousPeriod = useCallback(() => {
		const start = DateTime.fromJSDate(startTime)
		const end = DateTime.fromJSDate(endTime)
		const duration = end.diff(start)

		// Ensure we're working with proper DateTime objects and ISO strings
		const newStart = start.minus(duration)
		const newEnd = start // Previous period ends where current period starts

		setStartTime(newStart.toJSDate())
		setEndTime(newEnd.toJSDate())
	}, [startTime, endTime, setStartTime, setEndTime])
	const handleNextPeriod = useCallback(() => {
		const start = DateTime.fromJSDate(startTime)
		const end = DateTime.fromJSDate(endTime)
		const duration = end.diff(start)

		// Ensure we're working with proper DateTime objects and ISO strings
		const newStart = end // Next period starts where current period ends
		const newEnd = end.plus(duration)

		setStartTime(newStart.toJSDate())
		setEndTime(newEnd.toJSDate())
	}, [startTime, endTime, setStartTime, setEndTime])

	return (
		<div className="flex h-full items-center gap-2">
			{getFeatureFlagValue("ENABLE_NAV_ARROWS_ON_GRAPHS") === true && (
				<button
					onClick={handlePreviousPeriod}
					className="p-2 text-gray-500 hover:text-gray-700"
					aria-label="Previous period"
				>
					<FiChevronLeft className="h-6 w-6" />
				</button>
			)}
			<div className="h-full flex-1">
				<CumulativeProductionGraph
					data={cumulativeProductionData}
					estimation={expectedCumulativeProductionData}
					topPadding={50}
				/>
			</div>
			{getFeatureFlagValue("ENABLE_NAV_ARROWS_ON_GRAPHS") === true && (
				<button
					onClick={handleNextPeriod}
					className="p-2 text-gray-500 hover:text-gray-700"
					aria-label="Next period"
				>
					<FiChevronRight className="h-6 w-6" />
				</button>
			)}
		</div>
	)
}
