import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { defaultLanguage, Languages } from "@/i18n/config"

export interface UserPreferencesState {
	lang: Languages
	showInvestmentBanner: boolean
}

export const initialState: UserPreferencesState = {
	lang: defaultLanguage,
	showInvestmentBanner: true,
}

const userPreferencesSlice = createSlice({
	name: "preferences",
	initialState,
	reducers: {
		setLang: (state, action: PayloadAction<Languages>) => {
			state.lang = action.payload
		},
		setShowInvestmentBanner: (state, action: PayloadAction<boolean>) => {
			state.showInvestmentBanner = action.payload
		},
	},
})

// Action creators are generated for each case reducer function
export const { setLang, setShowInvestmentBanner } = userPreferencesSlice.actions

export default userPreferencesSlice.reducer
