import { ReactNode } from "react"
import { useTrans } from "@/i18n"
import { classNames } from "@/lib/ui"

// Graphs
import { MultiGraph } from "./types/bar-line/MultiGraph"

type GraphLabelBar = {
	type: "bar"
	variant: keyof typeof MultiGraph.barVariantColourMap
	title: string
}

type GraphLabelLine = {
	type: "line"
	variant: keyof typeof MultiGraph.lineVariantColourMap
	title: string
}

interface GraphLabelsProps {
	children: ReactNode
	className?: string
}

function GraphLabelsRoot({ children, className }: GraphLabelsProps) {
	return (
		<div
			className={classNames(
				className,
				"flex flex-wrap justify-end gap-6 lg:gap-x-8 lg:gap-y-2",
			)}
		>
			{children}
		</div>
	)
}

type GraphLabelType = GraphLabelBar | GraphLabelLine

export function GraphLabel({ title, variant, type }: GraphLabelType) {
	return (
		<div className="flex items-center justify-center gap-2">
			<span
				className="block h-2 w-2 rounded-full bg-red-500 lg:h-3 lg:w-3"
				style={{
					background:
						type === "bar"
							? MultiGraph.barVariantColourMap[variant]
							: MultiGraph.lineVariantColourMap[variant],
				}}
			/>
			<p className="text-xs leading-none lg:text-sm">{title}</p>
		</div>
	)
}

function TotalProduction() {
	const t = useTrans()

	return (
		<GraphLabel
			type="bar"
			variant="primary"
			title={t("common.multi_graph.label.total_production")}
		/>
	)
}

function ExpectedProduction() {
	const t = useTrans()

	return (
		<GraphLabel
			type="bar"
			variant="default"
			title={t("common.multi_graph.label.expected_production")}
		/>
	)
}

function PerformanceRatio() {
	const t = useTrans()

	return (
		<GraphLabel
			type="line"
			variant="primary"
			title={t("common.multi_graph.label.performance_ratio")}
		/>
	)
}

function ExpectedPerformanceRatio() {
	const t = useTrans()

	return (
		<GraphLabel
			type="line"
			variant="default"
			title={t("common.multi_graph.label.expected_performance_ratio")}
		/>
	)
}

export const GraphLabels = Object.assign(GraphLabelsRoot, {
	TotalProduction,
	ExpectedProduction,
	PerformanceRatio,
	ExpectedPerformanceRatio,
})
