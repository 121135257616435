// React
import { ReactNode, useState, useEffect, forwardRef } from "react"

// UI
import { classNames } from "@/lib/ui"

/**
/**
 * ContentHint
 * 
 * A component that provides a hint to the user when the content is scrolled to the left or right.
 */
export const ContentHint = forwardRef<
	HTMLElement,
	{ children: ReactNode; className?: string }
>(function ContentHint({ children, className }, ref) {
	const [state, set] = useState<"none" | "right" | "left" | "both">("none")

	// Calculate scroll state based on element dimensions
	const calculateScrollState = (element: HTMLDivElement) => {
		const scrollWidth = element.scrollWidth
		const scrollLeft = element.scrollLeft
		const width = element.clientWidth

		// Add a small buffer for floating point precision issues
		const isAtEnd = Math.abs(scrollWidth - width - scrollLeft) < 1

		// First check if there's any overflow content at all
		if (scrollWidth <= width) {
			set("none")
		}
		// Check if we're at the right edge
		else if (isAtEnd) {
			set("left")
		}
		// Check if we're at the left edge
		else if (scrollLeft === 0) {
			set("right")
		}
		// We're somewhere in the middle
		else {
			set("both")
		}
	}

	// When scrolling in div container or resizing window
	useEffect(() => {
		if (!ref || !("current" in ref) || !ref.current) return

		const element = ref.current
		const handleScroll = (evt: Event) => {
			if (evt.currentTarget instanceof HTMLDivElement) {
				calculateScrollState(evt.currentTarget)
			}
		}

		const handleResize = () => {
			if (element instanceof HTMLDivElement) {
				calculateScrollState(element)
			}
		}

		element.addEventListener("scroll", handleScroll)
		window.addEventListener("resize", handleResize)

		// Initial calculation
		if (element instanceof HTMLDivElement) {
			calculateScrollState(element)
		}

		return () => {
			element.removeEventListener("scroll", handleScroll)
			window.removeEventListener("resize", handleResize)
		}
	}, [set])

	return (
		<div
			className={classNames(
				state === "right" && "content-hint-left-hidden",
				state === "left" && "content-hint-right-hidden",
				state !== "none" && "content-hint",
				className,
			)}
		>
			{children}
		</div>
	)
})
