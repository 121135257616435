import { useContext } from "react"

// Navigation
import { matchPath, useLocation } from "@/lib/router"
import { Routes } from "@/constants/routes"
import { Link } from "@/components/Anchor"

// Translation
import { useTrans } from "@/i18n"

// UI
import { classNames } from "@/lib/ui"

// Context
import { AuthLayoutContext } from "@/layouts/AuthLayout"
import { useInvestmentTests } from "@/context/investmentTests"
import {
	useCurrentUserMessagesCount,
	useCurrentUserPayoutsCount,
	useCurrentUserProvisonalInvestmentsCount,
	useCurrentUserInvestmentsCount,
	useCurrentUserInvestmentsInProductionCount,
} from "@/context/user"

/**
 * InvestmentsSubMenu
 * @returns
 */
export function InvestmentsSubMenu() {
	// Router
	const location = useLocation()

	// Context
	const { setMobileMenuOpen } = useContext(AuthLayoutContext)
	const { amountOfRequiredActionsLeft } = useInvestmentTests()
	const messagesCount = useCurrentUserMessagesCount()
	const payoutsCount = useCurrentUserPayoutsCount()
	const provisionalInvestmentsCount =
		useCurrentUserProvisonalInvestmentsCount()
	const investmentsCount = useCurrentUserInvestmentsCount()
	const investmentsInProductionCount =
		useCurrentUserInvestmentsInProductionCount()

	/**
	 * The Routes.InvestmentsProjects page is a special case because it has nested routes in the url
	 * that need to be rendered on the same level and not both be marked as active
	 *
	 * @returns
	 */
	function isProjectSpecificSubPath() {
		// Return true if it is a full match
		if (location.pathname === Routes.InvestmentsProjects) {
			return true
		}

		// Check if the current path is a subpath of the ProjectsDashboard page
		const matchesPath = matchPath(
			{
				path: "/investments/projects/:id/:tab",
				end: true,
			},
			location.pathname,
		)

		// Now exclude the children pages from being marked as active
		const pagesToCheck = [
			Routes.InvestmentsProjectsCurrentOverview,
			Routes.ProvisionalInvestments,
			Routes.InvestmentsProjectsFiscalOverview,
			Routes.InvestmentsProjectsProduction,
			Routes.Communications,
			Routes.InvestmentsProjectsPayments,
			Routes.InvestmentsProjectsMonthlyOverviewMain,
			Routes.InvestmentsProjectsMonthlyOverview,
		]
		return (
			matchesPath !== null &&
			!pagesToCheck.some((page) => location.pathname.includes(page))
		)
	}

	/**
	 * Test if location path is any child page of the investor profile
	 * @returns
	 */
	function isInvestorProfileSpecificSubPath() {
		// Return true if it is a full match
		if (location.pathname === Routes.InvestorProfile) {
			return true
		}

		// Check if the current path is a subpath of the ProjectsDashboard page
		const matchesPath = matchPath(
			{
				path: "/investor/:tab",
				end: true,
			},
			location.pathname,
		)
		return matchesPath !== null
	}

	const t = useTrans(["common", "investments"])

	function closeMenu() {
		setMobileMenuOpen(false)
	}

	return (
		<nav className="space-y-1 pr-3">
			{/** Current overview */}
			{investmentsCount > 0 ? (
				<Link
					className={({ isActive }) =>
						classNames(
							isActive && "active-navigation-item",
							"block truncate text-sm hover:text-gray-500",
						)
					}
					href={Routes.InvestmentsProjectsCurrentOverview}
					onClick={closeMenu}
				>
					{t("project:current-overview.title")}
				</Link>
			) : null}

			{/** Project overview */}
			{investmentsCount > 0 ? (
				<Link
					className={classNames(
						isProjectSpecificSubPath() && "active-navigation-item",
						"block truncate text-sm hover:text-gray-500",
					)}
					href={Routes.InvestmentsProjects}
					onClick={closeMenu}
				>
					{t("common:common.navigation.investments.projects.link")}
				</Link>
			) : null}

			{/** Provisional investments */}
			{provisionalInvestmentsCount > 0 ? (
				<Link
					className={({ isActive }) =>
						classNames(
							isActive && "active-navigation-item",
							"block truncate text-sm hover:text-gray-500",
						)
					}
					href={Routes.ProvisionalInvestments}
					onClick={closeMenu}
				>
					{t("common:common.nav.provisional_investments")}
				</Link>
			) : null}

			{/** Fiscal year overview */}
			{investmentsCount > 0 ? (
				<Link
					className={({ isActive }) =>
						classNames(
							isActive && "active-navigation-item",
							"block truncate text-sm hover:text-gray-500",
						)
					}
					href={Routes.InvestmentsProjectsFiscalOverview}
					onClick={closeMenu}
				>
					{t("common:common.nav.fiscal")}
				</Link>
			) : null}

			{/** Show production when atleast 1 project has Production data */}
			{investmentsInProductionCount > 0 ? (
				<Link
					className={({ isActive }) =>
						classNames(
							isActive && "active-navigation-item",
							"block truncate text-sm hover:text-gray-500",
						)
					}
					href={Routes.InvestmentsProjectsProduction}
					onClick={closeMenu}
				>
					{t("common:common.nav.total_production")}
				</Link>
			) : null}

			{/** Payouts */}
			{payoutsCount > 0 ? (
				<Link
					className={({ isActive }) =>
						classNames(
							isActive && "active-navigation-item",
							"block truncate text-sm hover:text-gray-500",
						)
					}
					href={Routes.InvestmentsProjectsPayments}
					onClick={closeMenu}
				>
					{t("common:common.nav.payouts")}
				</Link>
			) : null}

			{/** Monthly overview */}
			{investmentsCount > 0 ? (
				<Link
					className={({ isActive }) =>
						classNames(
							isActive && "active-navigation-item",
							"block truncate text-sm hover:text-gray-500",
						)
					}
					href={Routes.InvestmentsProjectsMonthlyOverviewMain}
					onClick={closeMenu}
				>
					{t("investments:investments.monthly_overview.nav.title")}
				</Link>
			) : null}

			{/** Communication hub */}
			{messagesCount > 0 && (
				<Link
					className={({ isActive }) =>
						classNames(
							isActive && "active-navigation-item",
							"block truncate text-sm hover:text-gray-500",
						)
					}
					href={Routes.Communications}
					onClick={closeMenu}
				>
					{t("common:common.nav.all_messages")}
				</Link>
			)}

			{/** Investor profile */}
			<Link
				className="flex w-full items-center focus:no-underline"
				href={Routes.InvestorProfile}
				onClick={closeMenu}
			>
				<>
					<span
						className={classNames(
							isInvestorProfileSpecificSubPath() &&
								"active-navigation-item",
							"truncate text-sm hover:text-gray-500",
						)}
					>
						{t("common:common.navigation.investor-profile.title")}
					</span>

					{/** Show badge with number */}
					{amountOfRequiredActionsLeft !== undefined &&
						amountOfRequiredActionsLeft !== 0 && (
							<span className="ml-1 block h-4 w-4 items-center rounded-full bg-red-600 text-center text-xs text-white">
								{" "}
								{amountOfRequiredActionsLeft}
							</span>
						)}
				</>
			</Link>
		</nav>
	)
}
