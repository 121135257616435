// React rendering
import { StrictMode } from "react"
import { createRoot } from "react-dom/client"

// Router
import { createRoutesFromElements, Route, RouterProvider } from "@/lib/router"

// API
import { apiUrlOverride } from "@/api/rest"

// Tracing & Logging
import { initSentry, sentryCreateBrowserRouter } from "@/lib/sentry"

// Styles
import "@/styles/index.css"

// UI
import {
	App,
	RedirectToHomePage,
	RedirectToLogin,
	RedirectToNewMonthlyOverview,
} from "./App"

// env variables
import { MOCK_BACKEND } from "@/lib/env"

// Layouts / Templates
import { PageTemplate } from "@/templates/PageTemplate"

// Routes
import { ConditionalRoute } from "@/components/ConditionalRoute"
import { UserRoles } from "@/constants/constants"
import { Routes } from "@/constants/routes"

// Generic pages
import { NotFound } from "@/pages/NotFound"
import { Info } from "@/pages/profile/pages/Info"

// Statistics pages
import { Dashboard } from "@/pages/statistics/platform/AllProjectStats"
import { AllProjectsSolarInterestTariff } from "@/pages/statistics/platform/endex"
import { SingleProjectStats } from "@/pages/statistics/project"

// Project pages
import { ProjectLayout } from "@/pages/project"
import { ProjectCommunications } from "@/pages/project/pages/Communications"
import { ProjectDashboard } from "@/pages/project/pages/ProjectDashboard"
import { ProjectDocumentation } from "@/pages/project/pages/Documentation"
import { ProjectNotifications } from "@/pages/project/pages/Notifications"
import { ProjectProduction } from "@/pages/project/pages/Production"
import { ProjectRevenue } from "@/pages/project/pages/Revenue"

// Auth pages
import { Register } from "@/pages/account/Register"
import { Confirm } from "@/pages/account/Confirm"
import { SignIn } from "@/pages/account/SignIn"
import { Reset } from "@/pages/account/Reset"
import { Recover } from "@/pages/account/Recover"
// TODO: import { Recent } from "@/pages/Recent"

// Profile pages TODO: Cleanup code
import {
	ProfileLayout,
	ProfilePersonalData,
	ProfileBankDetails,
	ProfileSecurityComponentLayout,
	SettingsApp,
} from "@/pages/profile"
import { ProfileSecurityIndex } from "@/pages/profile/components/ProfileSecurityIndex"
import { ProfileSecurityVerifyCode } from "@/pages/profile/components/ProfileSecurityVerifyCode"
import { ProfileSecurityBackupCodes } from "@/pages/profile/components/ProfileSecurityBackupCodes"
import { ProfileChangePassword } from "@/pages/profile/pages/ChangePassword"
import { ProfileNotifications } from "@/pages/profile/pages/ProfileNotifications"
import { ProfileCompanyDetails } from "@/pages/profile/pages/ProfileCompanyDetails"

// Investor pages
import { InvestorLayout } from "@/pages/investor"
import { InvestorProfile } from "@/pages/investor/pages/InvestorProfile"
import { InvestorTest } from "@/pages/investor/pages/InvestorTest"
import { InvestorFunds } from "@/pages/investor/pages/InvestorFunds"
import { InvestorIdentity } from "@/pages/investor/pages/InvestorIdentity"
import { InvestorRisk } from "@/pages/investor/pages/InvestorRisk"

// Investments pages
import { InvestmentsLayout, InvestmentsIndex } from "@/pages/investments"
import { Fiscal } from "@/pages/investments/Fiscal"
import { Production } from "@/pages/investments/production"
import { ProvisionalInvestments } from "@/pages/investments/Investments"
import { MonthlyOverview } from "@/pages/investments/payments/monthly-overview"
import { InvestmentsProjectsCurrentOverview } from "@/pages/investments/current-overview"

// Payments pages / Finance
import { PaymentsLayout } from "@/pages/payments"
import { Payments as PaymentsList } from "@/pages/payments/components/Payments"
import { PaymentDetailProjectOwner } from "@/pages/payments/PaymentDetailProjectOwner"
import { PaymentDetailEnergySupplier } from "@/pages/payments/PaymentDetailEnergySupplier"
import { FinanceDashboard } from "@/pages/finance/pages/Dashboard"
import { FinancePerformance } from "@/pages/finance/pages/Performance"
import { FinanceSolarInterestTariff } from "@/pages/finance/pages/SolarInterestTariff"
import { FinanceBondsLoan } from "@/pages/finance/pages/FinanceBondsLoan"
import { AllCommunications } from "./pages/investments/AllCommunications"
import { Message } from "./pages/project/pages/Message"
import { AllPayments } from "./pages/investments/Payments"
import { RecentUserActivity } from "./components/RecentActivity"

async function registerMockApi() {
	// do not mock api backend when url from flag from url has been set
	if (Boolean(apiUrlOverride)) {
		return
	}

	const enableMocks =
		new URL(window.location.href).searchParams.get("enableMocks") === "true"

	if (MOCK_BACKEND === "true" && enableMocks) {
		const { worker } = await import("./mocks/browser")

		// `onUnhandledRequest` will remove the warning on unhandled requests
		// (such as static assets, manifest, favicon)
		await worker.start({ onUnhandledRequest: "bypass" })
		console.info("msw enabled")
	}
}

/**
 * main
 */
async function main() {
	// Register Mock Service Worker to fake API
	try {
		await registerMockApi()
	} catch (error) {
		console.error("Error registering mock API:", error)
	}

	const root = createRoot(document.getElementById("root") ?? document.body)

	// Initialise Sentry
	initSentry()

	/**
	 * Router
	 *
	 * Due to the decoupling of fetching and rendering in the design
	 * of the data APIs, you should create your router outside of the
	 * React tree with a statically defined set of routes. For more
	 * information on this design, please see the Remixing React Router
	 * blog post and the When to Fetch conference talk.
	 */
	const router = sentryCreateBrowserRouter(
		createRoutesFromElements(
			<Route
				path={Routes.Home}
				errorElement={
					<App>
						<NotFound />
					</App>
				}
				element={<App />}
			>
				{/** Redirect to dashboard when root route */}
				<Route
					path={Routes.Home}
					element={
						<ConditionalRoute
							auth={<RedirectToHomePage />}
							unAuth={<RedirectToLogin />}
						/>
					}
				/>

				{/** Account routes */}
				<Route path="account">
					<Route
						path={Routes.Login}
						element={
							<ConditionalRoute
								auth={<RedirectToHomePage />}
								unAuth={<SignIn />}
							/>
						}
					/>
					<Route
						path={Routes.Register}
						element={
							<ConditionalRoute
								unAuth={<Register />}
								auth={<RedirectToHomePage />}
							/>
						}
					/>
					<Route
						path={Routes.RegisterConfirm}
						element={
							<ConditionalRoute
								unAuth={<Confirm />}
								auth={<RedirectToHomePage />}
							/>
						}
					/>
					<Route
						path={Routes.RecoverPassword}
						element={
							<ConditionalRoute
								unAuth={<Recover />}
								auth={<RedirectToHomePage />}
							/>
						}
					/>
					<Route
						path={Routes.ResetPassword}
						element={
							<ConditionalRoute
								unAuth={<Reset />}
								auth={<RedirectToHomePage />}
							/>
						}
					/>
				</Route>

				{/** Statistics pages */}
				<Route path="stats">
					<Route
						path={Routes.StatisticsPlatform}
						element={<Dashboard />}
					/>
					<Route
						path={Routes.StatisticsPlatformEndex}
						element={<AllProjectsSolarInterestTariff />}
					/>
					<Route
						path={`${Routes.StatisticsPlatformSingleProject}/:id`}
						element={<SingleProjectStats />}
					/>
				</Route>

				{/** Profile pages */}
				<Route path={Routes.SettingsProfilePersonalDetails}>
					<Route
						path={Routes.SettingsProfilePersonalDetails}
						element={
							<ConditionalRoute
								auth={
									<ProfileLayout>
										<ProfilePersonalData />
									</ProfileLayout>
								}
								unAuth={<RedirectToLogin />}
							/>
						}
					/>
					<Route
						path={Routes.SettingsProfileChangePassword}
						element={
							<ConditionalRoute
								auth={
									<ProfileLayout>
										<ProfileChangePassword />
									</ProfileLayout>
								}
								unAuth={<RedirectToLogin />}
							/>
						}
					/>
					<Route
						path={Routes.SettingsProfileNotifications}
						element={
							<ConditionalRoute
								auth={
									<ProfileLayout>
										<ProfileNotifications />
									</ProfileLayout>
								}
								unAuth={<RedirectToLogin />}
							/>
						}
					/>
					<Route
						path={Routes.SettingsProfileCompanyDetails}
						element={
							<ConditionalRoute
								auth={
									<ProfileLayout>
										<ProfileCompanyDetails />
									</ProfileLayout>
								}
								unAuth={<RedirectToLogin />}
							/>
						}
					/>
					<Route
						path={Routes.SettingsProfileBankDetails}
						element={
							<ConditionalRoute
								auth={
									<ProfileLayout>
										<ProfileBankDetails />
									</ProfileLayout>
								}
								unAuth={<RedirectToLogin />}
							/>
						}
					/>
					<Route
						path={Routes.Recent}
						element={
							<ConditionalRoute
								auth={
									<ProfileLayout>
										<RecentUserActivity />
									</ProfileLayout>
								}
								unAuth={<RedirectToLogin />}
							/>
						}
					/>
					<Route
						path={Routes.AppSettings}
						element={
							<ConditionalRoute
								auth={
									<ProfileLayout>
										<SettingsApp />
									</ProfileLayout>
								}
								unAuth={<RedirectToLogin />}
							/>
						}
					/>

					{/** Profile security */}
					<Route path={Routes.SettingsProfileSecurity}>
						<Route
							path={Routes.SettingsProfileSecurity}
							element={
								<ConditionalRoute
									auth={
										<ProfileLayout>
											<ProfileSecurityComponentLayout>
												<ProfileSecurityIndex />
											</ProfileSecurityComponentLayout>
										</ProfileLayout>
									}
									unAuth={<RedirectToLogin />}
								/>
							}
						/>
						<Route
							path={Routes.SettingsProfileSecurityVerify}
							element={
								<ConditionalRoute
									auth={
										<ProfileLayout>
											<ProfileSecurityComponentLayout>
												<ProfileSecurityVerifyCode />
											</ProfileSecurityComponentLayout>
										</ProfileLayout>
									}
									unAuth={<RedirectToLogin />}
								/>
							}
						/>
						<Route
							path={
								Routes.SettingsProfileSecurityRegenerateBackupCodes
							}
							element={
								<ConditionalRoute
									auth={
										<ProfileLayout>
											<ProfileSecurityComponentLayout>
												<ProfileSecurityBackupCodes />
											</ProfileSecurityComponentLayout>
										</ProfileLayout>
									}
									unAuth={<RedirectToLogin />}
								/>
							}
						/>
					</Route>
				</Route>

				{/** Investor profile */}
				<Route path={Routes.InvestorProfile}>
					<Route
						path={Routes.InvestorProfile}
						element={
							<ConditionalRoute
								auth={
									<InvestorLayout>
										<InvestorProfile />
									</InvestorLayout>
								}
								unAuth={<RedirectToLogin />}
							/>
						}
					/>
					<Route
						path={Routes.InvestorTest}
						element={
							<ConditionalRoute
								auth={
									<InvestorLayout>
										<InvestorTest />
									</InvestorLayout>
								}
								unAuth={<RedirectToLogin />}
							/>
						}
					/>
					<Route
						path={Routes.InvestorRisk}
						element={
							<ConditionalRoute
								auth={
									<InvestorLayout>
										<InvestorRisk />
									</InvestorLayout>
								}
								unAuth={<RedirectToLogin />}
							/>
						}
					/>
					<Route
						path={Routes.InvestorFunds}
						element={
							<ConditionalRoute
								auth={
									<InvestorLayout>
										<InvestorFunds />
									</InvestorLayout>
								}
								unAuth={<RedirectToLogin />}
							/>
						}
					/>
					<Route
						path={Routes.InvestorIdentity}
						element={
							<ConditionalRoute
								auth={
									<InvestorLayout>
										<InvestorIdentity />
									</InvestorLayout>
								}
								unAuth={<RedirectToLogin />}
							/>
						}
					/>
				</Route>
				<Route
					path={Routes.Info}
					element={
						<ConditionalRoute
							roles={[UserRoles.admin]}
							auth={<Info />}
							unAuth={<RedirectToLogin />}
						/>
					}
				/>

				{/** Communications */}
				<Route path={Routes.Communications}>
					<Route
						path={Routes.Communications}
						element={
							<ConditionalRoute
								auth={
									<InvestmentsLayout>
										<AllCommunications />
									</InvestmentsLayout>
								}
								unAuth={<RedirectToLogin />}
							/>
						}
					/>
					<Route
						path={Routes.CommunicationsMessage}
						element={
							<ConditionalRoute
								auth={
									<InvestmentsLayout>
										<Message />
									</InvestmentsLayout>
								}
								unAuth={<RedirectToLogin />}
							/>
						}
					/>
				</Route>

				{/** Investments pages */}
				<Route path="investments">
					<Route path={Routes.InvestmentsProjects}>
						<Route
							path={Routes.InvestmentsProjects}
							element={
								<ConditionalRoute
									auth={
										<InvestmentsLayout>
											<InvestmentsIndex />
										</InvestmentsLayout>
									}
									unAuth={<RedirectToLogin />}
								/>
							}
						/>
						<Route
							path={Routes.InvestmentsProjectsDashboardPaginated}
							element={
								<ConditionalRoute
									auth={
										<InvestmentsLayout>
											<InvestmentsIndex />
										</InvestmentsLayout>
									}
									unAuth={<RedirectToLogin />}
								/>
							}
						/>
					</Route>
					<Route
						path={Routes.ProvisionalInvestments}
						element={
							<ConditionalRoute
								auth={
									<InvestmentsLayout>
										<ProvisionalInvestments />
									</InvestmentsLayout>
								}
								unAuth={<RedirectToLogin />}
							/>
						}
					/>
					<Route
						path={Routes.InvestmentsProjectsCurrentOverview}
						element={
							<ConditionalRoute
								auth={
									<InvestmentsLayout>
										<InvestmentsProjectsCurrentOverview />
									</InvestmentsLayout>
								}
								unAuth={<RedirectToLogin />}
							/>
						}
					/>
					<Route
						path={Routes.InvestmentsProjectsFiscalOverview}
						element={
							<ConditionalRoute
								auth={
									<InvestmentsLayout>
										<Fiscal />
									</InvestmentsLayout>
								}
								unAuth={<RedirectToLogin />}
							/>
						}
					/>

					{/** Monthly overview */}
					<Route
						path={
							// KEEP this for backward compatibility
							"/investments/projects/dashboard/monthly-overview/:date"
						}
						element={<RedirectToNewMonthlyOverview />}
					/>
					<Route
						path={Routes.InvestmentsProjectsMonthlyOverviewMain}
						element={
							<ConditionalRoute
								auth={
									<InvestmentsLayout>
										<MonthlyOverview />
									</InvestmentsLayout>
								}
								unAuth={<RedirectToLogin />}
							/>
						}
					>
						<Route
							path={Routes.InvestmentsProjectsMonthlyOverview}
							element={
								<ConditionalRoute
									auth={
										<InvestmentsLayout>
											<MonthlyOverview />
										</InvestmentsLayout>
									}
									unAuth={<RedirectToLogin />}
								/>
							}
						/>
					</Route>

					<Route
						path={Routes.InvestmentsProjectsProduction}
						element={
							<ConditionalRoute
								auth={
									<InvestmentsLayout>
										<Production />
									</InvestmentsLayout>
								}
								unAuth={<RedirectToLogin />}
							/>
						}
					/>
					<Route
						path={Routes.InvestmentsProjectsPayments}
						element={
							<ConditionalRoute
								auth={
									<InvestmentsLayout>
										<AllPayments />
									</InvestmentsLayout>
								}
								unAuth={<RedirectToLogin />}
							/>
						}
					/>

					{/** Project pages */}
					<Route path={`${Routes.InvestmentsProjects}/:id/`}>
						<Route
							path={Routes.InvestmentsProjectDashboard}
							element={
								<ConditionalRoute
									auth={
										<ProjectLayout>
											<ProjectDashboard />
										</ProjectLayout>
									}
									unAuth={<RedirectToLogin />}
								/>
							}
						/>
						<Route
							path={Routes.InvestmentsProjectRevenue}
							element={
								<ConditionalRoute
									auth={
										<ProjectLayout>
											<ProjectRevenue />
										</ProjectLayout>
									}
									unAuth={<RedirectToLogin />}
								/>
							}
						/>
						<Route
							path={Routes.InvestmentsProjectNotifications}
							element={
								<ConditionalRoute
									auth={
										<ProjectLayout>
											<ProjectNotifications />
										</ProjectLayout>
									}
									unAuth={<RedirectToLogin />}
								/>
							}
						/>
						<Route
							path={Routes.InvestmentsProjectData}
							element={
								<ConditionalRoute
									auth={
										<ProjectLayout>
											<ProjectProduction />
										</ProjectLayout>
									}
									unAuth={<RedirectToLogin />}
								/>
							}
						/>
						<Route
							path={Routes.InvestmentsProjectCommunications}
							element={
								<ConditionalRoute
									auth={
										<ProjectLayout>
											<ProjectCommunications />
										</ProjectLayout>
									}
									unAuth={<RedirectToLogin />}
								/>
							}
						/>
						<Route
							path={Routes.InvestmentsProjectDocumentation}
							element={
								<ConditionalRoute
									auth={
										<ProjectLayout>
											<ProjectDocumentation />
										</ProjectLayout>
									}
									unAuth={<RedirectToLogin />}
								/>
							}
						/>
					</Route>
				</Route>

				{/** Finance */}
				<Route path="finance">
					{/** Finance projects */}
					<Route path={Routes.FinanceProjects}>
						<Route
							path={Routes.FinanceProjects}
							element={
								<ConditionalRoute
									roles={[
										UserRoles.admin,
										UserRoles.projectOwner,
										UserRoles.energyProvider,
										UserRoles.staff,
									]}
									auth={<FinanceDashboard />}
									unAuth={<RedirectToLogin />}
								/>
							}
						/>
						<Route
							path={`${Routes.FinanceProjects}/:id`}
							element={
								<ConditionalRoute
									roles={[
										UserRoles.admin,
										UserRoles.projectOwner,
										UserRoles.energyProvider,
										UserRoles.staff,
									]}
									auth={<FinancePerformance />}
									unAuth={<RedirectToLogin />}
								/>
							}
						/>
					</Route>

					{/** Payments pages */}
					<Route path={Routes.FinanceInterestPayments}>
						<Route
							path={Routes.FinanceInterestPayments}
							element={
								<ConditionalRoute
									roles={[
										UserRoles.admin,
										UserRoles.projectOwner,
										UserRoles.energyProvider,
										UserRoles.staff,
									]}
									auth={
										<PaymentsLayout>
											<PageTemplate>
												<div className="space-y-5 md:space-y-6 lg:space-y-8">
													<PaymentsList />
												</div>
											</PageTemplate>
										</PaymentsLayout>
									}
									unAuth={<RedirectToLogin />}
								/>
							}
						/>

						<Route
							path={`${Routes.FinanceInterestPayments}/supplier/:id`}
							element={
								<ConditionalRoute
									roles={[
										UserRoles.admin,
										UserRoles.energyProvider,
										UserRoles.staff,
									]}
									auth={
										<PaymentsLayout>
											<PageTemplate>
												<div className="space-y-5 md:space-y-6 lg:space-y-8">
													<PaymentDetailEnergySupplier />
												</div>
											</PageTemplate>
										</PaymentsLayout>
									}
									unAuth={<RedirectToLogin />}
								/>
							}
						/>
						<Route
							path={`${Routes.FinanceInterestPayments}/owner/:id`}
							element={
								<ConditionalRoute
									roles={[
										UserRoles.admin,
										UserRoles.projectOwner,
										UserRoles.staff,
									]}
									auth={
										<PaymentsLayout>
											<PageTemplate>
												<div className="space-y-5 md:space-y-6 lg:space-y-8">
													<PaymentDetailProjectOwner />
												</div>
											</PageTemplate>
										</PaymentsLayout>
									}
									unAuth={<RedirectToLogin />}
								/>
							}
						/>
					</Route>
					<Route
						path={`${Routes.FinanceSolarInterestTariff}/:id`}
						element={
							<ConditionalRoute
								roles={[
									UserRoles.admin,
									UserRoles.projectOwner,
									UserRoles.energyProvider,
									UserRoles.staff,
								]}
								auth={<FinanceSolarInterestTariff />}
								unAuth={<RedirectToLogin />}
							/>
						}
					/>
					<Route
						path={`${Routes.FinanceBondsLoan}/:id`}
						element={
							<ConditionalRoute
								roles={[
									UserRoles.admin,
									UserRoles.projectOwner,
									UserRoles.energyProvider,
									UserRoles.staff,
								]}
								auth={<FinanceBondsLoan />}
								unAuth={<RedirectToLogin />}
							/>
						}
					/>
				</Route>
			</Route>,
		),
		{
			/**
			 * Future options
			 *
			 * DOCS: https://reactrouter.com/6.28.1/upgrading/future
			 */
			future: {
				v7_relativeSplatPath: true,
				v7_fetcherPersist: true,
				v7_normalizeFormMethod: true,
				v7_partialHydration: true,
				v7_skipActionErrorRevalidation: true,
			},
		},
	)

	// Render root
	root.render(
		<StrictMode>
			<RouterProvider
				router={router}
				future={{
					/**
					 * Future options
					 *
					 * DOCS: https://reactrouter.com/6.28.1/upgrading/future
					 *
					 * Important: somehow the v7_startTransition flag only works on the RouterProvider and
					 * not as an option above on createRoutesFromElements
					 *
					 * TODO: This is DISABLED now because it prevents navigation untill the data has been loaded
					 */
					v7_startTransition: false,
				}}
			/>
		</StrictMode>,
	)
}

// Run main function
main()
