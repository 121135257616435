/**
 * Detect device type
 *
 * Which method works best depends on your use case!
 * If you need it for styling, CSS is best. If you need logic changes, JavaScript detection is better.
 */

// React
import { useEffect, useState } from "react"

// Detect browser user agent
import { isMobile } from "react-device-detect"

// If you need it for styling, CSS is best
const useDeviceCssType = (breakpoint = 768) => {
	const [isMobile, setIsMobile] = useState(window.innerWidth < breakpoint)

	useEffect(() => {
		const handleResize = () => setIsMobile(window.innerWidth < breakpoint)
		window.addEventListener("resize", handleResize)
		return () => window.removeEventListener("resize", handleResize)
	}, [breakpoint])

	return isMobile ? "mobile" : "desktop"
}

// Hook that checks browser user agent type
const useDeviceAgentType = () => {
	return isMobile ? "mobile" : "desktop"
}

// Export
export { useDeviceCssType, useDeviceAgentType, isMobile }
