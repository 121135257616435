import { ReactNode } from "react"
import { useTrans } from "@/i18n"
import { classNames } from "@/lib/ui"
import { Button } from "../Button"
import { Heading } from "../Typography"

interface ErrorStateProps {
	title?: string
	message?: string
	className?: string
}

export const ErrorState = ({ title, message, className }: ErrorStateProps) => {
	const t = useTrans("common")

	return (
		<ErrorStateCard className={className}>
			<Heading as="h3" styleAs="h5" className="sm:mt-2">
				{title || t("common.error_state.title")}
			</Heading>
			<p className="m-auto mt-4 max-w-sm text-sm text-gray-600">
				{message || t("common.error_state.text")}
			</p>
			<Button
				onClick={() => {
					// This forces reload of whole page. We do this to also invalidate any caches!
					window.location.reload()
				}}
				variant="primary"
				className="mt-8 sm:mt-6"
			>
				{t("common.error_state.retry")}
			</Button>
		</ErrorStateCard>
	)
}

interface ErrorStateCardProps {
	children: ReactNode
	className?: string
}

export const ErrorStateCard = ({
	children,
	className,
}: ErrorStateCardProps) => (
	<div
		className={classNames(
			className,
			"overflow-hidden rounded-lg bg-white px-4 py-6 text-center shadow sm:px-6 sm:py-8 lg:px-8 lg:py-10",
		)}
	>
		{children}
	</div>
)
