import { HTMLProps } from "react"
import { classNames } from "@/lib/ui"

export const SelectButton = ({
	className,
	...rest
}: Omit<HTMLProps<HTMLButtonElement>, "type">) => {
	return (
		<button
			className={classNames(
				className,
				"justify-start",
				"inline-flex items-center justify-start",
				"border",
				"rounded-md shadow-sm",
				"font-normal text-gray-900",
				"transition",
				"focus:outline-none focus:ring-2 focus:ring-offset-2",
				"focus:ring-primary-500 border-gray-300 bg-white hover:bg-gray-50",
				"px-4 py-2 text-sm",
				"w-full",
			)}
			type="button"
			{...rest}
		/>
	)
}

export const Select = ({
	className,
	...rest
}: HTMLProps<HTMLSelectElement>) => {
	return (
		<select
			className={classNames(
				className,
				"absolute bottom-0 left-0 right-0 top-0 h-full w-full cursor-pointer opacity-0",
			)}
			{...rest}
		/>
	)
}
