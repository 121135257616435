// React
import { memo, useMemo } from "react"

// Utils
import { sortBy } from "lodash"

// Animations
import {
	AnimatePresence,
	motion,
	getTransitionPopoverProps,
} from "@/lib/animations"
import { Popover, Transition } from "@/lib/ui"

// Translations
import { Trans, useTrans } from "@/i18n"
import { onlyCoreLocationCountryChoices } from "@/lib/countries"
import { useLang } from "@/context/lang"

// GraphQL
import {
	CoreLocationCountryChoices,
	usePersonalDetailsQuery,
	useProfileUpdateMutation,
} from "@/api/graphql"

// Forms
import { handleErrorWithFormik } from "@/lib/formik"
import { FormikProvider, useFormik } from "formik"
import {
	FormikErrors,
	FormikInput,
	FormikLabel,
	FormikSubmitButton,
	LabelSubtitle,
	Select,
	FormGroup,
	SelectButton,
	FormikError,
} from "../form-controls"

// UI
import { Tooltip } from "@/components/Tooltip"
import { CardBody, CardFooter, CardWrapper } from "../Card"
import { Heading } from "../Typography"
import { useToasts } from "@/context/toasts"
import { DatePicker } from "@/components/form-controls/DatePicker"

// Icons
import { FiChevronDown, FiInfo } from "@/lib/icons"

function useEnergySuppliers() {
	const { data } = usePersonalDetailsQuery()

	// Memoize the energy suppliers
	return useMemo(() => {
		return sortBy(data?.energy_suppliers ?? [], (supplier) =>
			supplier.name.toLowerCase(),
		)
	}, [data])
}

export function PersonalDetails() {
	const toasts = useToasts()

	const t = useTrans("profile")
	const { getCountryName } = useLang()
	const energySuppliers = useEnergySuppliers()
	const { data, refetch } = usePersonalDetailsQuery()

	const updatePersonalDetailsMutation = useProfileUpdateMutation({
		onSuccess: async (response) => {
			// map each error from graphql to the field it's related to
			for (const error of response?.profile_update?.errors ?? []) {
				if (error?.field) {
					form.setFieldError(error.field, error.messages[0])
				}
			}

			// do we have data? then success
			if (
				!response?.profile_update?.errors ||
				response.profile_update.errors?.length === 0
			) {
				toasts.addToast({
					variant: "success",
					id: `bank-details-success-${Date.now()}`,
					text: t(`profile.personal_details.success_message`),
				})
				await refetch()
			}
			// otherwise show a message that there are form errors
			else {
				form.setFieldError(
					"errors.common",
					"common.form_errors.attention_required",
				)
			}
		},
		onError: (error: Error) => {
			handleErrorWithFormik(error, form)
		},
	})

	// Formik form
	const form = useFormik({
		enableReinitialize: true,
		initialValues: {
			...data,
			initials: data?.me?.profile?.initials,
		},
		onSubmit: async (values) => {
			await updatePersonalDetailsMutation.mutateAsync({
				profile: {
					initials: values?.initials ?? "",
					phone: values?.me?.profile?.phone ?? "",
					date_of_birth: values?.me?.profile?.date_of_birth ?? "",
					address: {
						address_line_primary:
							values?.me?.profile?.address
								?.address_line_primary ?? "",
						address_line_secondary:
							values?.me?.profile?.address
								?.address_line_secondary ?? "",
						country: values?.me?.profile?.address
							?.country as CoreLocationCountryChoices,
						city: values?.me?.profile?.address?.city ?? "",
						postal_code:
							values?.me?.profile?.address?.postal_code ?? "",
					},
				},
				investor: {
					supplier_id: values?.me?.investor?.supplier?.id ?? "",
					supplier_account:
						values?.me?.investor?.supplier_account ?? null,
				},
			})
		},
	})

	const sortedCountries = useMemo(() => {
		return onlyCoreLocationCountryChoices
			.map((country) => ({
				...country,
				localisedCountry: getCountryName(country.code) ?? country.name,
			}))
			.sort((a, b) =>
				a.localisedCountry.localeCompare(b.localisedCountry),
			)
	}, [getCountryName])

	return (
		<FormikProvider value={form}>
			<CardWrapper>
				<form onSubmit={form.handleSubmit}>
					<CardBody>
						<Heading
							as="h2"
							styleAs="h5"
							className="mb-3 sm:truncate"
						>
							{t("profile.personal_details.title")}
						</Heading>
						<FormGroup heading={<PersonalDetailsHeading />}>
							<div
								className="w-fulls grid gap-6 2xl:grid-cols-2"
								data-testid="form-group"
							>
								{/** Full Name */}
								<div className="2xl:col-span-2">
									<FormikLabel
										htmlFor="me.full_name"
										className="flex"
									>
										<Tooltip
											content={
												<p>
													{t(
														"profile.personal_details.name.tooltip",
													)}
												</p>
											}
										>
											<span className="flex items-center">
												{t(
													"profile.personal_details.name.title",
												)}
												<FiInfo className="ml-2" />
											</span>
										</Tooltip>
									</FormikLabel>
									<FormikInput
										name="me.full_name"
										required
										className="mt-1 block w-full"
										readOnly
										disabled
										classNameWrapper="lg:w-1/2"
									/>
									<FormikError field="me.full_name" />
								</div>

								{/** Name Initials */}
								<div className="2xl:col-span-2">
									<FormikLabel
										htmlFor="initials"
										className="flex"
									>
										<Tooltip
											content={
												<p>
													{t(
														"profile.personal_details.name.tooltip",
													)}
												</p>
											}
										>
											<span className="flex items-center">
												{t(
													"profile.personal_details.initials.title",
												)}
												<FiInfo className="ml-2" />
											</span>
										</Tooltip>
									</FormikLabel>
									<FormikInput
										name="initials"
										required
										disabled={Boolean(
											data?.me?.profile?.initials,
										)}
										className="mt-1 block w-full"
										classNameWrapper="lg:w-1/2"
									/>
									<FormikError field="initials" />
								</div>

								{/**  Birth date */}
								<Popover.Group className="2xl:col-span-2">
									<Popover className="relative z-20">
										<Popover.Button
											as="div"
											disabled={
												form?.values?.me?.profile
													?.date_of_birth !== null
											}
										>
											<FormikLabel
												htmlFor="me.profile.date_of_birth"
												className="flex"
											>
												<span className="flex items-center">
													{t(
														"profile.personal_details.birth_date.title",
													)}
												</span>
											</FormikLabel>
											<FormikInput
												disabled={
													form?.values?.me?.profile
														?.date_of_birth !== null
												}
												name="me.profile.date_of_birth"
												value={new Date(
													form?.values?.me?.profile?.date_of_birth,
												).toLocaleDateString("nl-NL")}
												required
												className="mt-1 block w-full"
												classNameWrapper="lg:w-1/2"
											/>
											<FormikError field="me.profile.date_of_birth" />
										</Popover.Button>

										<Transition
											{...getTransitionPopoverProps()}
										>
											<Popover.Panel className="absolute left-0 mt-2 origin-top-left rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
												{({ close }) => (
													<div
														className="min-w-[300px]"
														data-testid="daterangepicker-from"
													>
														<DatePicker
															showNavigation={
																false
															}
															onSelectDate={(
																date,
															) => {
																const formattedDate = `${date?.day}-${date?.month}-${date?.year}`
																form.setFieldValue(
																	"me.profile.date_of_birth",
																	formattedDate,
																)

																// Close popover
																close()
															}}
														/>
													</div>
												)}
											</Popover.Panel>
										</Transition>
									</Popover>
								</Popover.Group>

								{/**  Phone number */}
								<div className="2xl:col-span-2">
									<FormikLabel
										htmlFor="me.profile.phone"
										className="flex"
									>
										<span className="flex items-center">
											{t(
												"profile.personal_details.phone_number.title",
											)}
										</span>
									</FormikLabel>
									<FormikInput
										name="me.profile.phone"
										required
										className="mt-1 block w-full"
										classNameWrapper="lg:w-1/2"
									/>
									<FormikError field="me.profile.phone" />
								</div>

								{/**  Email */}
								<div className="2xl:col-span-2">
									<FormikLabel
										htmlFor="me.email"
										className="flex"
									>
										<Tooltip
											content={
												<p>
													{t(
														"profile.personal_details.email.tooltip",
													)}
												</p>
											}
										>
											<span className="flex items-center">
												{t(
													"profile.personal_details.email.title",
												)}
												<FiInfo className="ml-2" />
											</span>
										</Tooltip>
									</FormikLabel>
									<FormikInput
										name="me.email"
										required
										className="mt-1 block w-full"
										readOnly
										disabled
										classNameWrapper="lg:w-1/2"
									/>
									<FormikError field="me.email" />
								</div>

								{/** Street name (primary) */}
								<div className="2xl:col-span-2">
									<FormikLabel
										htmlFor="me.profile.address.address_line_primary"
										className="truncate"
									>
										{t(
											"profile.personal_details.streetname.title",
										)}
									</FormikLabel>
									<FormikInput
										name="me.profile.address.address_line_primary"
										required
										className="mt-1 block w-full"
										classNameWrapper="lg:w-1/2"
									/>
									<FormikError field="me.profile.address.address_line_primary" />
								</div>

								{/** Housenumber and suffix (secondary) */}
								<div className="2xl:col-span-2">
									<FormikLabel
										htmlFor="me.profile.address.address_line_secondary"
										className="truncate"
									>
										{t(
											"profile.personal_details.houseNumber.title",
										)}
									</FormikLabel>
									<FormikInput
										name="me.profile.address.address_line_secondary"
										required
										className="mt-1 block w-full"
										classNameWrapper="lg:w-1/2"
									/>
									<FormikError field="me.profile.address.address_line_secondary" />
								</div>

								{/** Zip code */}
								<div className="2xl:col-span-2">
									<FormikLabel
										htmlFor="me.profile.address.postal_code"
										className="truncate"
									>
										{t(
											"profile.personal_details.postcode.title",
										)}
									</FormikLabel>
									<FormikInput
										name="me.profile.address.postal_code"
										required
										className="mt-1 block w-full"
										classNameWrapper="lg:w-1/2"
									/>
									<FormikError field="me.profile.address.postal_code" />
								</div>

								{/** City */}
								<div className="2xl:col-span-2">
									<FormikLabel
										htmlFor="me.profile.address.city"
										className="truncate"
									>
										{t(
											"profile.personal_details.city.title",
										)}
									</FormikLabel>
									<FormikInput
										name="me.profile.address.city"
										required
										className="mt-1 block w-full"
										classNameWrapper="lg:w-1/2"
									/>
									<FormikError field="me.profile.address.city" />
								</div>

								{/** Country */}
								<div className="2xl:col-span-2">
									<FormikLabel
										htmlFor="me.profile.address.country"
										className="truncate"
									>
										{t(
											"profile.personal_details.country.title",
										)}
									</FormikLabel>
									<label className="mt-1">
										<SelectButton className="relative lg:w-1/2">
											{getCountryName(
												form.values.me?.profile?.address
													?.country as CoreLocationCountryChoices,
											)}
											<FiChevronDown className="ml-2" />
											<Select
												onChange={(evt) => {
													evt.preventDefault()
													form.setFieldValue(
														"me.profile.address.country",
														evt.currentTarget.value,
													)
												}}
												name="me.profile.address.country"
												id="me.profile.address.country"
												value={
													form.values.me?.profile
														?.address?.country ?? ""
												}
											>
												{sortedCountries.map((item) => (
													<option
														value={item.code}
														key={item.code}
													>
														{item.localisedCountry}
													</option>
												))}
											</Select>
										</SelectButton>
										<FormikError field="me.profile.address.country" />
									</label>
								</div>

								{/** Energy Supplier account */}
								<div className="sm:border-t sm:border-gray-200 sm:pt-5 2xl:col-span-2">
									<label className="mt-1">
										<FormikLabel htmlFor="me.investor.supplier_account">
											{t(
												"profile.personal_details.energy_supplier.title",
											)}
											<LabelSubtitle>
												{t(
													"profile.personal_details.energy_supplier.copy",
												)}
											</LabelSubtitle>
										</FormikLabel>
										<SelectButton className="relative lg:w-1/2">
											{form.values.me?.investor
												?.supplier === null
												? t(
														"profile.personal_details.energy_supplier.no_energy_supplier",
												  )
												: form.values.me?.investor
														?.supplier?.name}
											<FiChevronDown className="ml-2" />
											<Select
												name="me.investor.supplier"
												id="me.investor.supplier"
												value={
													form.values.me?.investor
														?.supplier?.id
												}
												onChange={(evt) => {
													evt.preventDefault()

													const supplierId =
														evt.currentTarget.value
													const energySupplier =
														energySuppliers.find(
															(supplier) =>
																supplier.id ===
																supplierId,
														) ?? null // No_energy_supplier

													// Set field value
													form.setFieldValue(
														"me.investor.supplier",
														energySupplier,
													)
												}}
											>
												<option>
													-{" "}
													{t(
														`${"profile.personal_details.energy_supplier.no_energy_supplier"}`,
													)}{" "}
													-
												</option>
												{energySuppliers.map(
													(energySupplier) => (
														<option
															key={
																energySupplier.id
															}
															value={
																energySupplier.id
															}
														>
															{
																energySupplier.name
															}
														</option>
													),
												)}
											</Select>
										</SelectButton>
										<FormikError field="me.investor.supplier_account" />
									</label>
								</div>

								{/** Energy Client number */}
								<AnimatePresence>
									{form.values.me?.investor?.supplier?.id ? (
										<motion.div
											initial={{
												height: 0,
												opacity: 0,
												overflow: "hidden",
											}}
											animate={{
												opacity: 1,
												height: "auto",
											}}
											exit={{
												opacity: 0,
												height: 0,
												overflow: "hidden",
											}}
											transition={{
												duration: 0.35,
											}}
										>
											<div className="2xl:col-span-2">
												<FormikLabel htmlFor="me.investor.supplier_account">
													{t(
														"profile.personal_details.energy_client_number.title",
													)}
													<LabelSubtitle>
														{t(
															"profile.personal_details.energy_client_number.copy",
														)}
													</LabelSubtitle>
												</FormikLabel>

												<div className="2xl:col-span-2">
													<FormikInput
														name="me.investor.supplier_account"
														required
														className="mt-1 block w-full"
														classNameWrapper="lg:w-1/2"
													/>
													<FormikError field="me.investor.supplier_account" />
												</div>
											</div>
										</motion.div>
									) : null}
								</AnimatePresence>

								{/** Form errors */}
								<div className="2xl:col-span-2">
									<FormikErrors i18nNamespace="profile" />
								</div>
							</div>
						</FormGroup>
					</CardBody>
					<CardFooter className="text-right">
						<FormikSubmitButton
							disabled={updatePersonalDetailsMutation.isLoading}
						>
							{t("profile.personal_details.form_action.save")}
						</FormikSubmitButton>
					</CardFooter>
				</form>
			</CardWrapper>
		</FormikProvider>
	)
}

const PersonalDetailsHeading = memo(() => {
	const t = useTrans("profile")
	return (
		<p className="whitespace-break-spaces">
			<Trans
				t={t} // Should have a reference to the useTrans hook otherwise it won't update
				ns="profile"
				i18nKey="profile.personal_details.copy"
				components={{
					a: (
						// the "href" gets taken from the i18n string!
						/* eslint-disable jsx-a11y/anchor-has-content */
						/* eslint-disable jsx-a11y/anchor-is-valid */
						<a className="text-secondary-300 hover:text-secondary-700 font-medium" />
						/* eslint-enable jsx-a11y/anchor-has-content */
						/* eslint-enable jsx-a11y/anchor-is-valid */
					),
				}}
			/>
		</p>
	)
})
