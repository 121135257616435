// React
import { useMemo, Fragment } from "react"

// Translations
import { useLang } from "@/context/lang"
import { useTrans } from "@/i18n"

// UI
import {
	Table,
	TableBody,
	TableDataCell,
	TableHead,
	TableHeading,
	TableRowCell,
} from "@/components/table-controls/TableItems"
import {
	flexRender,
	useReactTable,
	getCoreRowModel,
	createColumnHelper,
} from "@/lib/table"

// Types
import { FiscalOverviewType } from "@/api/graphql"
interface FinanceBondsLoanByYearTableProps {
	fiscalOverview?: Array<{
		__typename?: "FiscalOverviewType"
		year?: string | null
		value_start?: string | null
		value_end?: string | null
	} | null> | null
}

/**
 * FinanceBondsLoanByYearTable
 * @returns
 */
export function FinanceBondsLoanByYearTable({
	fiscalOverview,
}: FinanceBondsLoanByYearTableProps) {
	// Translate
	const { formatCurrency } = useLang()
	const t = useTrans("finance")

	const rows = useMemo(() => {
		return fiscalOverview
	}, [fiscalOverview])

	const columnHelper = createColumnHelper<FiscalOverviewType>()
	const columns = useMemo(
		() => [
			columnHelper.accessor("year", {
				header: () => (
					<TableHeading>
						{t(
							"finance:finance.bonds-loan.block.fiscal-overview.header.year",
						)}
					</TableHeading>
				),
			}),
			columnHelper.accessor(
				(data: FiscalOverviewType) =>
					formatCurrency(Number(data.value_start) || 0),
				{
					id: "value_start",
					header: () => (
						<TableHeading>
							{t(
								"finance:finance.bonds-loan.block.fiscal-overview.header.start",
							)}
						</TableHeading>
					),
				},
			),
			columnHelper.accessor(
				(data: FiscalOverviewType) =>
					formatCurrency(Number(data.value_end) || 0),
				{
					id: "value_end",
					header: () => (
						<TableHeading>
							{t(
								"finance:finance.bonds-loan.block.fiscal-overview.header.end",
							)}
						</TableHeading>
					),
				},
			),
		],
		[t, formatCurrency],
	)

	const table = useReactTable({
		data: rows as FiscalOverviewType[],
		columns,
		getCoreRowModel: getCoreRowModel(),
	})

	// Template
	return (
		<>
			<Table className="min-w-[48rem] lg:min-w-0">
				{/* table header */}
				<TableHead>
					{table.getHeaderGroups().map((headerGroup) => (
						<TableRowCell key={headerGroup.id}>
							{headerGroup.headers.map((header) => {
								return (
									<Fragment key={header.id}>
										{flexRender(
											header.column.columnDef.header,
											header.getContext(),
										)}
									</Fragment>
								)
							})}
						</TableRowCell>
					))}
				</TableHead>

				{/* table body and table cells */}
				<TableBody data-testid="tablebody">
					{table.getRowModel().rows.map(({ ...row }, colIndex) => {
						return (
							<TableRowCell
								key={row.id}
								isOdd={colIndex % 2 === 0}
							>
								{row.getAllCells().map((cell, index) => (
									<TableDataCell
										key={cell.id}
										className="break-word whitespace-pre-wrap"
									>
										{/* Hide the the second column (value_start) of the last row */}
										{index ===
											table.getRowModel().rows.length -
												1 && colIndex === 1 ? (
											"-"
										) : (
											<>
												{flexRender(
													cell.column.columnDef.cell,
													cell.getContext(),
												)}
											</>
										)}
									</TableDataCell>
								))}
							</TableRowCell>
						)
					})}
				</TableBody>
			</Table>
		</>
	)
}
