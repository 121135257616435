import { useMemo } from "react"
import { useNavigate, useLocation } from "@/lib/router"
import { downloadText } from "@/api/rest/download"
import { useAuth } from "@/context/auth"
import { useSignIn } from "@/context/signIn"
import { useToasts } from "@/context/toasts"

// Icons
import { FiAlertTriangle, FiCopy, FiDownload } from "@/lib/icons"

// Copy
import { copy } from "@/lib/copyClipboard"

// Translations
import { useTrans, Trans } from "@/lib/i18n"

// UI
import { Button } from "../Button"
import { Card } from "../Card"
import { TextArea } from "../form-controls/TextArea"
import { Heading } from "../Typography"

// State
import { AuthSliceState } from "@/state/features/authSlice"
import { useSelector } from "@/state/StateProvider"

let hasDownloadSupport = true
interface RecoveryCodesProps {
	codes?: string
}

export const RecoveryCodes = ({
	codes: recoveryCodesFromProps,
}: RecoveryCodesProps) => {
	const auth = useAuth()
	const t = useTrans("sign-in")
	const { recoveryCodes } = useSignIn()
	const toasts = useToasts()
	const navigate = useNavigate()
	const location = useLocation()

	// State
	const authToken = useSelector(
		({ auth }: { auth: AuthSliceState }) => auth.ephemeralToken,
	)

	const codes = useMemo(() => {
		if (recoveryCodesFromProps) return recoveryCodesFromProps
		return recoveryCodes.join(`\n`)
	}, [recoveryCodes, recoveryCodesFromProps])

	const onCopy = async () => {
		try {
			await copy(codes)
			toasts.addToast({
				text: t(
					"sign-in:sign-in.recovery_codes.form_actions.copy.success",
				),
				id: "copy-recovery-codes",
			})
		} catch {
			toasts.addToast({
				text: t(
					"sign-in:sign-in.recovery_codes.form_actions.copy.failed",
				),
				id: "copy-recovery-codes-failed",
			})
		}
	}

	const onDownload = () => {
		try {
			downloadText(codes)
		} catch {
			toasts.addToast({
				text: t(
					"sign-in:sign-in.recovery_codes.form_actions.download.failed",
				),
				id: "download-recovery-codes-failed",
			})
		}
	}

	const onSubmit = async () => {
		if (authToken) {
			auth.setAuth({ authToken })

			navigate((location.state as { from?: string })?.from ?? "/", {
				replace: true,
			})
		} else {
			toasts.addToast({
				id: "failed-to-sign-in",
				text: t("sign-in:sign-in.recovery_codes.failed"),
				timing: 4000,
			})
		}
	}

	return (
		<>
			<div className="mt-2 max-w-xl text-sm text-gray-700">
				<p>{t("sign-in:sign-in.recovery_codes.copy")}</p>
				<p className="mt-3">
					{t("sign-in:sign-in.recovery_codes.instructions")}
				</p>
			</div>
			<div className="mb-8 mt-6 md:mb-9 md:mt-8">
				<Warning />
			</div>
			<Card variant="gray">
				<div className="space-y-4">
					<label htmlFor="codes">
						<Heading as="h5">
							{t("sign-in:sign-in.recovery_codes.heading")}
						</Heading>
					</label>
					<TextArea
						rows={codes.split(/\r\n|\r|\n/).length}
						className="block w-full"
						value={codes}
						readOnly
						id="codes"
						name="codes"
					/>
					<div className="flex space-x-3">
						{hasDownloadSupport ? (
							<Button
								variant="transparent"
								size="small"
								onClick={onDownload}
							>
								<FiDownload className="mr-2" />
								{t(
									"sign-in:sign-in.recovery_codes.form_actions.download",
								)}
							</Button>
						) : null}
						<Button
							variant="transparent"
							size="small"
							onClick={onCopy}
						>
							<FiCopy className="mr-2" />
							{t(
								"sign-in:sign-in.recovery_codes.form_actions.copy",
							)}
						</Button>
					</div>
					<div>
						<Button
							onClick={onSubmit}
							variant="primary"
							className="mt-4 flex w-full"
							data-testid="sign-in.recovery_codes.form_actions.submit"
						>
							{t(
								"sign-in:sign-in.recovery_codes.form_actions.submit",
							)}
						</Button>
					</div>
				</div>
			</Card>
		</>
	)
}

const Warning = () => {
	return (
		<div className="flex flex-wrap items-center justify-between rounded-lg bg-yellow-50 p-2 text-sm shadow-lg sm:p-3">
			<div className="flex">
				<div>
					<div className="flex rounded-lg bg-yellow-100 p-2">
						<FiAlertTriangle
							className="h-6 w-6 text-yellow-400"
							aria-hidden="true"
						/>
					</div>
				</div>
				<p className="ml-3 text-yellow-800">
					<Trans
						i18nKey="sign-in.recovery_codes.warning"
						ns="sign-in"
						components={{
							strong: (
								// eslint-disable-next-line jsx-a11y/heading-has-content
								<h3 className="mb-2 text-sm font-medium text-yellow-800" />
							),
						}}
					/>
				</p>
			</div>
		</div>
	)
}

try {
	const element = document.createElement("a")
	if (typeof element.download === "undefined") {
		hasDownloadSupport = false
	}
} catch {}
