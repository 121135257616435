// React
import { useContext, useMemo, Fragment } from "react"

// Router
import { useNavigate, useParams, useLocation } from "@/lib/router"
import { Routes } from "@/constants/routes"

// UI
import { classNames } from "@/lib/ui"
import {
	PaginationAsButtons,
	PaginationAsButtonsLoadingState,
} from "@/components/PaginationAsButtons"

// Dates
import { DateTime } from "@/lib/dates"

// Context
import { InvestmentsContext } from "../Investments"

// Redux state
import { useSelector } from "@/state/StateProvider"

// GraphQL
import { useInvestmentsTableViewQuery, ProjectType } from "@/api/graphql"

// Types
import {
	ProjectSortOrder,
	InvestmentsOverviewState,
} from "@/state/features/investmentsOverview/types"

// Translations
import { useLang } from "@/context/lang"
import { useTrans } from "@/i18n"

// Tables
import {
	Table,
	TableBody,
	TableDataCell,
	TableHead,
	TableRowCell,
	TableHeading,
} from "@/components/table-controls/TableItems"
import {
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
	CellContext,
	createColumnHelper,
} from "@/lib/table"

// UI
import { CardBody, CardWrapper } from "@/components/Card"

// Utils
import { truncateFloatNumber } from "@/utils/helpers"

/**
 * InvestmentsTable
 * @returns
 */
export function InvestmentsTable() {
	// Context
	const { search } = useContext(InvestmentsContext)

	// Redux state
	const {
		filterState,
		filterType,
		sortingMethod,
		sortingOrder,
		perPage: limit,
	} = useSelector(
		({
			investmentsOverview,
		}: {
			investmentsOverview: InvestmentsOverviewState
		}) => investmentsOverview,
	)

	// Router
	const navigate = useNavigate()
	const location = useLocation()
	const params = useParams()
	const currentPage = Number(params.page) || 1
	function setCurrentPage(page: number) {
		if (page >= 1) {
			navigate(
				Routes.InvestmentsProjectsDashboardPaginated.replace(
					":page",
					page.toString(),
				),
			)
		}
	}

	// Translations
	const { config, formatCurrency, formatNumber } = useLang()
	const t = useTrans(["project"])

	// Query
	const { data } = useInvestmentsTableViewQuery({
		limit,
		offset: (currentPage - 1) * limit,
		name: search,
		ordering:
			sortingOrder === ProjectSortOrder.Asc
				? sortingMethod
				: `-${sortingMethod}`,
		state: filterState,
		type: filterType,
	})

	// Projects
	const projects = useMemo(
		() => data?.me?.investment_projects?.results ?? [],
		[data?.me?.investment_projects?.results, location],
	)

	// Tables
	const columnHelper = createColumnHelper<ProjectType>()
	const columns = useMemo(
		() => [
			columnHelper.accessor("name", {
				header: () => (
					<TableHeading>
						{t(
							"investments:investments.overview.table.header.project",
						)}
					</TableHeading>
				),
				cell: (info: CellContext<ProjectType, string>) => (
					<TableDataCell>{info.getValue()}</TableDataCell>
				),
			}),
			columnHelper.accessor(
				(data: ProjectType) =>
					Number(
						(data?.investor_shares_value_stats?.shares?.[0]
							?.nominal_value || 0) *
							(data?.investor_shares_value_stats?.total_shares ||
								0),
					),
				{
					id: "initial-investment",
					header: () => (
						<TableHeading>
							{t(
								"investments:investments.overview.table.header.initial-investment",
							)}
						</TableHeading>
					),
					cell: (info: CellContext<ProjectType, number>) => (
						<TableDataCell>
							{formatCurrency(Number(info.getValue()))}
						</TableDataCell>
					),
				},
			),
			columnHelper.accessor(
				(data: ProjectType) =>
					Number(
						data?.investor_shares_value_stats
							?.total_investment_for_project,
					).toFixed(2),
				{
					id: "current-value",
					header: () => (
						<TableHeading>
							{t(
								"investments:investments.overview.table.header.current-value",
							)}
						</TableHeading>
					),
					cell: (info: CellContext<ProjectType, string>) => (
						<TableDataCell>
							{formatCurrency(Number(info.getValue()))}
						</TableDataCell>
					),
				},
			),
			columnHelper.accessor(
				(data: ProjectType) =>
					data?.investor_shares_value_stats?.total_repaid_for_project
						? parseFloat(
								data.investor_shares_value_stats
									.total_repaid_for_project,
						  )
						: 0,
				{
					id: "total-repayment",
					header: () => (
						<TableHeading>
							{t(
								"investments:investments.overview.table.header.total-repayment",
							)}
						</TableHeading>
					),
					cell: (info: CellContext<ProjectType, number>) => (
						<TableDataCell>
							{formatCurrency(Number(info.getValue()))}
						</TableDataCell>
					),
				},
			),
			columnHelper.accessor(
				(data: ProjectType) =>
					truncateFloatNumber(
						Number(
							data?.investor_shares_value_stats
								?.internal_rate_of_returns
								?.expected_internal_rate_of_return,
						),
					),
				{
					id: "expected-interest-rate-fixed",
					header: () => (
						<TableHeading>
							{t(
								"investments:investments.overview.table.header.expected-interest-rate-fixed",
							)}
						</TableHeading>
					),
					cell: (info: CellContext<ProjectType, number>) => (
						<TableDataCell>{`${info.getValue()}%`}</TableDataCell>
					),
				},
			),
			columnHelper.accessor(
				(data: ProjectType) =>
					truncateFloatNumber(
						Number(
							data?.investor_shares_value_stats
								?.internal_rate_of_returns
								?.internal_rate_of_return,
						),
					),
				{
					id: "interest-rate-fixed",
					header: () => (
						<TableHeading>
							{t(
								"investments:investments.overview.table.header.interest-rate-fixed",
							)}
						</TableHeading>
					),
					cell: (info: CellContext<ProjectType, number>) => (
						<TableDataCell>{`${info.getValue()}%`}</TableDataCell>
					),
				},
			),
			columnHelper.accessor(
				(data: ProjectType) =>
					formatNumber(
						parseFloat(
							(data?.investor_production_stats
								?.investor_generated_power_in_kwh
								? parseFloat(
										data.investor_production_stats
											.investor_generated_power_in_kwh,
								  )
								: 0
							).toFixed(1),
						),
						{
							minimumFractionDigits: 1,
						},
					),
				{
					id: "total-kwh-produced",
					header: () => (
						<TableHeading>
							{t(
								"investments:investments.overview.table.header.total-kwh-produced",
							)}
						</TableHeading>
					),
					cell: (info: CellContext<ProjectType, string>) => (
						<TableDataCell>{`${info.getValue()}kWh`}</TableDataCell>
					),
				},
			),
			columnHelper.accessor(
				(project: ProjectType) => project?.current_interest_period?.end,
				{
					id: "current_interest_period.end",
					header: () => (
						<TableHeading>
							{t(
								"investments:investments.overview.table.header.current_interest_period",
							)}
						</TableHeading>
					),
					cell: (info: CellContext<ProjectType, string>) => (
						<TableDataCell>
							{info.getValue()
								? DateTime.fromISO(info.getValue()).toFormat(
										config.dateFormatProjectCardInterestPeriod,
								  )
								: "-"}
						</TableDataCell>
					),
				},
			),
		],
		[],
	)

	// Table
	const table = useReactTable({
		columns,
		data: projects as ProjectType[],

		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
	})

	return (
		<CardWrapper className="mt-4">
			<CardBody>
				<Table
					className="min-w-[64rem] lg:min-w-0"
					data-testid="tablebody"
				>
					<TableHead>
						{table.getHeaderGroups().map((headerGroup) => (
							<TableRowCell key={headerGroup.id}>
								{headerGroup.headers.map((header) => {
									return (
										<Fragment key={header.id}>
											{flexRender(
												header.column.columnDef.header,
												header.getContext(),
											)}
										</Fragment>
									)
								})}
							</TableRowCell>
						))}
					</TableHead>
					<TableBody
						data-testid="tablebody-overview"
						data-pageindex={table.getState().pagination.pageIndex}
					>
						{table.getRowModel().rows.map((row) => {
							const isOdd = row.index % 2 === 0
							let className = ""

							return (
								<Fragment key={row.id}>
									<TableRowCell
										isOdd={isOdd}
										className={classNames(className)}
									>
										{row.getAllCells().map((cell) => {
											return (
												<Fragment key={cell.id}>
													{flexRender(
														cell.column.columnDef
															.cell,
														cell.getContext(),
													)}
												</Fragment>
											)
										})}
									</TableRowCell>
								</Fragment>
							)
						})}
						{/* Pads the last entries in the table so the table doesn't collapse in the UI */}
						{table.getRowModel().rows.length <
							table.getState().pagination.pageSize &&
						table.getState().pagination.pageIndex !== 0 ? (
							<>
								{Array(
									Math.max(
										table.getState().pagination.pageSize -
											table.getRowModel().rows.length,
										1,
									),
								)
									.fill(true)
									.map((_, index) => (
										<TableRowCell
											key={index}
											withHover={false}
											isOdd={index % 2 === 0}
										>
											<TableDataCell
												colSpan={columns.length}
											>
												<span className="dummy-text" />
											</TableDataCell>
										</TableRowCell>
									))}
							</>
						) : null}
					</TableBody>
				</Table>

				{projects?.length !== 0 && (
					<div className="mt-6 flex w-full justify-center px-4">
						<PaginationAsButtons
							countPerPage={limit}
							totalCount={
								data?.me?.investment_projects?.totalCount ?? 0
							}
							itemType={"common.pagination.item_types.investment"}
							currentPage={currentPage}
							currentItemsAmount={projects?.length ?? 0}
							onNextPage={() => setCurrentPage(currentPage + 1)}
							onPrevPage={() => setCurrentPage(currentPage - 1)}
							analyticsId="investments"
						/>
					</div>
				)}
			</CardBody>
		</CardWrapper>
	)
}

/**
 * InvestmentsTableLoadingState
 * @returns
 */
export function InvestmentsTableLoadingState() {
	const t = useTrans()

	return (
		<CardWrapper className="mt-4">
			<CardBody>
				<Table>
					<TableHead>
						<TableRowCell>
							<TableHeading>
								{t(
									"investments:investments.overview.table.header.project",
								)}
							</TableHeading>
							<TableHeading>
								{t(
									"investments:investments.overview.table.header.initial-investment",
								)}
							</TableHeading>
							<TableHeading>
								{t(
									"investments:investments.overview.table.header.current-value",
								)}
							</TableHeading>
							<TableHeading>
								{t(
									"investments:investments.overview.table.header.total-repayment",
								)}
							</TableHeading>
							<TableHeading>
								{t(
									"investments:investments.overview.table.header.expected-interest-rate-fixed",
								)}
							</TableHeading>
							<TableHeading>
								{t(
									"investments:investments.overview.table.header.interest-rate-fixed",
								)}
							</TableHeading>
							<TableHeading>
								{t(
									"investments:investments.overview.table.header.total-kwh-produced",
								)}
							</TableHeading>
							<TableHeading>
								{t(
									"investments:investments.overview.table.header.current_interest_period",
								)}
							</TableHeading>
						</TableRowCell>
					</TableHead>
				</Table>
				<div className="mt-6 flex justify-center md:mt-8 md:gap-x-4">
					<PaginationAsButtonsLoadingState />
				</div>
			</CardBody>
		</CardWrapper>
	)
}
