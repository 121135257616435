import { HTMLProps } from "react"
import { classNames } from "@/lib/ui"
import { NavLink as ReactRouterLink, NavLinkProps } from "@/lib/router"

export const className = "focus:underline"

export const Anchor = ({
	className: classNameFromProps,
	...rest
}: HTMLProps<HTMLAnchorElement>) => {
	/* eslint-disable jsx-a11y/anchor-has-content */
	return <a className={classNames(classNameFromProps, className)} {...rest} />
}

export const Link = ({
	className: classNameFromProps,
	href,
	...rest
}: Omit<NavLinkProps, "to"> & {
	href: string
}) => {
	return (
		<ReactRouterLink
			className={
				// confusing, but, we want to generate the class name here in this
				// component. and also pass in our classNames for all links.
				typeof classNameFromProps === "function"
					? (props) =>
							classNames(className, classNameFromProps(props))
					: classNames(className, classNameFromProps)
			}
			to={href}
			{...rest}
		/>
	)
}
