import { ReactNode } from "react"
import { ProjectStateEnum } from "@/api/graphql"
import { classNames } from "@/lib/ui"

type Variants = ProjectStateEnum

const classesMap: { [Variant in Variants]: string } = {
	BEING_INSTALLED: "bg-gray-100 text-gray-800",
	DIRECTLY_FINANCED: "bg-green-100 text-green-800",
	FIXED_INTEREST: "bg-green-100 text-green-800",
	INVESTMENT_ALLOCATION: "bg-yellow-100 text-yellow-800",
	IN_FUNDING: "bg-yellow-100 text-yellow-800",
	IN_PRODUCTION: "bg-green-100 text-green-800",
	MONITORING_OFFLINE: "bg-gray-100 text-gray-800",
	NO_PRODUCTION: "bg-gray-100 text-gray-800",
	PAID_OFF: "bg-green-100 text-green-800",
}
export interface PillProps {
	variant?: Variants
	className?: string
	children?: ReactNode
}

export function Pill({
	className = "",
	variant = "MONITORING_OFFLINE",
	children,
}: PillProps) {
	return (
		<span
			className={classNames(
				className,
				"items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium",
				// offline styles are the "fallback styles"
				classesMap[variant] ?? classesMap.MONITORING_OFFLINE,
			)}
		>
			{children}
		</span>
	)
}

const invertedClassesMap: { [Variant in Variants]: string } = {
	BEING_INSTALLED: "text-white bg-gray-500",
	DIRECTLY_FINANCED: "text-white bg-green-500",
	FIXED_INTEREST: "text-white bg-green-500",
	INVESTMENT_ALLOCATION: "text-white bg-black",
	IN_FUNDING: "text-white bg-black",
	IN_PRODUCTION: "text-white bg-green-500",
	MONITORING_OFFLINE: "text-white bg-yellow-500",
	NO_PRODUCTION: "text-white bg-gray-500",
	PAID_OFF: "text-white bg-black",
}

export interface PillInvertedProps {
	variant?: Variants
	className?: string
	children?: ReactNode
}

export const PillInverted = ({
	className = "",
	variant = "MONITORING_OFFLINE",
	children,
}: PillInvertedProps) => (
	<span
		className={classNames(
			className,
			"items-baseline rounded-full px-3.5 py-1 text-sm font-medium",
			invertedClassesMap[variant] ??
				invertedClassesMap.MONITORING_OFFLINE,
		)}
	>
		{children}
	</span>
)
