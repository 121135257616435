import { HTMLProps } from "react"
import { classNames } from "@/lib/ui"

export interface TextAreaProps extends HTMLProps<HTMLTextAreaElement> {
	hasError?: boolean
}

export const TextArea = ({ className, hasError, ...props }: TextAreaProps) => (
	<textarea
		{...props}
		className={classNames(
			className,
			"appearance-none",
			"px-3 py-2",
			"border",
			hasError ? `border-red-300` : `border-gray-300`,
			hasError && `text-red-600`,
			"rounded-md shadow-sm",
			"placeholder-gray-400",
			"focus-within:text-dark focus:outline-none",
			hasError ? `focus:ring-red-500` : `focus:ring-primary-500`,
			hasError ? `focus:border-red-500` : `focus:border-primary-500`,
			"disabled:bg-gray-100 sm:text-sm",
		)}
	/>
)
